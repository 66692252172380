import {
  TRAINING_ATTEND,
  TRAINING_ATTEND_CLEAR_DATA,
  TRAINING_ATTEND_ERROR,
  TRAINING_ATTEND_SUCCESS,
  TRAINING_ASSIGNMENT,
  TRAINING_ASSIGNMENT_CLEAR_DATA,
  TRAINING_ASSIGNMENT_ERROR,
  TRAINING_ASSIGNMENT_SUCCESS,
  TRAINING_UNASSIGNMENT,
  TRAINING_UNASSIGNMENT_CLEAR_DATA,
  TRAINING_UNASSIGNMENT_ERROR,
  TRAINING_UNASSIGNMENT_SUCCESS,
} from "./actionTypes";

const initialState = {
  responseTrainingAttend: null,
  loadingTrainingAttend: null,
  errorTrainingAttend: null,
  responseTrainingAssignment: null,
  loadingTrainingAssignment: false,
  errorTrainingAssignment: null,
  responseTrainingUnAssignment: null,
  loadingTrainingUnAssignment: false,
  errorTrainingUnAssignment: null,
};

const Training = (state = initialState, action) => {
  switch (action.type) {
    case TRAINING_ATTEND:
      state = {
        ...state,
        loadingTrainingAttend: true,
        errorTrainingAttend: null,
        responseTrainingAttend: true,
      };
      break;
    case TRAINING_ASSIGNMENT:
      state = {
        ...state,
        loadingTrainingAssignment: true,
        errorTrainingAssignment: null,
        responseTrainingAssignment: true,
      };
      break;
    case TRAINING_UNASSIGNMENT:
      state = {
        ...state,
        loadingTrainingUnAssignment: true,
        errorTrainingUnAssignment: null,
        responseTrainingUnAssignment: true,
      };
      break;
    case TRAINING_ATTEND_SUCCESS:
      state = {
        ...state,
        errorTrainingAttend: null,
        loadingTrainingAttend: false,
        responseTrainingAttend: action.payload,
      };
      break;
    case TRAINING_ASSIGNMENT_SUCCESS:
      state = {
        ...state,
        errorTrainingAssignment: null,
        loadingTrainingAssignment: false,
        responseTrainingAssignment: action.payload,
      };
      break;
    case TRAINING_UNASSIGNMENT_SUCCESS:
      state = {
        ...state,
        errorTrainingUnAssignment: null,
        loadingTrainingUnAssignment: false,
        responseTrainingUnAssignment: action.payload,
      };
      break;
    case TRAINING_ATTEND_ERROR:
      state = {
        ...state,
        errorTrainingAttend: action.payload,
        loadingTrainingAttend: false,
        responseTrainingAttend: null,
      };
      break;
    case TRAINING_ASSIGNMENT_ERROR:
      state = {
        ...state,
        errorTrainingAssignment: action.payload,
        loadingTrainingAssignment: false,
        responseTrainingAssignment: null,
      };
      break;
    case TRAINING_UNASSIGNMENT_ERROR:
      state = {
        ...state,
        errorTrainingUnAssignment: action.payload,
        loadingTrainingUnAssignment: false,
        responseTrainingUnAssignment: null,
      };
      break;
    case TRAINING_ATTEND_CLEAR_DATA:
      state = {
        ...state,
        errorTrainingAttend: null,
        loadingTrainingAttend: false,
        responseTrainingAttend: null,
      };
      break;
    case TRAINING_ASSIGNMENT_CLEAR_DATA:
      state = {
        ...state,
        errorTrainingAssignment: null,
        loadingTrainingAssignment: false,
        responseTrainingAssignment: null,
      };
      break;
    case TRAINING_UNASSIGNMENT_CLEAR_DATA:
      state = {
        ...state,
        errorTrainingUnAssignment: null,
        loadingTrainingUnAssignment: false,
        responseTrainingUnAssignment: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Training;
