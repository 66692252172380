import { Navigate, Route, Routes } from "react-router-dom";
import HomeTeach from "../edukcare/home/home";
import ContactTeach from "../edukcare/contacts/contact";
import LearnerPage from "../edukcare/learner/learner";
import LearnerDetailPage from "../edukcare/learnerDetail/learnerDetail";
import Welcome from "../edukcare/welcome/welcome";
import { Layout } from "../components/layout/layout";
import Course from "../edukcare/course/course";
import { formStatus } from "@edukcare/github-packages-edukcare-components/dist";
import CourseDetail from "../edukcare/courseDetail/courseDetail";

export const PrivateRoutes = () => {
  return (
    <Routes>
      <Route
        path="/home"
        element={
          <Layout>
            <HomeTeach />
          </Layout>
        }
      />
      <Route path="/welcome" element={<Welcome />} />
      <Route
        path="/contact"
        element={
          <Layout>
            <ContactTeach />
          </Layout>
        }
      />
      <Route
        path="/learner"
        element={
          <Layout>
            <LearnerPage />
          </Layout>
        }
      />
      <Route
        path="/learner/detail/:id"
        element={
          <Layout>
            <LearnerDetailPage />
          </Layout>
        }
      />
      <Route
        path="/course/create"
        element={
          <Layout>
            <Course mode={formStatus.create} />
          </Layout>
        }
      />
      <Route
        path="/course/detail/:id"
        element={
          <Layout>
            <CourseDetail />
          </Layout>
        }
      />
      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  );
};
