import { useNavigate, useParams } from "react-router-dom";
import { View } from "./view";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  spaceOne,
  spaceOneClearData,
  spaceUpdate,
  spaceUpdateClearData,
  updateAvatarSpace,
  updateAvatarSpaceClearData,
} from "../../store/space/actions";
import { useTranslation } from "react-i18next";
import { Space } from "../../model/space";
import { formatDate } from "../../utils/formatDate";
import {
  QuizOptionsProps,
  formStatus,
  position,
  typeAlert,
} from "@edukcare/github-packages-edukcare-components/dist";
import { Session, SessionCreateRequest } from "../../model/session";
import { APP_ID, ORIGIN } from "../../utils/constants";
import {
  sessionCreate,
  sessionCreateClearData,
  sessionOne,
  sessionOneClearData,
  sessionResourceAdd,
  sessionUpdate,
} from "../../store/session/actions";
import { ASSET_TYPE, SESSION_TYPE } from "../../enum/session";
import { SpaceType } from "../../enum/space";
import { Quiz } from "../../model/quiz";
import { Question } from "../../model/question";
import { Choise } from "../../model/choise";
import {
  contactSpaceStudentClearData,
  contactStaff,
  contactStaffClearData,
  contactStaffSpace,
  contactStudent,
  contactStudentClearData,
  contactStudentSpace,
} from "../../store/contact/actions";
import { CONTACT_SUBTYPE, CONTACT_TYPE } from "../../enum/contact";
import { autocomplete } from "@edukcare/github-packages-edukcare-components/dist/src/dto/autocomplete";
import { ContactData } from "../../model/contact";
import {
  TrainingAssignmentRequest,
  TrainingUnAssignmentRequest,
} from "../../model/training";
import {
  trainingAssignment,
  trainingAssignmentClearData,
  trainingUnAssignment,
  trainingUnAssignmentClearData,
} from "../../store/training/actions";
import { getSessionType } from "../../utils/sessions";
import { INTERRUMPTION_REASON, STUDENTS_PAGE_STATE } from "../../enum/training";
import showToast from "../../services/toast";

const CourseDetail = ({ ...props }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [option, setOption] = useState<number>(0);
  const [listSession, setListSessions] = useState<any[]>([]);
  const [listSessionTemp, setListSessionsTemp] = useState<any[]>([]);
  const [listQuestions, setListQuestions] = useState<QuizOptionsProps[]>([]);
  const [dataForm, setDataForm] = useState<any>({});
  const [dataFormQuiz, setDataFormQuiz] = useState<any>({});
  const [sessionState, setSessionState] = useState<any>(null);
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [listStudents, setListStudents] = useState<any[]>([]);
  const [listStaff, setListStaff] = useState<autocomplete[]>([]);
  const [avatar, setAvatar] = useState<File[]>([]);
  const [studentState, setStudentState] = useState(STUDENTS_PAGE_STATE.LIST);
  const [staffState, setStaffState] = useState(STUDENTS_PAGE_STATE.LIST);
  const [listAssigmentStudents, setListAssignmentStudents] = useState<any>([]);
  const [listAssigmentStudentsTemp, setListAssignmentStudentsTemp] =
    useState<any>([]);
  const [listAssigmentStaff, setListAssignmentStaff] = useState<any>([]);
  const [listAssigmentStaffTemp, setListAssignmentStaffTemp] = useState<any>(
    []
  );
  const [dataFormSession, setDataFormSession] = useState<any>({});
  const [defaultAvatar, setDefaultAvatar] = useState<any>({});
  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { t } = useTranslation("global");
  const labels = {
    info: t("courseDetail.info"),
    sessions: t("courseDetail.session"),
    students: t("courseDetail.students"),
    staff: t("courseDetail.staff"),
    quizes: t("courseDetail.quizes"),
    exit: t("courseDetail.exit"),
  };

  const courseLabels: any = {
    confirmLabel: t("course.confirmLabel"),
    cancelLabel: t("course.cancelLabel"),
    dataForm: {
      descriptionErrorText: t("course.dataForm.descriptionErrorText"),
      descriptionLabel: t("course.dataForm.descriptionLabel"),
      nameErrorText: t("course.dataForm.nameErrorText"),
      nameLabel: t("course.dataForm.nameLabel"),
      initialDateLabel: t("course.dataForm.initialDateLabel"),
      endDateLabel: t("course.dataForm.endDateLabel"),
      descriptionTextHelper: t("course.dataForm.descriptionTextHelper"),
      nameTextHelper: t("course.dataForm.nameTextHelper"),
      tagDescription: t("course.dataForm.tagDescription"),
      tagTitle: t("course.dataForm.tagTitle"),
      fileDescription: t("course.dataForm.fileDescription"),
      minScoreLabel: t("course.dataForm.minScoreLabel"),
      minScoreHelperText: t("course.dataForm.minScoreHelperText"),
      maxScoreLabel: t("course.dataForm.minScoreHelperText"),
      maxScoreHelperText: t("course.dataForm.minScoreHelperText"),
      minStudentsLabel: t("course.dataForm.minScoreHelperText"),
      minStudentsHelperText: t("course.dataForm.minScoreHelperText"),
      maxStudentsLabel: t("course.dataForm.maxStudentsLabel"),
      maxStudentsHelperText: t("course.dataForm.maxStudentsHelperText"),
      retriesHelperText: t("course.dataForm.retriesHelperText"),
      retriesLabel: t("course.dataForm.retriesLabel"),
      durationLabel: t("course.dataForm.durationLabel"),
      durationHelperText: t("course.dataForm.durationHelperText"),
      draftLabel: t("course.dataForm.draftLabel"),
    },
    description: t("course.description"),
    errorLabels: {
      DescriptionRequired: t("course.errorLabels.DescriptionRequired"),
      NameRequired: t("course.errorLabels.NameRequired"),
      InitialDate: t("course.errorLabels.InitialDate"),
      EndDate: t("course.errorLabels.EndDate"),
    },
    title: t("course.titleUpdate"),
  };

  const sessionsLabels: any = {
    pageCourseDescription: t("courseDetail.sessions.pageCourseDescription"),
    pageCourseTitle: t("courseDetail.sessions.pageCourseTitle"),
    pageTitle: t("courseDetail.sessions.pageTitle"),
    title: t("courseDetail.sessions.title"),
    label: {
      cancel: t("courseDetail.sessions.label.cancel"),
      confirm: t("courseDetail.sessions.label.confirm"),
      description: t("courseDetail.sessions.label.description"),
      descriptionHelperText: t(
        "courseDetail.sessions.label.descriptionHelperText"
      ),
      draft: t("courseDetail.sessions.label.draft"),
      draftHelperText: t("courseDetail.sessions.label.draftHelperText"),
      dropZoneDescription: t("courseDetail.sessions.label.dropZoneDescription"),
      link: t("courseDetail.sessions.label.link"),
      linkHelperText: t("courseDetail.sessions.label.linkHelperText"),
      orLabel: t("courseDetail.sessions.label.orLabel"),
      title: t("courseDetail.sessions.label.title"),
      titleHelperText: t("courseDetail.sessions.label.titleHelperText"),
    },
    errorLabel: {
      descriptionRequired: t(
        "courseDetail.sessions.errorLabel.descriptionRequired"
      ),
      fileRequired: t("courseDetail.sessions.errorLabel.fileRequired"),
      invalidFile: t("courseDetail.sessions.errorLabel.invalidFile"),
      titleRequired: t("courseDetail.sessions.errorLabel.titleRequired"),
    },
  };

  const quizWizardLabels: any = {
    error: {
      scoreRequired: t("courseDetail.quizWizard.error.scoreRequired"),
    },
    label: {
      answer: t("courseDetail.quizWizard.label.answer"),
      buttonMultipleQuestion: t(
        "courseDetail.quizWizard.label.buttonMultipleQuestion"
      ),
      buttonSimpleQuestion: t(
        "courseDetail.quizWizard.label.buttonSimpleQuestion"
      ),
      enable: t("courseDetail.quizWizard.label.enable"),
      question: t("courseDetail.quizWizard.label.question"),
      questionLabel: t("courseDetail.quizWizard.label.questionLabel"),
      saveButton: t("courseDetail.quizWizard.label.saveButton"),
      score: t("courseDetail.quizWizard.label.score"),
      scoreHelperText: t("courseDetail.quizWizard.label.scoreHelperText"),
      multiple: t("courseDetail.quizWizard.label.multiple"),
    },
  };

  const studentsLabels: any = {
    alertDescription: t("courseDetail.studentsList.alertDescription"),
    alertDescriptionUnAssign: t(
      "courseDetail.studentsList.alertDescriptionUnAssign"
    ),
    alertTitle: t("courseDetail.studentsList.alertTitle"),
    buttonText: t("courseDetail.studentsList.buttonText"),
    description: t("courseDetail.studentsList.description"),
    descriptionUnAssign: t("courseDetail.studentsList.descriptionUnAssign"),
    helperTextTag: t("courseDetail.studentsList.helperTextTag"),
    labelTag: t("courseDetail.studentsList.labelTag"),
    preSelectedDescription: t(
      "courseDetail.studentsList.preSelectedDescription"
    ),
    preSelectedTitle: t("courseDetail.studentsList.preSelectedTitle"),
    title: t("courseDetail.studentsList.title"),
    titleUnAssignemnt: t("courseDetail.studentsList.titleUnAssignemnt"),
    titleTag: t("courseDetail.studentsList.titleTag"),
    assign: t("courseDetail.studentsList.assign"),
    unassign: t("courseDetail.studentsList.unassign"),
    cancelLabel: t("courseDetail.studentsList.cancelLabel"),
  };

  const teacherLabels: any = {
    alertDescription: t("courseDetail.teacherList.alertDescription"),
    alertDescriptionUnAssign: t(
      "courseDetail.teacherList.alertDescriptionUnAssign"
    ),
    alertTitle: t("courseDetail.teacherList.alertTitle"),
    buttonText: t("courseDetail.teacherList.buttonText"),
    description: t("courseDetail.teacherList.description"),
    descriptionUnAssign: t("courseDetail.teacherList.descriptionUnAssign"),
    helperTextTag: t("courseDetail.teacherList.helperTextTag"),
    labelTag: t("courseDetail.teacherList.labelTag"),
    preSelectedDescription: t(
      "courseDetail.teacherList.preSelectedDescription"
    ),
    preSelectedTitle: t("courseDetail.teacherList.preSelectedTitle"),
    title: t("courseDetail.teacherList.title"),
    titleTag: t("courseDetail.teacherList.titleTag"),
    assign: t("courseDetail.teacherList.assign"),
    unassign: t("courseDetail.teacherList.unassign"),
    cancelLabel: t("courseDetail.teacherList.cancelLabel"),
  };

  const { responseSessionCreate, errorSessionCreate, loadingSessionCreate } =
    useSelector((state: any) => ({
      responseSessionCreate: state.Session.responseSessionCreate,
      errorSessionCreate: state.Session.errorSessionCreate,
      loadingSessionCreate: state.Session.loadingSessionCreate,
    }));

  const {
    responseSessionResourceAdd,
    errorSessionResourceAdd,
    loadingSessionResourceAdd,
  } = useSelector((state: any) => ({
    responseSessionResourceAdd: state.Session.responseSessionResourceAdd,
    errorSessionResourceAdd: state.Session.errorSessionResourceAdd,
    loadingSessionResourceAdd: state.Session.loadingSessionResourceAdd,
  }));

  const { responseContactStudent, errorContactStudent, loadingContactStudent } =
    useSelector((state: any) => ({
      responseContactStudent: state.Contact.responseContactStudent,
      errorContactStudent: state.Contact.errorContactStudent,
      loadingContactStudent: state.Contact.loadingContactStudent,
    }));

  const { responseContactStaff, errorContactStaff, loadingContactStaff } =
    useSelector((state: any) => ({
      responseContactStaff: state.Contact.responseContactStaff,
      errorContactStaff: state.Contact.errorContactStaff,
      loadingContactStaff: state.Contact.loadingContactStaff,
    }));

  const { responseSpaceUpdate, errorSpaceUpdate, loadingSpaceUpdate } =
    useSelector((state: any) => ({
      responseSpaceUpdate: state.Space.responseSpaceUpdate,
      errorSpaceUpdate: state.Space.errorSpaceUpdate,
      loadingSpaceUpdate: state.Space.loadingSpaceUpdate,
    }));

  const { responseSpaceOne, errorSpaceOne, loadingSpaceOne } = useSelector(
    (state: any) => ({
      responseSpaceOne: state.Space.responseSpaceOne,
      errorSpaceOne: state.Space.errorSpaceOne,
      loadingSpaceOne: state.Space.loadingSpaceOne,
    })
  );

  const {
    updateAvatarResponseSpace,
    updateAvatarErrorSpace,
    updateAvatarLoadingSpace,
  } = useSelector((state: any) => ({
    updateAvatarResponseSpace: state.Space.updateAvatarResponseSpace,
    updateAvatarErrorSpace: state.Space.updateAvatarErrorSpace,
    updateAvatarLoadingSpace: state.Space.updateAvatarLoadingSpace,
  }));

  const { responseSessionOne, errorSessionOne, loadingSessionOne } =
    useSelector((state: any) => ({
      responseSessionOne: state.Session.responseSessionOne,
      errorSessionOne: state.Session.errorSessionOne,
      loadingSessionOne: state.Session.loadingSessionOne,
    }));

  const {
    responseContactSpaceStudent,
    errorContactSpaceStudent,
    loadingContactSpaceStudent,
  } = useSelector((state: any) => ({
    responseContactSpaceStudent: state.Contact.responseContactSpaceStudent,
    errorContactSpaceStudent: state.Contact.errorContactSpaceStudent,
    loadingContactSpaceStudent: state.Contact.loadingContactSpaceStudent,
  }));

  const {
    responseContactSpaceStaff,
    errorContactSpaceStaff,
    loadingContactSpaceStaff,
  } = useSelector((state: any) => ({
    responseContactSpaceStaff: state.Contact.responseContactSpaceStaff,
    errorContactSpaceStaff: state.Contact.errorContactSpaceStaff,
    loadingContactSpaceStaff: state.Contact.loadingContactSpaceStaff,
  }));

  const {
    responseTrainingAssignment,
    errorTrainingAssignment,
    loadingTrainingAssignment,
  } = useSelector((state: any) => ({
    responseTrainingAssignment: state.Training.responseTrainingAssignment,
    errorTrainingAssignment: state.Training.errorTrainingAssignment,
    loadingTrainingAssignment: state.Training.loadingTrainingAssignment,
  }));

  const {
    responseTrainingUnAssignment,
    errorTrainingUnAssignment,
    loadingTrainingUnAssignment,
  } = useSelector((state: any) => ({
    responseTrainingUnAssignment: state.Training.responseTrainingUnAssignment,
    errorTrainingUnAssignment: state.Training.errorTrainingUnAssignment,
    loadingTrainingUnAssignment: state.Training.loadingTrainingUnAssignment,
  }));

  const { responseSessionUpdate, errorSessionUpdate, loadingSessionUpdate } =
    useSelector((state: any) => ({
      responseSessionUpdate: state.Session.responseSessionUpdate,
      errorSessionUpdate: state.Session.errorSessionUpdate,
      loadingSessionUpdate: state.Session.loadingSessionUpdate,
    }));

  const getType = (value: any) => {
    if (ASSET_TYPE.AUDIO === value) return SESSION_TYPE.AUDIO;
    if (ASSET_TYPE.VIDEO === value) return SESSION_TYPE.WATCH_VIDEO;
    return SESSION_TYPE.READ_DOCUMENT;
  };

  const onChangeOption = (value: any) => {
    if (value === 5) {
      navigate("/home");
      return;
    }
    if (value === 2) {
      setStudentState(STUDENTS_PAGE_STATE.LIST);
    }
    if (value === 3) {
      setStaffState(STUDENTS_PAGE_STATE.LIST);
    }
    setSessionState(null);
    setOption(value);
  };

  const handleClickAddSession = () => {
    setSessionState(formStatus.create);
  };

  const handleClickCancelCreateSession = () => {
    setSessionState(null);
  };

  const handleSubmitSession = (data: any) => {
    setSeeToast({ ...seeToast, show: false });
    if (data?.Link) setUploadFile(null);
    else setUploadFile(data?.file?.file);
    const newSession: SessionCreateRequest = {
      app_id: APP_ID,
      data: {
        title: data?.Title,
        description: data?.Description,
        type: getType(data?.file?.type),
        draft: data?.Draft ?? false,
        asset_url: data?.Link,
        duration: 10,
        scoring: 10,
      },
    };
    dispatch(sessionCreate({ id: dataForm?.Id, body: newSession }));
  };

  const handleSubmitQuiz = (data: any) => {
    updateTraining(dataForm, data);
  };

  const handleSubmitStudentAssignment = (data: any) => {
    const { id } = params;
    setSeeToast({ ...seeToast, show: false });
    const formAssigment: TrainingAssignmentRequest = {
      app_id: APP_ID,
      data: {
        type: CONTACT_TYPE.STUDENT,
        origin: ORIGIN,
        contactsId: (data?.newAssignments ?? []).map(
          (assignment: any) => assignment?.id ?? ""
        ),
        expiresAt: formatDate(dataForm?.EndDate),
      },
    };

    dispatch(trainingAssignment({ id, body: formAssigment }));
  };

  const handleSubmitStudentUnAssignment = (data: any) => {
    const { id } = params;
    setSeeToast({ ...seeToast, show: false });
    const formAssigment: TrainingUnAssignmentRequest = {
      app_id: APP_ID,
      data: {
        contacts: (data?.newAssignments ?? []).map(
          (assignment: any) => assignment?.id ?? ""
        ),
        interrumptionReason: INTERRUMPTION_REASON.OTHER,
      },
    };

    dispatch(trainingUnAssignment({ id, body: formAssigment }));
  };

  const handleSubmitStaffAssignment = (data: any) => {
    const { id } = params;
    setSeeToast({ ...seeToast, show: false });
    const formAssigment: TrainingAssignmentRequest = {
      app_id: APP_ID,
      data: {
        type: CONTACT_TYPE.STAFF,
        origin: ORIGIN,
        subType: CONTACT_SUBTYPE.TARGET,
        contactsId: (data?.newAssignments ?? []).map(
          (assignment: any) => assignment?.id ?? ""
        ),
        expiresAt: formatDate(dataForm?.EndDate),
      },
    };
    dispatch(trainingAssignment({ id, body: formAssigment }));
  };

  const updateTraining = (data: any, questions: any) => {
    const { id } = params;
    let dataUpdate: Space = {
      title: data?.Name,
      description: data?.Description,
      type: SpaceType.TRAINING,
      requireQuiz: questions !== null,
      training: {
        id: dataForm.trainingId,
        expires_at: new Date(data?.EndDate).toISOString(),
        never_expires: false,
        min_score: data?.minScore,
        max_retries: data?.retries,
        starts_at: new Date(data?.InitialDate).toISOString(),
        max_duration_days: data?.duration,
        students_max_limit: data?.maxStudents,
        students_min_limit: data?.minStudents,
        draft: data?.Draft,
        require_quiz: questions?.Enable === true,
        score: +questions?.Score || dataForm?.quiz?.score || 0,
        scoring: +questions?.Score || dataForm?.quiz?.score || 0,
        quiz: dataForm?.quiz,
      },
    };

    if (questions && dataUpdate?.training) {
      dataUpdate.training.quiz = {
        minScore: +questions?.Score,
        retries: +data?.retries,
        questions: questions?.questions?.map((question: any) => {
          let newQuestion: Question = {
            id: question?.id,
            title: question?.value,
            score: question?.score,
            multipleChoice: question?.multipleChoice,
          };
          if (question?.multipleChoice) {
            newQuestion.choices = question.choises?.map((choise: any) => {
              return {
                id: choise?.id,
                right: choise?.right,
                title: choise?.value,
              };
            });
          }
          return newQuestion;
        }),
      };
    }
    dispatch(
      spaceUpdate({
        id,
        body: {
          app: APP_ID,
          data: dataUpdate,
        },
      })
    );
  };

  const handleSubmit = (data: any) => {
    updateTraining(data.data, null);
  };

  const handleAvatar = (files: File[]) => {
    setAvatar(files);
  };

  const resetForm = () => {
    setSeeToast({ ...seeToast, show: false });
    setSessionState(null);
    setOption(0);
    setListSessions([]);
    setListSessionsTemp([]);
    dispatch(spaceOneClearData());
    dispatch(contactStudentClearData());
    dispatch(sessionCreateClearData());
    dispatch(contactStaffClearData());
    dispatch(contactSpaceStudentClearData());
    setListAssignmentStudents([]);
    setListAssignmentStaff([]);
    setListQuestions([]);
    setUploadFile(null);
    setDataFormQuiz({});
    setDataFormSession({});
    setDefaultAvatar({});
    setAvatar([]);
  };

  const searchSession = (name: string) => {
    if (!name) return setListSessionsTemp(listSession);
    setListSessionsTemp(
      listSession.filter((x) =>
        x.title?.toLocaleLowerCase()?.includes(name?.toLocaleLowerCase())
      )
    );
  };

  const searchStudents = (name: string) => {
    console.log(name);
    if (!name) return setListAssignmentStudentsTemp(listAssigmentStudents);
    setListAssignmentStudentsTemp(
      listAssigmentStudents.filter((x: any) =>
        x.name?.toLocaleLowerCase()?.includes(name?.toLocaleLowerCase())
      )
    );
  };

  const searchStaff = (name: string) => {
    if (!name) return setListAssignmentStaffTemp(listAssigmentStaff);
    setListAssignmentStaffTemp(
      listAssigmentStaff.filter((x: any) =>
        x.name?.toLocaleLowerCase()?.includes(name?.toLocaleLowerCase())
      )
    );
  };

  const handleUpdateSession = (data: any) => {
    const { id } = params;
    setSeeToast({ ...seeToast, show: false });
    if (data?.Link) setUploadFile(null);
    else setUploadFile(data?.file?.file);
    const newSession: SessionCreateRequest = {
      app_id: APP_ID,
      data: {
        title: data?.Title,
        description: data?.Description,
        type: getType(data?.file?.type),
        draft: data?.Draft ?? false,
        asset_url: data?.Link,
        duration: 10,
        scoring: 10,
      },
    };
    dispatch(
      sessionUpdate({
        spaceId: id,
        body: newSession,
        sessionId: dataFormSession?.id,
      })
    );
  };

  const handleClickSession = (data: any) => {
    setSeeToast({ ...seeToast, show: false });
    dispatch(sessionOneClearData());
    dispatch(sessionOne({ id: data?.id }));
  };

  const changeToAssign = () => {
    setStudentState(STUDENTS_PAGE_STATE.ASSIGN);
    setStaffState(STUDENTS_PAGE_STATE.ASSIGN);
  };

  const changeToUnAssign = () => {
    setStudentState(STUDENTS_PAGE_STATE.UNASSIGN);
    setStaffState(STUDENTS_PAGE_STATE.UNASSIGN);
  };

  const changeToAssignList = () => {
    setStudentState(STUDENTS_PAGE_STATE.LIST);
    setStaffState(STUDENTS_PAGE_STATE.LIST);
  };

  useEffect(() => {
    const { id } = params;
    resetForm();
    setSeeToast({ ...seeToast, show: false });
    dispatch(spaceOne({ id }));
    dispatch(contactStudent({ type: CONTACT_TYPE.STUDENT }));
    dispatch(contactStaff({ type: CONTACT_TYPE.STAFF }));
    dispatch(contactStudentSpace({ id, type: CONTACT_TYPE.STUDENT }));
    dispatch(contactStaffSpace({ id, type: CONTACT_TYPE.STAFF }));
  }, []);

  useEffect(() => {
    if (
      errorSessionCreate ||
      errorSessionResourceAdd ||
      errorContactStudent ||
      errorContactStaff ||
      errorSpaceUpdate ||
      errorSpaceOne ||
      updateAvatarErrorSpace ||
      errorContactSpaceStudent ||
      errorContactSpaceStaff ||
      errorTrainingAssignment ||
      errorTrainingUnAssignment ||
      errorSessionUpdate
    ) {
      setSeeToast({
        show: true,
        message: t("courseDetail.error"),
        type: typeAlert.error,
      });
    }
  }, [
    errorSessionCreate,
    errorSessionResourceAdd,
    errorContactStudent,
    errorContactStaff,
    errorSpaceUpdate,
    errorSpaceOne,
    updateAvatarErrorSpace,
    errorContactSpaceStudent,
    errorContactSpaceStaff,
    errorTrainingAssignment,
    errorTrainingUnAssignment,
    errorSessionUpdate,
  ]);

  useEffect(() => {
    const { id } = params;
    setSeeToast({ ...seeToast, show: false });
    if (responseSessionCreate?.data) {
      if (uploadFile === null) {
        dispatch(spaceOne({ id }));
        setSessionState(null);
      } else {
        const formData = new FormData();
        formData.append("file", uploadFile);
        dispatch(
          sessionResourceAdd({
            spaceId: id,
            sessionId: responseSessionCreate?.data?.id,
            body: formData,
          })
        );
      }
    }
  }, [responseSessionCreate]);

  useEffect(() => {
    const { id } = params;
    setSeeToast({ ...seeToast, show: false });
    if (responseSessionUpdate?.data) {
      if (uploadFile === null) {
        dispatch(spaceOne({ id }));
        setSessionState(null);
      } else {
        const formData = new FormData();
        formData.append("file", uploadFile);
        dispatch(
          sessionResourceAdd({
            spaceId: id,
            sessionId: responseSessionUpdate?.data?.id,
            body: formData,
          })
        );
      }
    }
  }, [responseSessionUpdate]);

  useEffect(() => {
    const { id } = params;
    setSeeToast({ ...seeToast, show: false });
    if (responseSpaceUpdate?.success) {
      if (avatar.length > 0) {
        const formData = new FormData();
        formData.append("file", avatar[0]);
        dispatch(updateAvatarSpace({ id: id, body: formData }));
        return;
      }
      dispatch(spaceOneClearData());
      dispatch(spaceOne({ id }));
      dispatch(spaceUpdateClearData());
    }
  }, [responseSpaceUpdate]);

  useEffect(() => {
    const { id } = params;
    setSeeToast({ ...seeToast, show: false });
    if (updateAvatarResponseSpace?.success) {
      dispatch(spaceOneClearData());
      dispatch(spaceOne({ id }));
      dispatch(spaceUpdateClearData());
      dispatch(updateAvatarSpaceClearData());
      setAvatar([]);
    }
  }, [updateAvatarResponseSpace]);

  useEffect(() => {
    if (responseSpaceOne?.data) {
      const data = responseSpaceOne.data as Space;
      let info: any = {
        trainingId: data?.training?.id,
        defaultAvatar: data?.avatar?.href,
        Name: data?.title,
        Description: data?.description,
        InitialDate: formatDate(data?.training?.starts_at ?? "", "YYYY-MM-DD"),
        EndDate: formatDate(data?.training?.expires_at ?? "", "YYYY-MM-DD"),
        minScore: data?.training?.min_score,
        maxScore: data?.training?.max_score,
        minStudents: data?.training?.students_min_limit,
        maxStudents: data?.training?.students_max_limit,
        retries: data?.training?.max_retries,
        duration: data?.training?.max_duration_days,
        Draft: data?.training?.draft === 1,
        Id: data?.id,
        quiz: data?.training?.quiz ?? null,
      };
      if (data?.training?.quiz?.questions) {
        setDataFormQuiz({
          Score: data?.training?.quiz?.score ?? 0,
          Enable: data?.training?.require_quiz === "1",
        });
        setListQuestions(
          data.training.quiz.questions?.map((question: Question) => {
            return {
              id: question.id ?? "",
              title: question.title ?? "",
              value: question.title ?? "",
              score: question?.score ?? 0,
              multipleChoice: question.multipleChoice ?? false,
              choises: (question.choices ?? []).map((choise: Choise) => {
                return {
                  id: choise.id ?? "",
                  title: choise?.title ?? "",
                  value: choise?.title ?? "",
                  right: choise?.right ?? false,
                };
              }),
            };
          })
        );
      }
      setDataForm(info);
      const sessions = (data?.training?.sessions ?? []).map((session: any) => {
        return {
          avatar: "",
          description: session.description ?? "",
          id: session.id,
          title: session.title ?? "",
          type: getSessionType(session?.type),
        };
      });
      setListSessions(sessions);
      setListSessionsTemp(sessions);
    }
  }, [responseSpaceOne]);

  useEffect(() => {
    const { id } = params;
    setSeeToast({ ...seeToast, show: false });
    if (responseSessionResourceAdd?.data) {
      dispatch(spaceOne({ id }));
      setSessionState(null);
    }
  }, [responseSessionResourceAdd]);

  useEffect(() => {
    if (responseContactStudent?.data) {
      const data = responseContactStudent?.data as ContactData[];
      setListStudents(
        data.map((contact: ContactData) => {
          return {
            id: contact.id,
            name: `${contact.names} ${contact.last_names}`,
            avatar: contact?.avatar?.href ?? "",
            image: contact?.avatar?.href ?? "",
          };
        })
      );
    }
  }, [responseContactStudent]);

  useEffect(() => {
    if (responseContactStaff?.data) {
      const data = responseContactStaff?.data as ContactData[];
      setListStaff(
        data.map((contact: ContactData) => {
          return {
            id: contact.id,
            name: `${contact.names} ${contact.last_names}`,
            image: contact?.avatar?.href ?? "",
          };
        })
      );
    }
  }, [responseContactStaff]);

  useEffect(() => {
    if (responseContactSpaceStudent?.data) {
      let list = responseContactSpaceStudent?.data ?? [];
      list = list.map((contact: any) => {
        return {
          id: contact?.id,
          Name: `${contact?.names ?? ""} ${contact?.lastNames ?? ""}`,
          name: `${contact?.names ?? ""} ${contact?.lastNames ?? ""}`,
          avatar: contact?.avatar?.href ?? "",
          mail: contact?.email ?? "",
          progress: contact?.progress ?? 0,
          date: formatDate(contact?.assignedDate ?? ""),
          image: contact?.avatar?.href ?? "",
        };
      });
      setListAssignmentStudents(list);
      setListAssignmentStudentsTemp(list);
    }
  }, [responseContactSpaceStudent]);

  useEffect(() => {
    if (responseContactSpaceStaff?.data) {
      let list = responseContactSpaceStaff?.data ?? [];
      list = list.map((contact: any) => {
        return {
          id: contact?.id,
          Name: `${contact?.names ?? ""} ${contact?.lastNames ?? ""}`,
          name: `${contact?.names ?? ""} ${contact?.lastNames ?? ""}`,
          avatar: contact?.avatar?.href ?? "",
          mail: contact?.email ?? "",
          progress: contact?.progress ?? 0,
          date: formatDate(contact?.assignedDate ?? ""),
          image: contact?.avatar?.href ?? "",
        };
      });
      setListAssignmentStaff(list);
      setListAssignmentStaffTemp(list);
    }
  }, [responseContactSpaceStaff]);

  useEffect(() => {
    const { id } = params;
    setSeeToast({ ...seeToast, show: false });
    if (
      responseTrainingAssignment?.success ||
      responseTrainingUnAssignment?.success
    ) {
      dispatch(trainingAssignmentClearData());
      dispatch(trainingUnAssignmentClearData());
      setStudentState(STUDENTS_PAGE_STATE.LIST);
      setStaffState(STUDENTS_PAGE_STATE.LIST);
      dispatch(contactStudentSpace({ id, type: CONTACT_TYPE.STUDENT }));
      dispatch(contactStaffSpace({ id, type: CONTACT_TYPE.STAFF }));
    }
  }, [responseTrainingAssignment, responseTrainingUnAssignment]);

  useEffect(() => {
    if (responseSessionOne?.data) {
      const data: any = responseSessionOne?.data;
      setDataFormSession({
        id: data?.id,
        Title: data.title,
        Draft: (data?.draft ?? 0) === 1,
        Description: data?.description,
        resource: data?.resources?.href ?? "",
        Link: data?.resources?.href ?? "",
      });
      setDefaultAvatar({
        file: data?.resources?.href ?? "",
        type: getSessionType(data?.type ?? ""),
      });
      setSessionState(formStatus.edit);
    }
  }, [responseSessionOne]);

  return (
    <>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message)}
      <View
        isLoading={
          loadingSpaceOne ||
          loadingSessionCreate ||
          loadingSessionResourceAdd ||
          loadingSpaceUpdate ||
          updateAvatarLoadingSpace ||
          loadingContactSpaceStudent ||
          loadingTrainingAssignment ||
          loadingTrainingUnAssignment ||
          loadingSessionOne ||
          loadingSessionUpdate
        }
        labels={labels}
        option={option}
        courseLabels={courseLabels}
        dataForm={dataForm}
        onChangeOption={onChangeOption}
        handleClickAddSession={handleClickAddSession}
        sessionState={sessionState}
        sessionsLabels={sessionsLabels}
        handleClickCancelCreateSession={handleClickCancelCreateSession}
        handleSubmitSession={handleSubmitSession}
        listSession={listSessionTemp}
        handleSubmitQuiz={handleSubmitQuiz}
        listQuestions={listQuestions}
        quizWizardLabels={quizWizardLabels}
        dataFormQuiz={dataFormQuiz}
        listStudents={listStudents}
        listStaff={listStaff}
        studentsLabels={studentsLabels}
        teacherLabels={teacherLabels}
        handleSubmitStudentAssignment={handleSubmitStudentAssignment}
        handleSubmitStaffAssignment={handleSubmitStaffAssignment}
        handleSubmit={handleSubmit}
        handleAvatar={handleAvatar}
        searchSession={searchSession}
        studentState={studentState}
        listAssigmentStudents={listAssigmentStudentsTemp}
        changeToAssign={changeToAssign}
        changeToUnAssign={changeToUnAssign}
        handleSubmitStudentUnAssignment={handleSubmitStudentUnAssignment}
        listAssigmentStaff={listAssigmentStaffTemp}
        staffState={staffState}
        handleClickSession={handleClickSession}
        dataFormSession={dataFormSession}
        defaultAvatar={defaultAvatar}
        handleUpdateSession={handleUpdateSession}
        searchStudents={searchStudents}
        searchStaff={searchStaff}
        changeToAssignList={changeToAssignList}
      />
    </>
  );
};

export default CourseDetail;
