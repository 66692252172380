import { useEffect, useState } from "react";
import { View } from "./view";
import {
  Loading_,
  formStatus,
  position,
  typeAlert,
  typeLoaders,
} from "@edukcare/github-packages-edukcare-components/dist";
import { useTranslation } from "react-i18next";
import {
  SpaceOneResponse,
  SpaceRequest,
  SpaceResponse,
} from "../../model/space";
import { APP_ID } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  createSpace,
  createSpaceClearData,
  updateAvatarSpace,
  updateAvatarSpaceClearData,
} from "../../store/space/actions";
import showToast from "../../services/toast";
import { themeCore } from "../../assets/themes/theme";
import { useNavigate } from "react-router-dom";
import { SpaceType } from "../../enum/space";
import { formatDate } from "../../utils/formatDate";
import { tagSpace } from "../../store/tag/actions";
import { autocomplete } from "@edukcare/github-packages-edukcare-components/dist/src/dto/autocomplete";
import { TagResponse } from "../../model/tag";

const Course = ({ mode, ...props }: { mode: formStatus }) => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const [avatar, setAvatar] = useState<File[]>([]);
  const [listTags, setListTags] = useState<autocomplete[]>([]);
  const labels: any = {
    confirmLabel: t("course.confirmLabel"),
    cancelLabel: t("course.cancelLabel"),
    dataForm: {
      descriptionErrorText: t("course.dataForm.descriptionErrorText"),
      descriptionLabel: t("course.dataForm.descriptionLabel"),
      nameErrorText: t("course.dataForm.nameErrorText"),
      nameLabel: t("course.dataForm.nameLabel"),
      initialDateLabel: t("course.dataForm.initialDateLabel"),
      endDateLabel: t("course.dataForm.endDateLabel"),
      descriptionTextHelper: t("course.dataForm.descriptionTextHelper"),
      tagDescription: t("course.dataForm.tagDescription"),
      tagTitle: t("course.dataForm.tagTitle"),
      fileDescription: t("course.dataForm.fileDescription"),
    },
    description: t("course.description"),
    errorLabels: {
      DescriptionRequired: t("course.errorLabels.DescriptionRequired"),
      NameRequired: t("course.errorLabels.NameRequired"),
      InitialDate: t("course.errorLabels.InitialDate"),
      EndDate: t("course.errorLabels.EndDate"),
    },
    title: t("course.title"),
  };

  const { createResponseSpace, createErrorSpace, createLoadingSpace } =
    useSelector((state: any) => ({
      createResponseSpace: state.Space.createResponseSpace,
      createErrorSpace: state.Space.errorUpdateContact,
      createLoadingSpace: state.Space.createLoadingSpace,
    }));

  const { responseSpaceTag, errorSpaceTag, loadingSpaceTag } = useSelector(
    (state: any) => ({
      responseSpaceTag: state.Tag.responseSpaceTag,
      errorSpaceTag: state.Tag.errorSpaceTag,
      loadingSpaceTag: state.Tag.loadingSpaceTag,
    })
  );

  const {
    updateAvatarResponseSpace,
    updateAvatarErrorSpace,
    updateAvatarLoadingSpace,
  } = useSelector((state: any) => ({
    updateAvatarResponseSpace: state.Space.updateAvatarResponseSpace,
    updateAvatarErrorSpace: state.Space.updateAvatarErrorSpace,
    updateAvatarLoadingSpace: state.Space.updateAvatarLoadingSpace,
  }));

  const resetForm = () => {
    setListTags([]);
    dispatch(createSpaceClearData());
    dispatch(updateAvatarSpaceClearData());
    dispatch(tagSpace({}));
    setAvatar([]);
  };

  const initPage = async () => {
    if (mode === formStatus.create) {
      resetForm();
    }
  };

  const handleSubmit = async (data: any) => {
    const tags = data?.tags
      ?.filter((x: any) => x.newValue)
      ?.map((x: any) => x.name);
    const newCourse: SpaceRequest = {
      app_id: APP_ID,
      data: {
        type: SpaceType.TRAINING,
        title: data?.data?.Name,
        description: data?.data?.Description,
        newTags: tags ?? [],
        training: {
          starts_at: new Date(data?.data?.InitialDate).toISOString(),
          expires_at: new Date(data?.data?.EndDate).toISOString(),
        },
      },
    };
    dispatch(createSpace(newCourse));
  };

  const handleAvatar = (files: File[]) => {
    setAvatar(files);
  };

  const handleCancel = () => {
    navigate("/home")
  }

  useEffect(() => {
    initPage();
  }, []);

  useEffect(() => {
    initPage();
  }, [mode]);

  useEffect(() => {
    if (createErrorSpace) {
      setSeeToast({
        show: true,
        message: t("course.errorMessages.errorCreateCourse"),
        type: typeAlert.error,
      });
      dispatch(createSpaceClearData());
    }
  }, [createErrorSpace]);

  useEffect(() => {
    if (createResponseSpace?.success) {
      if (avatar.length > 0) {
        const data = createResponseSpace as SpaceOneResponse;
        const formData = new FormData();
        formData.append("file", avatar[0]);
        dispatch(updateAvatarSpace({ id: data.data?.id, body: formData }));
        return;
      }
      navigate("/home");
    }
  }, [createResponseSpace]);

  useEffect(() => {
    if (updateAvatarErrorSpace?.data || updateAvatarResponseSpace?.success) {
      setSeeToast({
        show: true,
        message: t("course.successMessages.successCreated"),
        type: typeAlert.success,
      });
      navigate("/home");
    }
  }, [updateAvatarErrorSpace, updateAvatarResponseSpace]);

  useEffect(() => {
    if (responseSpaceTag?.data) {
      const data = responseSpaceTag as TagResponse;
      setListTags(
        (data?.data ?? []).map((tag: any) => {
          return {
            id: tag?.id,
            name: tag?.name,
          };
        })
      );
    }
  }, [responseSpaceTag]);

  return (
    <>
      <View
        mode={mode}
        tags={listTags}
        labels={labels}
        handleSubmit={handleSubmit}
        handleAvatar={handleAvatar}
        handleCancel={handleCancel}
      />
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message)}
      <Loading_
        loading={createLoadingSpace || updateAvatarLoadingSpace}
        type={typeLoaders.BarLoader}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        bgColor={themeCore.colors.backgroundPrimaryTransparent}
      />
    </>
  );
};

export default Course;
