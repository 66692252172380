import { useEffect, useState } from "react";
import LocalStorageService from "../../services/localStorage";
import { STORE_KEY } from "../../utils/constants";
import { View } from "./view";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  const localStoreService = new LocalStorageService(STORE_KEY);
  const { t } = useTranslation("global");
  const [userData, setUserData] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    const userInfo: any = localStoreService.getUserStored();
    setUserData(userInfo);
  }, []);

  const handleContinue = () => {
    navigate("/home");
  };

  return (
    <View
      userData={userData}
      title={t("welcome.title")}
      handleContinue={handleContinue}
    />
  );
};

export default Welcome;
