import { useDispatch, useSelector } from "react-redux";
import { LearnerView } from "./view";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { space, spaceClearData } from "../../store/space/actions";
import LocalStorageService from "../../services/localStorage";
import {
  ASSIGNED_TRAINING_STATUS,
  COMPLETED_TRAINING_STATUS,
  STORE_KEY,
} from "../../utils/constants";
import { LearnerCardProps } from "@edukcare/github-packages-edukcare-components/dist/src/dto/learnerList";
import { Space } from "../../model/space";
import { useNavigate } from "react-router-dom";
import showToast from "../../services/toast";
import {
  D3ISpaceStaff,
  position,
  typeAlert,
} from "@edukcare/github-packages-edukcare-components/dist";
import { Staff } from "../../model/staff";
const LearnerPage = () => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const localStoreService = new LocalStorageService(STORE_KEY);

  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [listSpaces, setListSpaces] = useState<LearnerCardProps[]>([]);
  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { responseSpace, errorSpace, loadingSpace } = useSelector(
    (state: any) => ({
      responseSpace: state.Space.responseSpace,
      errorSpace: state.Space.errorSpace,
      loadingSpace: state.Space.loadingSpace,
    })
  );

  const handleGetSpaces = ({
    type,
    searchTerm,
    status,
  }: {
    type?: string;
    searchTerm?: string;
    status?: string;
  }) => {
    const userInfo: any = localStoreService.getUserStored();
    if (userInfo?.students && userInfo?.students?.length > 0) {
      const params: {
        assigneeUserId: string;
        fillByType: boolean;
        type?: string;
        searchTerm?: string;
        status?: string;
      } = {
        assigneeUserId: userInfo?.students[0]?.id,
        fillByType: true,
        status: status,
      };
      params.type = type ?? "TRAINING";
      params.searchTerm = searchTerm;
      dispatch(space({ ...params }));
    }
  };

  const handleClickCard = (data: any) => {
    navigate(`/learner/detail/${data?.id}`);
  };

  const handleOnChangeOption = (option: any) => {
    dispatch(spaceClearData());
    setListSpaces([]);
    setSelectedOption(+option);
  };

  const handleChangeSelectOption = (selectedOption: any) => {
    dispatch(spaceClearData());
    if (+selectedOption === 0)
      handleGetSpaces({ status: ASSIGNED_TRAINING_STATUS });
    else if (+selectedOption === 1)
      handleGetSpaces({ status: COMPLETED_TRAINING_STATUS });
  };

  const handleOnRefresh = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleChangeSelectOption(selectedOption);
  };

  useEffect(() => {
    if (responseSpace?.data) {
      const data: LearnerCardProps[] = responseSpace.data?.map(
        (item: Space) => {
          const staffList =
            item.training?.staff?.map((staff: Staff) => {
              return {
                id: staff.id,
                names: staff.names,
                last_names: staff.last_names,
                avatar: staff.avatar,
              };
            }) ?? [];
          return {
            id: item.id,
            image: item.avatar?.href,
            title: item.description,
            progress: item.training?.progress,
            teachers: staffList,
          };
        }
      );
      setListSpaces(data);
    }
  }, [responseSpace]);

  useEffect(() => {
    if (errorSpace) {
      setSeeToast({
        show: true,
        message: t("learner.getAllError"),
        type: typeAlert.error,
      });
      dispatch(spaceClearData());
    }
  }, [errorSpace]);

  useEffect(() => {
    handleChangeSelectOption(selectedOption);
  }, [selectedOption]);

  const labels = {
    title: t("learner.title"),
    labels: {
      option1: t("learner.option1"),
      option2: t("learner.option2"),
      search: t("learner.search"),
    },
  };
  return (
    <>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message)}
      <LearnerView
        labels={labels}
        data={loadingSpace ? [] : listSpaces}
        handleClickCard={handleClickCard}
        isLoading={loadingSpace}
        handleOnChangeOption={handleOnChangeOption}
        handleOnRefresh={handleOnRefresh}
      />
    </>
  );
};

export default LearnerPage;
