import LocalStorageService from "../../services/localStorage";
import { STORE_KEY } from "../../utils/constants";
import GetUrl from "../../services/config";
import { URLs } from "../url";
import { buildQueryString } from "../../utils/queryParams";
import { fetchRequest } from "../fetchRequest";
import { TrainingAttendRequest } from "../../model/training";

const localStorageService = new LocalStorageService(STORE_KEY);

const updateTrainingAttend = async (data: any) => {
  const url = `${GetUrl(URLs.trainings)}/${data.id}/Attend`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      Authorization: userInfo?.token,
      studentId: undefined,
    },
    body: JSON.stringify(data.body as TrainingAttendRequest),
  };
  if (userInfo?.students && userInfo?.students?.length > 0)
    requestOptions.headers.studentId = userInfo?.students[0]?.id;
  try {
    return await fetchRequest(url, requestOptions);
  } catch (error: any) {
    throw error;
  }
};

const trainingUnAssignmentContact = async (data: any) => {
  const url = `${GetUrl(URLs.trainings)}/${data.id}/Unassign`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "POST",
    headers: {
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
    },
    body: JSON.stringify(data.body),
  };
  try {
    return await fetchRequest(url, requestOptions);
  } catch (errors: any) {
    throw errors;
  }
};

export { updateTrainingAttend, trainingUnAssignmentContact };
