import React from "react";
import { WrapperContent } from "../../components/layout/style";
import { Home_ } from "@edukcare/github-packages-edukcare-components/dist";

export const HomeView = (props: any) => {
  return (
    <WrapperContent>
      <Home_
        data={props?.listTraining ?? []}
        onClickNewCourse={props.handleOnClickNewCourse}
        onClickCard={() => {}}
        onDetailCourse={props.handleDetailCourse}
        optionCourse={{
          description:
            "Descripcion de curso con texto largo para que se pueda mostrar a detalle que es lo que se trae en el curso",
          detailLabel: "Ingresar al Curso",
          id: "optionMenu",
          name: "Nombre del Curso",
          showDelete: true,
          showDetail: true,
          showEdit: true,
          showStudent: true,
          showTeacher: true,
          studentLabel: "Estudiantes",
          teacherLabel: "Maestros",
        }}
        title="Home Page"
        isLoading={props.isLoading}
        onTerm={props.onTerm}
        panelLabels={props.labels}
        filter={props.filter}
        panel={props.panel}
        onClickPanelFilter={props.handleClickFilter}
      />
    </WrapperContent>
  );
};
