import { WelcomePage } from "@edukcare/github-packages-edukcare-components/dist";
import { WrapperContent } from "../../components/layout/style";
import logo from "../../assets/images/edukLogo.svg";
export const View = (props: any) => {
  return (
    <WrapperContent>
      <WelcomePage
        id="homeStudent"
        title={props?.title ?? ""}
        description={props?.userData?.user?.full_name ?? ""}
        imageURL={props?.userData?.school?.avatar?.href ?? logo}
        onContinue={props?.handleContinue}
        showSkeleton={!props?.userData?.school?.avatar?.href}
      />
    </WrapperContent>
  );
};
