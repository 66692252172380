import React, { useEffect, useState } from "react";
import { HomeView } from "./view";
import { useNavigate } from "react-router-dom";
import LocalStorageService from "../../services/localStorage";
import { STORE_KEY } from "../../utils/constants";
import { USER_ROL } from "../../enum/login";
import { useDispatch, useSelector } from "react-redux";
import { branch, space } from "../../store/space/actions";
import {
  Card2Props,
  position,
  typeAlert,
} from "@edukcare/github-packages-edukcare-components/dist";
import showToast from "../../services/toast";
import { Space, SpaceResponse } from "../../model/space";
import { Staff } from "../../model/staff";
import { SpaceType } from "../../enum/space";
import { useTranslation } from "react-i18next";
import { tagSpace } from "../../store/tag/actions";
import { Tag, TagResponse } from "../../model/tag";

const HomeTeach = () => {
  const localStoreService = new LocalStorageService(STORE_KEY);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("global");

  const initialParams = {
    type: SpaceType.TRAINING,
    searchTerm: undefined,
    draft: false,
    status: undefined,
  };

  const labels = {
    filterButton: t("homeTeacher.filterButton"),
    cancelButton: t("homeTeacher.cancelButton"),
    teacherSection: t("homeTeacher.teacherSection"),
    studentsSection: t("homeTeacher.studentsSection"),
    alertsSection: t("homeTeacher.alertsSection"),
    draftSection: t("homeTeacher.draftSection"),
    notificationSection: t("homeTeacher.notificationSection"),
    tagHelperText: t("homeTeacher.tagHelperText"),
    tagTitle: t("homeTeacher.tagTitle"),
    tagLabel: t("homeTeacher.tagLabel"),
    filtersSection: t("homeTeacher.filtersSection"),
    uploadCoursesSection: t("homeTeacher.uploadCoursesSection"),
    newBranch: t("homeTeacher.newBranch"),
    branch: t("homeTeacher.branch"),
    branchSection: t("homeTeacher.branchSection"),
  };

  const filter = {
    searchLabel: t("homeTeacher.filter.searchLabel"),
  };

  const [userData, setUserData] = useState<any>({});
  const [listTraining, setLisTraining] = useState<any>([]);
  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const [panel, setPanel] = useState<{
    tagArray?: any[];
    alertDefault?: boolean;
    notificationDefault?: boolean;
    studentDefault?: boolean;
    tagDefault?: any[];
    spaces?: any[];
    teacherDefault?: boolean;
    draftDefault?: boolean;
  }>({
    tagArray: [],
    alertDefault: false,
    notificationDefault: false,
    studentDefault: false,
    tagDefault: [],
    teacherDefault: false,
    spaces: [],
  });
  const [params, setParams] = useState<{
    type?: string;
    searchTerm?: string;
    status?: string;
    draft?: boolean;
    hasStudents?: boolean;
    hasStaff?: boolean;
    hasContentAlert?: boolean;
    hasActivity?: boolean;
    tags?: string;
  } | null>(null);

  const { responseBranch, errorBranch, loadingBranch } = useSelector(
    (state: any) => ({
      responseBranch: state.Space.responseBranch,
      errorBranch: state.Space.errorBranch,
      loadingBranch: state.Space.loadingBranch,
    })
  );

  const { responseSpace, errorSpace, loadingSpace } = useSelector(
    (state: any) => ({
      responseSpace: state.Space.responseSpace,
      errorSpace: state.Space.errorSpace,
      loadingSpace: state.Space.loadingSpace,
    })
  );

  const { responseSpaceTag, errorSpaceTag, loadingSpaceTag } = useSelector(
    (state: any) => ({
      responseSpaceTag: state.Tag.responseSpaceTag,
      errorSpaceTag: state.Tag.errorSpaceTag,
      loadingSpaceTag: state.Tag.loadingSpaceTag,
    })
  );

  const handleGetSpaces = ({
    type,
    searchTerm,
    status,
    draft,
    hasStudents,
    hasStaff,
    hasContentAlert,
    hasActivity,
    tags,
  }: {
    type?: string;
    searchTerm?: string;
    status?: string;
    draft?: boolean;
    hasStudents?: boolean;
    hasStaff?: boolean;
    hasContentAlert?: boolean;
    hasActivity?: boolean;
    tags?: string;
  }) => {
    const params: any = {
      fillByType: true,
      type: type ?? SpaceType.TRAINING,
      searchTerm,
      status,
      draft: draft ?? false,
    };
    if (hasStudents) params.hasStudents = hasStudents;
    if (hasStaff) params.hasStaff = hasStaff;
    if (hasContentAlert) params.hasContentAlert = hasContentAlert;
    if (hasActivity) params.hasActivity = hasActivity;
    if (tags) params.tags = tags;
    dispatch(space({ ...params }));
  };

  const handleOnClickNewCourse = () => {
    navigate("/course/create");
  };

  const handleClickFilter = (data: any) => {
    setParams({
      ...params,
      hasContentAlert: data?.alert,
      hasStudents: data?.student,
      hasStaff: data?.teacher,
      tags: (data?.tag ?? []).map((x: any) => x?.id),
      draft: data?.draft,
    });
    setPanel({
      ...panel,
      alertDefault: data?.alert ?? false,
      notificationDefault: data?.notification ?? false,
      studentDefault: data?.student ?? false,
      tagDefault: data?.tag ?? [],
      teacherDefault: data?.teacher ?? false,
      draftDefault: data?.draft ?? false,
    });
  };

  const handleDetailCourse = (event: Card2Props) => {
    navigate(`/course/detail/${event.id}`);
  };

  const onTerm = (data: any) => {
    setParams({ ...params, searchTerm: data });
  };

  useEffect(() => {
    const userInfo: any = localStoreService.getUserStored();
    setUserData(userInfo);
    setParams(initialParams);
    if (
      userInfo?.user?.role?.toLocaleLowerCase() ===
      USER_ROL.STUDENT.toLocaleLowerCase()
    ) {
      navigate("/learner");
      return;
    } else {
      handleGetSpaces({});
      dispatch(tagSpace({}));
      setParams(initialParams);
      dispatch(branch({ type: SpaceType.INSTITUTION }));
    }
  }, []);

  useEffect(() => {
    if (params) handleGetSpaces(params);
  }, [params]);

  useEffect(() => {
    if (responseSpaceTag?.data) {
      const data = responseSpaceTag as TagResponse;
      setPanel({
        ...panel,
        tagArray: (data?.data ?? []).map((tag: any) => {
          return {
            id: tag?.id,
            name: tag?.name,
          };
        }),
      });
    }
  }, [responseSpaceTag]);

  useEffect(() => {
    if (responseBranch?.data) {
      const data = responseBranch as SpaceResponse;
      setPanel({
        ...panel,
        spaces: (data?.data ?? []).map((space: any) => {
          return {
            id: space?.id,
            name: space?.title,
          };
        }),
      });
    }
  }, [responseBranch]);

  useEffect(() => {
    if (responseSpace?.success) {
      const data = responseSpace as SpaceResponse;
      const newList = data.data?.map((x: Space) => {
        const staffList = x.training?.staff?.map((staff: Staff) => {
          return {
            id: staff.id,
            names: staff.names,
            last_names: staff.last_names,
            avatar: staff.avatar,
          };
        });
        return {
          id: x.id,
          img: x.avatar?.href,
          tags: x.tags ?? [],
          title: x.title ?? "",
          teachers: staffList ?? [],
          description: x.description ?? "",
        };
      });
      setLisTraining(newList ?? []);
    }
  }, [responseSpace]);

  return (
    <>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message)}
      <HomeView
        isLoading={loadingSpace}
        listTraining={listTraining}
        handleOnClickNewCourse={handleOnClickNewCourse}
        onTerm={onTerm}
        labels={labels}
        filter={filter}
        panel={panel}
        handleClickFilter={handleClickFilter}
        handleDetailCourse={handleDetailCourse}
      />
    </>
  );
};

export default HomeTeach;
