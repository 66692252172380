import {
  D3ICourseDetail,
  D3ICourseNew,
  formStatus,
  sizes,
  wrapperType,
  Sessions_,
  Session,
  D3IQuizWizard,
  D3ICourseAssignment,
  AssignedStudent_,
} from "@edukcare/github-packages-edukcare-components/dist";
import { STUDENTS_PAGE_STATE } from "../../enum/training";
import { useStyles } from "./styles";
export const View = (props: any) => {
  const { classes } = useStyles();
  return (
    <div className={classes.bodyContainer}>
      <D3ICourseDetail
        id="courseDetail"
        isLoading={props.isLoading}
        labels={props.labels}
        onChangeOption={props.onChangeOption}
      >
        <div className={classes.container}>
          {props.option === 0 && (
            <D3ICourseNew
              defaultAvatar={props.dataForm?.defaultAvatar ?? null}
              confirmLabel={props.courseLabels?.confirmLabel ?? ""}
              cancelLabel={props.courseLabels?.cancelLabel ?? ""}
              dataFormLabel={props?.courseLabels?.dataForm}
              dataForm={props.dataForm ?? undefined}
              showCancel={false}
              description={props.courseLabels?.description ?? ""}
              showHelp={false}
              showHeader={false}
              formType={formStatus.edit}
              fullWidth={false}
              showActions={true}
              buttonSize={sizes.small}
              open={false}
              errorLabels={{}}
              elevation={1}
              options={[]}
              preSelectedAssignment={[]}
              buttonOptions={[]}
              title={props.courseLabels.title}
              breadCrumbs={[]}
              type={wrapperType.form}
              onSubmit={props.handleSubmit}
              onClickAvatar={props.handleAvatar}
            />
          )}
          {props.option === 1 && props.sessionState === null && (
            <Sessions_
              chipData={[]}
              data={props.listSession}
              onBlur={() => {}}
              onChange={props.searchSession}
              onClickAdd={function noRefCheck() {}}
              onClickAddSession={props.handleClickAddSession}
              onClickCard={props.handleClickSession}
              onClickDelete={function noRefCheck() {}}
              onClickDisable={function noRefCheck() {}}
              onDelete={function noRefCheck() {}}
              onTags={function noRefCheck() {}}
              optionCourse={{
                description:
                  "Descripcion de curso con texto largo para que se pueda mostrar a detalle que es lo que se trae en el curso",
                icon1: "AddCircleOutline",
                icon2: "Delete",
                icon3: "DesktopAccessDisabled",
                id: "optionMenu",
                label1: "Agregar",
                label2: "Eliminar",
                label3: "Deshabilitar",
                name: "Nombre Curso",
              }}
              pageCourseDescription={
                props.sessionsLabels?.pageCourseDescription ?? ""
              }
              pageCourseTitle={props.sessionsLabels?.pageCourseTitle ?? ""}
              pageTitle={props.sessionsLabels?.pageTitle ?? ""}
              title={props.sessionsLabels?.title ?? ""}
            />
          )}
          {props.option === 1 && props.sessionState === formStatus.create && (
            <Session
              id="session"
              errorLabel={props.sessionsLabels?.errorLabel ?? {}}
              label={props.sessionsLabels?.label ?? {}}
              onClickCancel={props.handleClickCancelCreateSession}
              onClickHelp={function noRefCheck() {}}
              onClickHelpButton={function noRefCheck() {}}
              onSubmit={props.handleSubmitSession}
              title="Session"
            />
          )}
          {props.option === 1 && props.sessionState === formStatus.edit && (
            <Session
              id="session"
              errorLabel={props.sessionsLabels?.errorLabel ?? {}}
              label={props.sessionsLabels?.label ?? {}}
              onClickCancel={props.handleClickCancelCreateSession}
              onClickHelp={function noRefCheck() {}}
              onClickHelpButton={function noRefCheck() {}}
              onSubmit={props.handleUpdateSession}
              dataForm={props.dataFormSession}
              title="Session"
              defaultAvatar={props?.defaultAvatar}
            />
          )}
          {props.option === 2 &&
            props.studentState === STUDENTS_PAGE_STATE.ASSIGN && (
              <D3ICourseAssignment
                alertDescription={props?.studentsLabels?.alertDescription ?? ""}
                alertDuration={2000}
                alertTitle={props?.studentsLabels?.alertTitle ?? ""}
                acceptLabel={props?.studentsLabels?.assign ?? ""}
                cancelLabel={props?.studentsLabels?.cancelLabel ?? ""}
                description={props?.studentsLabels?.description ?? ""}
                helperTextTag={props?.studentsLabels?.helperTextTag ?? ""}
                labelTag={props?.studentsLabels?.labelTag ?? ""}
                onPreSelectedTags={() => {}}
                onSubmit={props.handleSubmitStudentAssignment}
                onTags={function noRefCheck() {}}
                optionsTag={props.listStudents ?? []}
                preSelectedAssignments={props.listAssigmentStudents}
                preSelectedDescription={
                  props?.studentsLabels?.preSelectedDescription ?? ""
                }
                preSelectedTitle={props?.studentsLabels?.preSelectedTitle ?? ""}
                title={props?.studentsLabels?.title ?? ""}
                titleTag={props?.studentsLabels?.titleTag ?? ""}
                disableDeleteSelected={true}
                onCancel={props.changeToAssignList}
              />
            )}
          {props.option === 2 &&
            props.studentState === STUDENTS_PAGE_STATE.UNASSIGN && (
              <D3ICourseAssignment
                alertDescription={
                  props?.studentsLabels?.alertDescriptionUnAssign ?? ""
                }
                alertDuration={2000}
                alertTitle={props?.studentsLabels?.alertTitle ?? ""}
                acceptLabel={props?.studentsLabels?.unassign ?? ""}
                cancelLabel={props?.studentsLabels?.cancelLabel ?? ""}
                description={props?.studentsLabels?.descriptionUnAssign ?? ""}
                helperTextTag={props?.studentsLabels?.helperTextTag ?? ""}
                labelTag={props?.studentsLabels?.labelTag ?? ""}
                onPreSelectedTags={() => {}}
                onSubmit={props.handleSubmitStudentUnAssignment}
                onTags={function noRefCheck() {}}
                optionsTag={props.listAssigmentStudents ?? []}
                preSelectedAssignments={[]}
                preSelectedDescription={
                  props?.studentsLabels?.preSelectedDescription ?? ""
                }
                preSelectedTitle={props?.studentsLabels?.preSelectedTitle ?? ""}
                title={props?.studentsLabels?.titleUnAssignemnt ?? ""}
                titleTag={props?.studentsLabels?.titleTag ?? ""}
                disableDeleteSelected={true}
                onCancel={props.changeToAssignList}
              />
            )}

          {props.option === 2 &&
            props.studentState === STUDENTS_PAGE_STATE.LIST && (
              <AssignedStudent_
                assignLabel={props?.studentsLabels?.assign ?? ""}
                unAssignLabel={props?.studentsLabels?.unassign ?? ""}
                onBlur={() => {}}
                onChange={props.searchStudents}
                onClickAdd={props.changeToAssign}
                onClickCard={function noRefCheck() {}}
                onClickDelete={function noRefCheck() {}}
                onClickDisable={function noRefCheck() {}}
                onClickUnassigned={props.changeToUnAssign}
                onDelete={function noRefCheck() {}}
                onTags={function noRefCheck() {}}
                optionCourse={{
                  description: props?.dataForm?.Description ?? "",
                  icon1: "AddCircleOutline",
                  icon2: "HideSource",
                  icon3: "DesktopAccessDisabled",
                  id: "optionMenu",
                  label1: props?.studentsLabels?.assign ?? "",
                  label4: props?.studentsLabels?.unassign ?? "",
                  name: props?.dataForm?.Name ?? "",
                }}
                options={[]}
                pageTitle={props?.studentsLabels?.labelTag ?? ""}
                studentData={props.listAssigmentStudents}
                title={props?.studentsLabels?.labelTag ?? ""}
              />
            )}

          {props.option === 3 &&
            props.staffState === STUDENTS_PAGE_STATE.LIST && (
              <AssignedStudent_
                assignLabel={props?.teacherLabels?.assign ?? ""}
                unAssignLabel={props?.teacherLabels?.unassign ?? ""}
                onChange={props.searchStaff}
                onBlur={function noRefCheck() {}}
                onClickAdd={props.changeToAssign}
                onClickCard={function noRefCheck() {}}
                onClickDelete={function noRefCheck() {}}
                onClickDisable={function noRefCheck() {}}
                onClickUnassigned={props.changeToUnAssign}
                onDelete={function noRefCheck() {}}
                onTags={function noRefCheck() {}}
                optionCourse={{
                  description: props?.dataForm?.Description ?? "",
                  icon1: "AddCircleOutline",
                  icon2: "HideSource",
                  icon3: "DesktopAccessDisabled",
                  id: "optionMenu",
                  label1: props?.teacherLabels?.assign ?? "",
                  label4: props?.teacherLabels?.unassign ?? "",
                  name: props?.dataForm?.Name ?? "",
                }}
                options={[]}
                pageTitle={props?.teacherLabels?.labelTag ?? ""}
                studentData={props.listAssigmentStaff}
                title={props?.teacherLabels?.labelTag ?? ""}
              />
            )}
          {props.option === 3 &&
            props.staffState === STUDENTS_PAGE_STATE.ASSIGN && (
              <D3ICourseAssignment
                alertDescription={props?.teacherLabels?.alertDescription ?? ""}
                alertDuration={2000}
                alertTitle={props?.teacherLabels?.alertTitle ?? ""}
                acceptLabel={props?.teacherLabels?.assign ?? ""}
                cancelLabel={props?.teacherLabels?.cancelLabel ?? ""}
                description={props?.teacherLabels?.description ?? ""}
                helperTextTag={props?.teacherLabels?.helperTextTag ?? ""}
                labelTag={props?.teacherLabels?.labelTag ?? ""}
                onPreSelectedTags={() => {}}
                onSubmit={props.handleSubmitStaffAssignment}
                onTags={function noRefCheck() {}}
                optionsTag={props.listStaff ?? []}
                preSelectedAssignments={props.listAssigmentStaff}
                preSelectedDescription={
                  props?.teacherLabels?.preSelectedDescription ?? ""
                }
                preSelectedTitle={props?.teacherLabels?.preSelectedTitle ?? ""}
                title={props?.teacherLabels?.title ?? ""}
                titleTag={props?.teacherLabels?.titleTag ?? ""}
                disableDeleteSelected={true}
                onCancel={props.changeToAssignList}
              />
            )}

          {props.option === 3 &&
            props.staffState === STUDENTS_PAGE_STATE.UNASSIGN && (
              <D3ICourseAssignment
                alertDescription={
                  props?.teacherLabels?.alertDescriptionUnAssign ?? ""
                }
                alertDuration={2000}
                alertTitle={props?.teacherLabels?.alertTitle ?? ""}
                acceptLabel={props?.teacherLabels?.unassign ?? ""}
                cancelLabel={props?.teacherLabels?.cancelLabel ?? ""}
                description={props?.teacherLabels?.descriptionUnAssign ?? ""}
                helperTextTag={props?.teacherLabels?.helperTextTag ?? ""}
                labelTag={props?.teacherLabels?.labelTag ?? ""}
                onPreSelectedTags={() => {}}
                onSubmit={props.handleSubmitStudentUnAssignment}
                onTags={function noRefCheck() {}}
                optionsTag={props.listAssigmentStaff ?? []}
                preSelectedAssignments={[]}
                preSelectedDescription={
                  props?.teacherLabels?.preSelectedDescription ?? ""
                }
                preSelectedTitle={props?.teacherLabels?.preSelectedTitle ?? ""}
                title={props?.teacherLabels?.titleUnAssignemnt ?? ""}
                titleTag={props?.teacherLabels?.titleTag ?? ""}
                disableDeleteSelected={true}
                onCancel={props.changeToAssignList}
              />
            )}
          {props.option === 4 && (
            <D3IQuizWizard
              error={props.quizWizardLabels?.error ?? {}}
              id="quizWizard"
              label={props.quizWizardLabels?.label ?? {}}
              listQuestionsDefault={props.listQuestions}
              onClickHelpButton={() => {}}
              onSubmit={props.handleSubmitQuiz}
              dataForm={props.dataFormQuiz ?? {}}
            />
          )}
        </div>
      </D3ICourseDetail>
    </div>
  );
};
