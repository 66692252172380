import {
  FEED_CONTACT,
  FEED_CONTACT_CLEAR_DATA,
  FEED_CONTACT_ERROR,
  FEED_CONTACT_SUCCESS,
  FEED_CONTACT_READ,
  FEED_CONTACT_READ_CLEAR_DATA,
  FEED_CONTACT_READ_ERROR,
  FEED_CONTACT_READ_SUCCESS,
} from "./actionTypes";

export const feedContact = (feed, history) => {
  return {
    type: FEED_CONTACT,
    payload: { feed, history },
  };
};

export const feedContactRead = (feed, history) => {
  return {
    type: FEED_CONTACT_READ,
    payload: { feed, history },
  };
};

export const feedContactSuccess = (success) => {
  return {
    type: FEED_CONTACT_SUCCESS,
    payload: success,
  };
};

export const feedContactReadSuccess = (success) => {
  return {
    type: FEED_CONTACT_READ_SUCCESS,
    payload: success,
  };
};

export const feedContactError = (error) => {
  return {
    type: FEED_CONTACT_ERROR,
    payload: error,
  };
};

export const feedContactReadError = (error) => {
  return {
    type: FEED_CONTACT_READ_ERROR,
    payload: error,
  };
};

export const feedContactClearData = () => {
  return {
    type: FEED_CONTACT_CLEAR_DATA,
    payload: {},
  };
};

export const feedContactReadClearData = () => {
  return {
    type: FEED_CONTACT_READ_CLEAR_DATA,
    payload: {},
  };
};
