import GetUrl from "../../services/config";
import LocalStorageService from "../../services/localStorage";
import { STORE_KEY } from "../../utils/constants";
import { fetchRequest } from "../fetchRequest";
import { URLs } from "../url";

const localStorageService = new LocalStorageService(STORE_KEY);

const getFeedsByContact = async (body: any) => {
  const url = `${GetUrl(URLs.feeds)}`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
      studentId: undefined,
    },
  };
  if (userInfo?.students && userInfo?.students?.length > 0)
    requestOptions.headers.studentId = userInfo?.students[0]?.id;
  try {
    return await fetchRequest(url, requestOptions);
  } catch (error: any) {
    throw error;
  }
};

const readFeed = async (body: any) => {
  const url = `${GetUrl(URLs.feeds)}/${body.id}/Read`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
      studentId: undefined,
    },
  };
  if (userInfo?.students && userInfo?.students?.length > 0)
    requestOptions.headers.studentId = userInfo?.students[0]?.id;
  try {
    return await fetchRequest(url, requestOptions);
  } catch (error: any) {
    throw error;
  }
};

export { getFeedsByContact,readFeed };
