import { combineReducers } from "redux";
import Login from "./auth/reducer";
import Contact from "./contact/reducer";
import Tag from "./tag/reducer";
import Space from "./space/reducer";
import Session from "./session/reducer";
import Training from "./training/reducer";
import Feed from './feed/reducer'

const rootReducer = combineReducers({
  Login,
  Contact,
  Tag,
  Space,
  Session,
  Training,
  Feed
});

export default rootReducer;
