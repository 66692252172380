import { call, put, takeEvery } from "redux-saga/effects";
import {
  TRAINING_ATTEND,
  TRAINING_ASSIGNMENT,
  TRAINING_UNASSIGNMENT,
} from "./actionTypes";
import {
  trainingSuccess,
  trainingError,
  trainingAssignmentSuccess,
  trainingAssignmentError,
  trainingUnAssignmentSuccess,
  trainingUnAssignmentError,
} from "./actions";

import {
  trainingUnAssignmentContact,
  updateTrainingAttend,
} from "../../resources/training/sagaHelper";
import { sessionTrainingAssignment } from "../../resources/space/sagaHelper";

function* trainingAttend({ payload: { training } }) {
  try {
    const response = yield call(updateTrainingAttend, training);
    yield put(trainingSuccess(response));
  } catch (error) {
    yield put(trainingError(error?.message));
  }
}

function* trainingAssignment({ payload: { training } }) {
  try {
    const response = yield call(sessionTrainingAssignment, training);
    yield put(trainingAssignmentSuccess(response));
  } catch (error) {
    yield put(trainingAssignmentError(error?.message));
  }
}

function* trainingUnAssignment({ payload: { training } }) {
  try {
    const response = yield call(trainingUnAssignmentContact, training);
    yield put(trainingUnAssignmentSuccess(response));
  } catch (error) {
    yield put(trainingUnAssignmentError(error?.message));
  }
}

function* trainingSaga() {
  yield takeEvery(TRAINING_ATTEND, trainingAttend);
  yield takeEvery(TRAINING_ASSIGNMENT, trainingAssignment);
  yield takeEvery(TRAINING_UNASSIGNMENT, trainingUnAssignment);
}

export default trainingSaga;
