import React, { useEffect, useState } from "react";
import { View } from "./view";
import LocalStorageService from "../../services/localStorage";
import {
  MenuItem,
  MenuOptionsList,
  feedType,
} from "@edukcare/github-packages-edukcare-components/dist";
import {
  GetMenuSideBarAdmin,
  GetMenuSideBarHome,
  GetMenuSideBarLearner,
  GetMenuSideBarHomeStudent,
} from "../../services/nav";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGOUT, STORE_KEY } from "../../utils/constants";
import { USER_ROL } from "../../enum/login";
import { useDispatch, useSelector } from "react-redux";
import {
  feedContact,
  feedContactRead,
  feedContactReadClearData,
} from "../../store/feed/action";
import { FeedData } from "../../model/feed";
import { FEED_TYPE } from "../../enum/feed";

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const Layout = ({ children }: Props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation("global");
  const storageKeyMh = "edukcareMenuH";
  const lsMH = new LocalStorageService(storageKeyMh);
  const storageKeyMs = "edukcareMenuS";
  const lsMS = new LocalStorageService(storageKeyMs);
  const localStoreService = new LocalStorageService(STORE_KEY);
  const [userInfo, setUserInfo] = useState<any>({});
  const [notification, setNotification] = useState<any[]>([]);

  const { loadingFeedContact, errorFeedContact, responseFeedContact } =
    useSelector((state: any) => ({
      responseFeedContact: state.Feed.responseFeedContact,
      loadingFeedContact: state.Feed.loadingFeedContact,
      errorFeedContact: state.Feed.errorFeedContact,
    }));

  const {
    loadingFeedContactRead,
    errorFeedContactRead,
    responseFeedContactRead,
  } = useSelector((state: any) => ({
    responseFeedContactRead: state.Feed.responseFeedContactRead,
    loadingFeedContactRead: state.Feed.loadingFeedContactRead,
    errorFeedContactRead: state.Feed.errorFeedContactRead,
  }));

  const [menuHorizontal, setMenuHorizontal] = React.useState<MenuItem[]>(
    lsMH.getMenuHorizontalStored()
  );
  const [menuSideBar, setMenuSideBar] = React.useState<MenuOptionsList[]>(
    lsMS.getMenuSideBarStored()
  );

  const setActiveMenuHorizontal = (item: MenuItem) => {
    const userInfo: any = localStoreService.getUserStored();
    const updatedMenuH = menuHorizontal.map((menuItem) => {
      if (menuItem.id === item.id) {
        return { ...menuItem, active: true };
      }
      return { ...menuItem, active: false };
    });
    switch (item.id) {
      case "home":
        navigate("/home");
        lsMS.setMenuSideBarStored(
          userInfo?.user?.role?.toLocaleLowerCase() ===
            USER_ROL.STUDENT.toLocaleLowerCase()
            ? GetMenuSideBarHomeStudent(t)
            : GetMenuSideBarHome(t)
        );
        break;
      case "Training":
        navigate("/learner");
        lsMS.setMenuSideBarStored(GetMenuSideBarLearner(t));
        break;
      case "admin":
        navigate("/contact");
        lsMS.setMenuSideBarStored(GetMenuSideBarAdmin(t));
        break;
      default:
        break;
    }

    let st = lsMS.getMenuSideBarStored();

    setMenuSideBar(st);
    setMenuHorizontal(updatedMenuH);
    lsMH.setMenuHorizontalStored(updatedMenuH);
  };

  const setActiveMenuSideBar = (item: MenuOptionsList) => {
    const updatedMenuS = menuSideBar.map((menuItem) => {
      if (menuItem.id === item.id) {
        return { ...menuItem, active: true };
      }
      return { ...menuItem, active: false };
    });
    const actualMenu = menuSideBar.find((menuItem) => menuItem.id === item.id);

    setMenuSideBar(updatedMenuS);
    lsMS.setMenuSideBarStored(updatedMenuS);
    if (actualMenu) {
      switch (actualMenu.id) {
        case "Training":
          navigate("/learner");
          lsMS.setMenuSideBarStored(GetMenuSideBarLearner(t));
          setMenuSideBar(GetMenuSideBarLearner(t));
          break;
      }
      navigate(actualMenu.route);
    }
  };

  const onClickContextMenu = (item: MenuItem) => {
    if (item.id === LOGOUT) {
      localStoreService.logOut();
      window.location.reload();
      return;
    }
  };

  const getFeedType = (type: FEED_TYPE | undefined) => {
    if (type === FEED_TYPE.SESSION) return feedType.SESSION;
    return feedType.TRAINING;
  };

  const getResource = (type: FEED_TYPE | undefined, data: any) => {
    const basePath =
      userInfo?.role?.toLocaleLowerCase() ===
      USER_ROL.STUDENT.toLocaleLowerCase()
        ? "/learner/detail/"
        : "/training/";
    return `${basePath}${data?.target?.id}`;
  };

  const onClickNotification = (data: any) => {
    if (data?.resource) {
      if (!data?.read) dispatch(feedContactRead({ id: data?.id }));
      navigate(data?.resource);
    }
  };

  useEffect(() => {
    setNotification([]);
    const userInfo: any = localStoreService.getUserStored();
    if (userInfo) {
      setUserInfo({
        ...userInfo.user,
        userOption: [
          {
            avatar: {
              image: userInfo?.user?.avatar?.href,
            },
            disabled: false,
            divider: true,
            id: "1",
            name: "Profile",
          },
          {
            disabled: false,
            icon: {
              color: "rgb(32,32,32)",
              icon: "ExitToApp",
              size: "standard",
            },
            id: LOGOUT,
            name: "Log out",
          },
        ],
      });
    }
  }, []);

  useEffect(() => {
    if (location) {
      dispatch(feedContact({}));
    }
  }, [location]);

  useEffect(() => {
    if (responseFeedContact?.data) {
      const data = responseFeedContact.data as FeedData[];
      setNotification(
        data.map((feed: FeedData) => {
          return {
            type: getFeedType(feed.target?.type),
            title: feed.title ?? "",
            subtitle: feed.description ?? "",
            read: feed.read ?? false,
            data: {
              id: feed.id,
              type: getFeedType(feed.target?.type),
              resource: getResource(feed.target?.type, feed),
              target: feed.target,
              read: feed.read ?? false,
            },
            labelReader: t("layout.feed.markAsRead"),
          };
        })
      );
    }
  }, [responseFeedContact]);

  useEffect(() => {
    if (responseFeedContactRead?.success) {
      dispatch(feedContactReadClearData());
      dispatch(feedContact());
    }
  }, [responseFeedContactRead]);

  useEffect(() => {
    if (errorFeedContactRead?.data) {
      dispatch(feedContactReadClearData());
    }
  }, [errorFeedContactRead]);

  const handleClickReadFeed = (data: any) => {
    dispatch(feedContactRead({ id: data?.id }));
  };

  return (
    <View
      menuHorizontal={menuHorizontal}
      menuSideBar={menuSideBar}
      children={children}
      setActiveMenuHorizontal={setActiveMenuHorizontal}
      setActiveMenuSideBar={setActiveMenuSideBar}
      userInfo={userInfo}
      onClickContextMenu={onClickContextMenu}
      feed={{
        id: "idList",
        cant: notification.filter((feed: FeedData) => !feed.read).length,
        data: notification,
        labelReader: t("layout.feed.markAsRead"),
        iconColor: "white",
      }}
      handleClickReadFeed={handleClickReadFeed}
      onClickNotification={onClickNotification}
    />
  );
};
