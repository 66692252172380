import { call, put, takeEvery } from "redux-saga/effects";
import {
  CONTACT,
  CONTACT_STAFF,
  CONTACT_STUDENT,
  CREATE_CONTACT,
  DELETE_CONTACT,
  DISABLE_CONTACT,
  UPDATE_CONTACT,
  CONTACT_SPACE_STUDENT,
  CONTACT_SPACE_STAFF,
} from "./actionTypes";
import {
  contactSuccess,
  contactError,
  createContactSuccess,
  createContactError,
  updateContactSuccess,
  updateContactError,
  disableContactSuccess,
  disableContactError,
  deleteContactSuccess,
  deleteContactError,
  contactStudentSuccess,
  contactStudentError,
  contactStaffSuccess,
  contactStaffError,
  contactStudentSpaceSuccess,
  contactSpaceStudentError,
  contactStaffSpaceSuccess,
  contactSpaceStaffError,
} from "./actions";

import {
  createContact,
  getAll,
  updateContact,
  disableContact,
  deleteContact,
} from "../../resources/contact/sagaHelper";
import { getContactsTrainingByType } from "../../resources/space/sagaHelper";

function* contact({ payload: { contact } }) {
  try {
    const response = yield call(getAll, contact);
    yield put(contactSuccess(response));
  } catch (error) {
    yield put(contactError(error?.message));
  }
}

function* contactStudent({ payload: { contact } }) {
  try {
    const response = yield call(getAll, contact);
    yield put(contactStudentSuccess(response));
  } catch (error) {
    yield put(contactStudentError(error?.message));
  }
}

function* contactSpaceStudent({ payload: { contact } }) {
  try {
    const response = yield call(getContactsTrainingByType, contact);
    yield put(contactStudentSpaceSuccess(response));
  } catch (error) {
    yield put(contactSpaceStudentError(error?.message));
  }
}

function* contactSpaceStaff({ payload: { contact } }) {
  try {
    const response = yield call(getContactsTrainingByType, contact);
    yield put(contactStaffSpaceSuccess(response));
  } catch (error) {
    yield put(contactSpaceStaffError(error?.message));
  }
}

function* contactStaff({ payload: { contact } }) {
  try {
    const response = yield call(getAll, contact);
    yield put(contactStaffSuccess(response));
  } catch (error) {
    yield put(contactStaffError(error?.message));
  }
}

function* createContactSaga({ payload: { contact } }) {
  try {
    const response = yield call(createContact, contact);
    yield put(createContactSuccess(response));
  } catch (error) {
    yield put(createContactError(error?.message));
  }
}

function* updateContactSaga({ payload: { contact } }) {
  try {
    const response = yield call(updateContact, contact);
    yield put(updateContactSuccess(response));
  } catch (error) {
    yield put(updateContactError(error?.message));
  }
}

function* disableContactSaga({ payload: { contact } }) {
  try {
    const response = yield call(disableContact, contact);
    yield put(disableContactSuccess(response));
  } catch (error) {
    yield put(disableContactError(error?.message));
  }
}

function* deleteContactSaga({ payload: { contact } }) {
  try {
    const response = yield call(deleteContact, contact);
    yield put(deleteContactSuccess(response));
  } catch (error) {
    yield put(deleteContactError(error?.message));
  }
}

function* contactSaga() {
  yield takeEvery(CONTACT, contact);
  yield takeEvery(CREATE_CONTACT, createContactSaga);
  yield takeEvery(UPDATE_CONTACT, updateContactSaga);
  yield takeEvery(DISABLE_CONTACT, disableContactSaga);
  yield takeEvery(DELETE_CONTACT, deleteContactSaga);
  yield takeEvery(CONTACT_STUDENT, contactStudent);
  yield takeEvery(CONTACT_STAFF, contactStaff);
  yield takeEvery(CONTACT_SPACE_STUDENT, contactSpaceStudent);
  yield takeEvery(CONTACT_SPACE_STAFF, contactSpaceStaff);
}

export default contactSaga;
