export const SPACE = "SPACE";
export const SPACE_UPDATE = "SPACE_UPDATE";
export const BRANCH = "BRANCH";
export const CREATE_SPACE = "CREATE_SPACE";
export const UPDATE_AVATAR_SPACE = "UPDATE_AVATAR_SPACE";
export const SPACE_TRAINING_FINISH = "SPACE_TRAINING_FINISH";
export const SPACE_ONE = "SPACE_ONE";
export const SPACE_SUCCESS = "SPACE_SUCCESS";
export const SPACE_UPDATE_SUCCESS = "SPACE_UPDATE_SUCCESS";
export const BRANCH_SUCCESS = "BRANCH_SUCCESS";
export const UPDATE_AVATAR_SPACE_SUCCESS = "UPDATE_AVATAR_SPACE_SUCCESS";
export const CREATE_SPACE_SUCCESS = "CREATE_SPACE_SUCCESS";
export const SPACE_TRAINING_FINISH_SUCCESS = "SPACE_TRAINING_FINISH_SUCCESS";
export const SPACE_ONE_SUCCESS = "SPACE_ONE_SUCCESS";
export const SPACE_ERROR = "SPACE_ERROR";
export const SPACE_UPDATE_ERROR = "SPACE_UPDATE_ERROR";
export const BRANCH_ERROR = "BRANCH_ERROR";
export const UPDATE_AVATAR_SPACE_ERROR = "UPDATE_AVATAR_SPACE_ERROR";
export const CREATE_SPACE_ERROR = "CREATE_SPACE_ERROR";
export const SPACE_TRAINING_FINISH_ERROR = "SPACE_TRAINING_FINISH_ERROR";
export const SPACE_ONE_ERROR = "SPACE_ONE_ERROR";
export const SPACE_CLEAR_DATA = "SPACE_CLEAR_DATA";
export const SPACE_UPDATE_CLEAR_DATA = "SPACE_UPDATE_CLEAR_DATA";
export const BRANCH_CLEAR_DATA = "BRANCH_CLEAR_DATA";
export const UPDATE_AVATAR_SPACE_CLEAR_DATA = "UPDATE_AVATAR_SPACE_CLEAR_DATA";
export const CREATE_SPACE_CLEAR_DATA = "CREATE_ÍSPACE_CLEAR_DATA";
export const SPACE_TRAINING_FINISH_CLEAR_DATA =
  "SPACE_TRAINING_FINISH_CLEAR_DATA";
export const SPACE_ONE_CLEAR_DATA = "SPACE_ONE_CLEAR_DATA";
