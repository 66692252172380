import {
  TRAINING_ATTEND,
  TRAINING_ATTEND_CLEAR_DATA,
  TRAINING_ATTEND_ERROR,
  TRAINING_ATTEND_SUCCESS,
  TRAINING_ASSIGNMENT,
  TRAINING_UNASSIGNMENT,
  TRAINING_ASSIGNMENT_CLEAR_DATA,
  TRAINING_UNASSIGNMENT_CLEAR_DATA,
  TRAINING_ASSIGNMENT_ERROR,
  TRAINING_UNASSIGNMENT_ERROR,
  TRAINING_ASSIGNMENT_SUCCESS,
  TRAINING_UNASSIGNMENT_SUCCESS,
} from "./actionTypes";

export const trainingAttend = (training, history) => {
  return {
    type: TRAINING_ATTEND,
    payload: { training, history },
  };
};

export const trainingAssignment = (training, history) => {
  return {
    type: TRAINING_ASSIGNMENT,
    payload: { training, history },
  };
};

export const trainingUnAssignment = (training, history) => {
  return {
    type: TRAINING_UNASSIGNMENT,
    payload: { training, history },
  };
};

export const trainingSuccess = (success) => {
  return {
    type: TRAINING_ATTEND_SUCCESS,
    payload: success,
  };
};

export const trainingUnAssignmentSuccess = (success) => {
  return {
    type: TRAINING_UNASSIGNMENT_SUCCESS,
    payload: success,
  };
};

export const trainingAssignmentSuccess = (success) => {
  return {
    type: TRAINING_ASSIGNMENT_SUCCESS,
    payload: success,
  };
};

export const trainingError = (error) => {
  return {
    type: TRAINING_ATTEND_ERROR,
    payload: error,
  };
};

export const trainingAssignmentError = (error) => {
  return {
    type: TRAINING_ASSIGNMENT_ERROR,
    payload: error,
  };
};

export const trainingUnAssignmentError = (error) => {
  return {
    type: TRAINING_UNASSIGNMENT_ERROR,
    payload: error,
  };
};

export const trainingAttendClearData = () => {
  return {
    type: TRAINING_ATTEND_CLEAR_DATA,
    payload: {},
  };
};

export const trainingAssignmentClearData = () => {
  return {
    type: TRAINING_ASSIGNMENT_CLEAR_DATA,
    payload: {},
  };
};

export const trainingUnAssignmentClearData = () => {
  return {
    type: TRAINING_UNASSIGNMENT_CLEAR_DATA,
    payload: {},
  };
};
