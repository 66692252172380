import React from "react";
import {
  D3IContainer,
  FeedListProps,
  MenuItem,
  MenuOptionsList,
} from "@edukcare/github-packages-edukcare-components/dist";
import logo from "../../assets/images/edukLogo.svg";

export const View = (props: {
  menuHorizontal: MenuItem[];
  menuSideBar: MenuOptionsList[];
  children: React.ReactNode;
  setActiveMenuHorizontal: (item: MenuItem) => void;
  setActiveMenuSideBar: (item: MenuOptionsList) => void;
  onClickContextMenu: (item: MenuItem) => void;
  handleClickReadFeed?: (data: any) => void;
  onClickNotification?: (data: any) => void;
  userInfo: any;
  feed?: FeedListProps;
}) => {
  return (
    <div style={{ width: "100%" }}>
      <D3IContainer
        logo={logo}
        menuList={props.menuSideBar}
        menuHorizontal={props.menuHorizontal}
        menuStartOpen={true}
        onClickMenuSideBar={props.setActiveMenuSideBar}
        onClickMenuHorizontal={props.setActiveMenuHorizontal}
        userName={props?.userInfo?.user_name ?? ""}
        userOption={props?.userInfo?.userOption ?? []}
        onClickContextMenu={props?.onClickContextMenu}
        onClickReadNotification={props?.handleClickReadFeed}
        onClickNotification={props?.onClickNotification}
        feed={props.feed}
      >
        <div style={{ width: "100%", padding: 10 }}>{props.children}</div>
      </D3IContainer>
    </div>
  );
};
