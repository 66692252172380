import React from "react";
import "./App.css";
import { Layout } from "./components/layout/layout";
import { AppRouter } from "./routes/appRouter";
import { PublicRoutes } from "./routes/publicRoutes";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthProvider, useAuth } from "./authContext";

function App() {
  const { status } = useAuth();

  //i18n.changeLanguage("es");
  return (
    <BrowserRouter>
      {status !== "no-authenticated" ? (
        <AppRouter />
      ) : (
        <Routes>
          <Route path="/*" element={<PublicRoutes />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

const WrappedApp = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default WrappedApp;
