import {
  SPACE,
  SPACE_SUCCESS,
  SPACE_ERROR,
  SPACE_CLEAR_DATA,
  SPACE_ONE,
  SPACE_ONE_CLEAR_DATA,
  SPACE_ONE_ERROR,
  SPACE_ONE_SUCCESS,
  SPACE_TRAINING_FINISH,
  SPACE_TRAINING_FINISH_CLEAR_DATA,
  SPACE_TRAINING_FINISH_SUCCESS,
  SPACE_TRAINING_FINISH_ERROR,
  CREATE_SPACE,
  CREATE_SPACE_CLEAR_DATA,
  CREATE_SPACE_ERROR,
  CREATE_SPACE_SUCCESS,
  UPDATE_AVATAR_SPACE,
  UPDATE_AVATAR_SPACE_CLEAR_DATA,
  UPDATE_AVATAR_SPACE_ERROR,
  UPDATE_AVATAR_SPACE_SUCCESS,
  BRANCH,
  BRANCH_CLEAR_DATA,
  BRANCH_ERROR,
  BRANCH_SUCCESS,
  SPACE_UPDATE,
  SPACE_UPDATE_CLEAR_DATA,
  SPACE_UPDATE_ERROR,
  SPACE_UPDATE_SUCCESS,
} from "./actionTypes";

export const space = (space, history) => {
  return {
    type: SPACE,
    payload: { space, history },
  };
};

export const spaceUpdate = (space, history) => {
  return {
    type: SPACE_UPDATE,
    payload: { space, history },
  };
};

export const branch = (space, history) => {
  return {
    type: BRANCH,
    payload: { space, history },
  };
};

export const createSpace = (space, history) => {
  return {
    type: CREATE_SPACE,
    payload: { space, history },
  };
};

export const updateAvatarSpace = (space, history) => {
  return {
    type: UPDATE_AVATAR_SPACE,
    payload: { space, history },
  };
};

export const spaceTrainingFinish = (space, history) => {
  return {
    type: SPACE_TRAINING_FINISH,
    payload: { space, history },
  };
};

export const spaceOne = (space, history) => {
  return {
    type: SPACE_ONE,
    payload: { space, history },
  };
};

export const spaceSuccess = (success) => {
  return {
    type: SPACE_SUCCESS,
    payload: success,
  };
};

export const spaceUpdateSuccess = (success) => {
  return {
    type: SPACE_UPDATE_SUCCESS,
    payload: success,
  };
};

export const branchSuccess = (success) => {
  return {
    type: BRANCH_SUCCESS,
    payload: success,
  };
};

export const updateAvatarSpaceSuccess = (success) => {
  return {
    type: UPDATE_AVATAR_SPACE_SUCCESS,
    payload: success,
  };
};

export const createSpaceSuccess = (success) => {
  return {
    type: CREATE_SPACE_SUCCESS,
    payload: success,
  };
};

export const spaceTrainingFinishSuccess = (success) => {
  return {
    type: SPACE_TRAINING_FINISH_SUCCESS,
    payload: success,
  };
};

export const spaceOneSuccess = (success) => {
  return {
    type: SPACE_ONE_SUCCESS,
    payload: success,
  };
};

export const spaceError = (error) => {
  return {
    type: SPACE_ERROR,
    payload: error,
  };
};

export const spaceUpdateError = (error) => {
  return {
    type: SPACE_UPDATE_ERROR,
    payload: error,
  };
};

export const branchError = (error) => {
  return {
    type: BRANCH_ERROR,
    payload: error,
  };
};

export const updateAvatarSpaceError = (error) => {
  return {
    type: UPDATE_AVATAR_SPACE_ERROR,
    payload: error,
  };
};

export const createSpaceError = (error) => {
  return {
    type: CREATE_SPACE_ERROR,
    payload: error,
  };
};

export const spaceTrainingFinishError = (error) => {
  return {
    type: SPACE_TRAINING_FINISH_ERROR,
    payload: error,
  };
};

export const spaceOneError = (error) => {
  return {
    type: SPACE_ONE_ERROR,
    payload: error,
  };
};

export const spaceClearData = () => {
  return {
    type: SPACE_CLEAR_DATA,
    payload: {},
  };
};

export const spaceUpdateClearData = () => {
  return {
    type: SPACE_UPDATE_CLEAR_DATA,
    payload: {},
  };
};

export const branchClearData = () => {
  return {
    type: BRANCH_CLEAR_DATA,
    payload: {},
  };
};

export const updateAvatarSpaceClearData = () => {
  return {
    type: UPDATE_AVATAR_SPACE_CLEAR_DATA,
    payload: {},
  };
};

export const spaceOneClearData = () => {
  return {
    type: SPACE_ONE_CLEAR_DATA,
    payload: {},
  };
};

export const createSpaceClearData = () => {
  return {
    type: CREATE_SPACE_CLEAR_DATA,
    payload: {},
  };
};

export const spaceFinishTrainingClearData = () => {
  return {
    type: SPACE_TRAINING_FINISH_CLEAR_DATA,
    payload: {},
  };
};
