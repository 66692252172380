import {
  TAG,
  TAG_ERROR,
  TAG_SUCCESS,
  TAG_CLEAR_DATA,
  TAG_SPACE_ERROR,
  TAG_SPACE,
  TAG_SPACE_CLEAR_DATA,
  TAG_SPACE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorTag: null,
  responseTag: null,
  loadingTag: false,
  errorSpaceTag: null,
  responseSpaceTag: null,
  loadingSpaceTag: false,
};

const Tag = (state = initialState, action) => {
  switch (action.type) {
    case TAG:
      state = {
        ...state,
        loadingTag: true,
        errorTag: null,
        responseTag: true,
      };
      break;
    case TAG_SPACE:
      state = {
        ...state,
        loadingSpaceTag: true,
        errorSpaceTag: null,
        responseSpaceTag: true,
      };
      break;
    case TAG_SUCCESS:
      state = {
        ...state,
        errorTag: null,
        loadingTag: false,
        responseTag: action.payload,
      };
      break;
    case TAG_SPACE_SUCCESS:
      state = {
        ...state,
        errorSpaceTag: null,
        loadingSpaceTag: false,
        responseSpaceTag: action.payload,
      };
      break;
    case TAG_ERROR:
      state = {
        ...state,
        errorTag: action.payload,
        loadingTag: false,
        responseTag: null,
      };
      break;
    case TAG_SPACE_ERROR:
      state = {
        ...state,
        errorSpaceTag: action.payload,
        loadingSpaceTag: false,
        responseSpaceTag: null,
      };
      break;
    case TAG_CLEAR_DATA:
      state = {
        ...state,
        errorTag: null,
        loadingTag: null,
        responseTag: false,
      };
      break;
    case TAG_SPACE_CLEAR_DATA:
      state = {
        ...state,
        errorSpaceTag: null,
        loadingSpaceTag: null,
        responseSpaceTag: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Tag;
