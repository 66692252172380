import {LOGIN_USER, LOGIN_USER_CLEAR_DATA, LOGIN_USER_ERROR, LOGIN_USER_SUCCESS} from "./actionTypes"

export const loginUser = (user, history) => {
    return {
        type: LOGIN_USER,
        payload: {user, history},
    }
}

export const loginUserSuccess = success => {
    return {
        type: LOGIN_USER_SUCCESS,
        payload: success,
    }
}

export const loginUserError = error => {
    return {
        type: LOGIN_USER_ERROR,
        payload: error
    }
}

export const loginUserClearData = () => {
    return {
        type: LOGIN_USER_CLEAR_DATA,
        payload: {}
    };
};

