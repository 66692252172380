import React, { useEffect } from "react";
import { LoginView } from "./view";
import {
  Loading_,
  onSignInDTO,
  position,
  typeAlert,
  typeLoaders,
} from "@edukcare/github-packages-edukcare-components/dist";
import { themeCore } from "../../assets/themes/theme";
import { useAuth } from "../../authContext";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../store/auth/actions";
import LocalStorageService from "../../services/localStorage";
import showToast from "../../services/toast";
import { useTranslation } from "react-i18next";
import {
  GetMenuHorizontalAdmin,
  GetMenuHorizontalStudent,
  GetMenuSideBarHome,
  GetMenuSideBarHomeStudent,
  GetMenuSideBarLearner,
} from "../../services/nav";
import { USER_ROL } from "../../enum/login";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  const { setStatus } = useAuth();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { responseLogin, errorLogin, loadingLogin } = useSelector(
    (state: any) => ({
      responseLogin: state.Login.responseLogin?.data,
      errorLogin: state.Login.errorLogin,
      loadingLogin: state.Login.loadingLogin,
    })
  );

  const storageKey = "edukcare";
  const storageKeyMh = "edukcareMenuH";
  const storageKeyMs = "edukcareMenuS";
  const localStorageService = new LocalStorageService(storageKey);
  const lsMH = new LocalStorageService(storageKeyMh);
  const lsMS = new LocalStorageService(storageKeyMs);

  useEffect(() => {
    if (responseLogin) {
      const role = responseLogin?.user?.role;
      localStorageService.setUserStored(responseLogin);
      lsMH.setMenuHorizontalStored(
        role.toLocaleLowerCase() === USER_ROL.STUDENT.toLocaleLowerCase()
          ? GetMenuHorizontalStudent(t)
          : GetMenuHorizontalAdmin(t)
      );
      lsMS.setMenuSideBarStored(
        role.toLocaleLowerCase() === USER_ROL.STUDENT.toLocaleLowerCase()
          ? GetMenuSideBarLearner(t)
          : GetMenuSideBarHome(t)
      );

      setStatus("authenticated");
      navigate("/welcome");
    }
  }, [responseLogin, setStatus]);

  useEffect(() => {
    if (errorLogin) {
      setSeeToast({ show: true, message: errorLogin, type: typeAlert.error });
    }
  }, [errorLogin]);

  const lgSingIn = (form: onSignInDTO) => {
    setSeeToast({ show: false, message: errorLogin, type: typeAlert.error });
    dispatch(
      loginUser({
        app_id: "app",
        data: {
          userName: form.email,
          password: form.password,
        },
      })
    );
  };

  return (
    <>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message)}
      <Loading_
        loading={loading}
        type={typeLoaders.BarLoader}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text="Iniciando Session!"
        bgColor={themeCore.colors.backgroundPrimaryTransparent}
      />
      <LoginView onSingIn={lgSingIn} />
    </>
  );
};
export default Login;
