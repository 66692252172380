import LocalStorageService from "../../services/localStorage";
import { STORE_KEY } from "../../utils/constants";
import GetUrl from "../../services/config";
import { URLs } from "../url";
import { buildQueryString } from "../../utils/queryParams";
import { fetchRequest } from "../fetchRequest";
import { SpaceResponse } from "../../model/space";
import { ContactResponse } from "../../model/contact";

const localStorageService = new LocalStorageService(STORE_KEY);

const createNewSpace = async (data: any) => {
  const url = `${GetUrl(URLs.spaces)}/`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
    },
    body: JSON.stringify(data),
  };
  try {
    return (await fetchRequest(url, requestOptions)) as SpaceResponse;
  } catch (errors: any) {
    throw errors;
  }
};

const updateSpace = async (data: any) => {
  const url = `${GetUrl(URLs.spaces)}/${data.id}`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
    },
    body: JSON.stringify(data.body),
  };
  try {
    return (await fetchRequest(url, requestOptions)) as SpaceResponse;
  } catch (errors: any) {
    throw errors;
  }
};

const uploadAvatarSpace = async (data: any) => {
  const url = `${GetUrl(URLs.spaces)}/${data.id}/Avatar`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "PUT",
    headers: {
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
      userId: undefined,
    },
    body: data.body,
  };
  if (userInfo?.user) requestOptions.headers.userId = userInfo?.user?.id;
  try {
    return (await fetchRequest(url, requestOptions)) as SpaceResponse;
  } catch (errors: any) {
    throw errors;
  }
};

const getAll = async (params: any) => {
  const url = `${GetUrl(URLs.spaces)}?${buildQueryString(params)}`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
    },
  };
  try {
    return (await fetchRequest(url, requestOptions)) as SpaceResponse;
  } catch (errors: any) {
    throw errors;
  }
};

const getOne = async (params: any) => {
  const contactId = params.contactId;
  delete params.contactId;
  const url = `${GetUrl(URLs.spaces)}/${params.id}`;
  const userInfo: any = localStorageService.getUserStored();
  let requestOptions: any = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
    },
  };
  if (contactId) requestOptions.headers.studentId = contactId;

  try {
    return (await fetchRequest(url, requestOptions)) as SpaceResponse;
  } catch (errors: any) {
    throw errors;
  }
};

const updateTrainingFinish = async (data: any) => {
  const url = `${GetUrl(URLs.spaces)}/${data?.id}/Finish`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      Authorization: userInfo?.token,
      studentId: undefined,
    },
  };
  if (userInfo?.students && userInfo?.students?.length > 0)
    requestOptions.headers.studentId = userInfo?.students[0]?.id;
  try {
    return await fetchRequest(url, requestOptions);
  } catch (error: any) {
    throw error;
  }
};

const createNewSession = async (data: any) => {
  const url = `${GetUrl(URLs.spaces)}/${data.id}/Sessions`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "POST",
    headers: {
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
    },
    body: JSON.stringify(data.body),
  };
  try {
    return await fetchRequest(url, requestOptions);
  } catch (errors: any) {
    throw errors;
  }
};

const uploadResourceSession = async (data: any) => {
  const url = `${GetUrl(URLs.spaces)}/${data.spaceId}/Sessions/${
    data.sessionId
  }/Resources`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "POST",
    headers: {
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
      userId: undefined,
    },
    body: data.body,
  };
  if (userInfo?.user) requestOptions.headers.userId = userInfo?.user?.id;
  try {
    return await fetchRequest(url, requestOptions);
  } catch (errors: any) {
    throw errors;
  }
};

const sessionTrainingAssignment = async (data: any) => {
  const url = `${GetUrl(URLs.spaces)}/${data.id}/Trainings/Assignments`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "POST",
    headers: {
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
    },
    body: JSON.stringify(data.body),
  };
  try {
    return await fetchRequest(url, requestOptions);
  } catch (errors: any) {
    throw errors;
  }
};

const getContactsTrainingByType = async (params: any) => {
  const contactId = params.contactId;
  delete params.contactId;
  const url = `${GetUrl(URLs.spaces)}/${params.id}/Trainings/Contacts?types=${
    params.type
  }`;
  const userInfo: any = localStorageService.getUserStored();
  let requestOptions: any = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
    },
  };
  if (contactId) requestOptions.headers.studentId = contactId;

  try {
    return (await fetchRequest(url, requestOptions)) as ContactResponse;
  } catch (errors: any) {
    throw errors;
  }
};

const updateActualSession = async (data: any) => {
  const url = `${GetUrl(URLs.spaces)}/${data.spaceId}/Sessions/${
    data.sessionId
  }`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "PUT",
    headers: {
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
    },
    body: JSON.stringify(data.body),
  };
  try {
    return await fetchRequest(url, requestOptions);
  } catch (errors: any) {
    throw errors;
  }
};

export {
  getAll,
  getOne,
  updateTrainingFinish,
  createNewSpace,
  uploadAvatarSpace,
  createNewSession,
  uploadResourceSession,
  updateSpace,
  sessionTrainingAssignment,
  getContactsTrainingByType,
  updateActualSession
};
