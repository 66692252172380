import { all, fork } from "redux-saga/effects";
import LoginSaga from "./auth/saga";
import ContactSaga from "./contact/saga";
import TagSaga from "./tag/saga";
import SpaceSaga from "./space/saga";
import SessionSaga from "./session/saga";
import TrainingSaga from "./training/saga";
import FeedSaga from "./feed/saga";

export default function* rootSaga() {
  yield all([fork(LoginSaga)]);
  yield all([fork(ContactSaga)]);
  yield all([fork(TagSaga)]);
  yield all([fork(SpaceSaga)]);
  yield all([fork(SessionSaga)]);
  yield all([fork(TrainingSaga)]);
  yield all([fork(FeedSaga)]);
}
