export const buildQueryString = (params: any): string => {
    if (!params) return "";
    // @ts-ignore
    return Object.entries(params)
        .filter(
            ([key, value]) => {
                if (Array.isArray(value)) return value.length > 0;
                return value !== undefined && value !== null && value !== "";
            },
        )
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                return `${key}=${encodeURIComponent(value.join(",") as string)}`;
            }
            return `${key}=${encodeURIComponent(value as string)}`;
        })
        .join("&");
};