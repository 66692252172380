export enum TRAINING_QUIZ_ACTION {
  FINISH = "FINISH",
  RETRY = "RETRY",
}

export enum STUDENTS_PAGE_STATE {
  LIST = "LIST",
  ASSIGN = "ASSIGN",
  UNASSIGN = "UNASSIGN",
}

export enum INTERRUMPTION_REASON {
  OTHER = "OTHER"
}
