import {
  CONTACT,
  CONTACT_SPACE_STUDENT,
  CONTACT_SPACE_STUDENT_SUCCESS,
  CONTACT_SUCCESS,
  CONTACT_ERROR,
  CONTACT_SPACE_STUDENT_ERROR,
  CONTACT_CLEAR_DATA,
  CONTACT_SPACE_STUDENT_CLEAR_DATA,
  CREATE_CONTACT_ERROR,
  CREATE_CONTACT,
  CREATE_CONTACT_CLEAR_DATA,
  CREATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_ERROR,
  UPDATE_CONTACT,
  UPDATE_CONTACT_CLEAR_DATA,
  UPDATE_CONTACT_SUCCESS,
  DISABLE_CONTACT,
  DISABLE_CONTACT_CLEAR_DATA,
  DISABLE_CONTACT_ERROR,
  DISABLE_CONTACT_SUCCESS,
  DELETE_CONTACT,
  DELETE_CONTACT_CLEAR_DATA,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_ERROR,
  CONTACT_STUDENT,
  CONTACT_STUDENT_CLEAR_DATA,
  CONTACT_STUDENT_ERROR,
  CONTACT_STUDENT_SUCCESS,
  CONTACT_STAFF,
  CONTACT_STAFF_CLEAR_DATA,
  CONTACT_STAFF_ERROR,
  CONTACT_STAFF_SUCCESS,
  CONTACT_SPACE_STAFF,
  CONTACT_SPACE_STAFF_CLEAR_DATA,
  CONTACT_SPACE_STAFF_ERROR,
  CONTACT_SPACE_STAFF_SUCCESS,
} from "./actionTypes";

export const contact = (contact, history) => {
  return {
    type: CONTACT,
    payload: { contact, history },
  };
};

export const contactStudent = (contact, history) => {
  return {
    type: CONTACT_STUDENT,
    payload: { contact, history },
  };
};

export const contactStudentSpace = (contact, history) => {
  return {
    type: CONTACT_SPACE_STUDENT,
    payload: { contact, history },
  };
};

export const contactStaffSpace = (contact, history) => {
  return {
    type: CONTACT_SPACE_STAFF,
    payload: { contact, history },
  };
};

export const contactStaff = (contact, history) => {
  return {
    type: CONTACT_STAFF,
    payload: { contact, history },
  };
};

export const createContact = (contact, history) => {
  return {
    type: CREATE_CONTACT,
    payload: { contact, history },
  };
};

export const updateContact = (contact, history) => {
  return {
    type: UPDATE_CONTACT,
    payload: { contact, history },
  };
};

export const disableContact = (contact, history) => {
  return {
    type: DISABLE_CONTACT,
    payload: { contact, history },
  };
};

export const deleteContact = (contact, history) => {
  return {
    type: DELETE_CONTACT,
    payload: { contact, history },
  };
};

export const contactSuccess = (success) => {
  return {
    type: CONTACT_SUCCESS,
    payload: success,
  };
};

export const contactStudentSuccess = (success) => {
  return {
    type: CONTACT_STUDENT_SUCCESS,
    payload: success,
  };
};

export const contactStaffSpaceSuccess = (success) => {
  return {
    type: CONTACT_SPACE_STAFF_SUCCESS,
    payload: success,
  };
};

export const contactStudentSpaceSuccess = (success) => {
  return {
    type: CONTACT_SPACE_STUDENT_SUCCESS,
    payload: success,
  };
};

export const contactStaffSuccess = (success) => {
  return {
    type: CONTACT_STAFF_SUCCESS,
    payload: success,
  };
};

export const createContactSuccess = (success) => {
  return {
    type: CREATE_CONTACT_SUCCESS,
    payload: success,
  };
};

export const updateContactSuccess = (success) => {
  return {
    type: UPDATE_CONTACT_SUCCESS,
    payload: success,
  };
};

export const disableContactSuccess = (success) => {
  return {
    type: DISABLE_CONTACT_SUCCESS,
    payload: success,
  };
};

export const deleteContactSuccess = (success) => {
  return {
    type: DELETE_CONTACT_SUCCESS,
    payload: success,
  };
};

export const contactError = (error) => {
  return {
    type: CONTACT_ERROR,
    payload: error,
  };
};

export const contactSpaceStudentError = (error) => {
  return {
    type: CONTACT_SPACE_STUDENT_ERROR,
    payload: error,
  };
};

export const contactSpaceStaffError = (error) => {
  return {
    type: CONTACT_SPACE_STAFF_ERROR,
    payload: error,
  };
};

export const contactStudentError = (error) => {
  return {
    type: CONTACT_STUDENT_ERROR,
    payload: error,
  };
};

export const contactStaffError = (error) => {
  return {
    type: CONTACT_STAFF_ERROR,
    payload: error,
  };
};

export const createContactError = (error) => {
  return {
    type: CREATE_CONTACT_ERROR,
    payload: error,
  };
};

export const updateContactError = (error) => {
  return {
    type: UPDATE_CONTACT_ERROR,
    payload: error,
  };
};

export const deleteContactError = (error) => {
  return {
    type: DELETE_CONTACT_ERROR,
    payload: error,
  };
};

export const disableContactError = (error) => {
  return {
    type: DISABLE_CONTACT_ERROR,
    payload: error,
  };
};

export const createContactClearData = () => {
  return {
    type: CREATE_CONTACT_CLEAR_DATA,
    payload: {},
  };
};

export const contactSpaceStudentClearData = () => {
  return {
    type: CONTACT_SPACE_STUDENT_CLEAR_DATA,
    payload: {},
  };
};

export const contactSpaceStaffClearData = () => {
  return {
    type: CONTACT_SPACE_STAFF_CLEAR_DATA,
    payload: {},
  };
};

export const contactClearData = () => {
  return {
    type: CONTACT_CLEAR_DATA,
    payload: {},
  };
};

export const contactStudentClearData = () => {
  return {
    type: CONTACT_STUDENT_CLEAR_DATA,
    payload: {},
  };
};

export const contactStaffClearData = () => {
  return {
    type: CONTACT_STAFF_CLEAR_DATA,
    payload: {},
  };
};

export const updateContactClearData = () => {
  return {
    type: UPDATE_CONTACT_CLEAR_DATA,
    payload: {},
  };
};

export const disableContactClearData = () => {
  return {
    type: DISABLE_CONTACT_CLEAR_DATA,
    payload: {},
  };
};

export const deleteContactClearData = () => {
  return {
    type: DELETE_CONTACT_CLEAR_DATA,
    payload: {},
  };
};
