import {
  TAG,
  TAG_SUCCESS,
  TAG_ERROR,
  TAG_CLEAR_DATA,
  TAG_SPACE_ERROR,
  TAG_SPACE,
  TAG_SPACE_CLEAR_DATA,
  TAG_SPACE_SUCCESS,
} from "./actionTypes";

export const tag = (tag, history) => {
  return {
    type: TAG,
    payload: { tag, history },
  };
};

export const tagSpace = (tag, history) => {
  return {
    type: TAG_SPACE,
    payload: { tag, history },
  };
};

export const tagSuccess = (success) => {
  return {
    type: TAG_SUCCESS,
    payload: success,
  };
};

export const tagSpaceSuccess = (success) => {
  return {
    type: TAG_SPACE_SUCCESS,
    payload: success,
  };
};

export const tagError = (error) => {
  return {
    type: TAG_ERROR,
    payload: error,
  };
};

export const tagSpaceError = (error) => {
  return {
    type: TAG_SPACE_ERROR,
    payload: error,
  };
};

export const tagClearData = () => {
  return {
    type: TAG_CLEAR_DATA,
    payload: {},
  };
};

export const tagSpaceClearData = () => {
  return {
    type: TAG_SPACE_CLEAR_DATA,
    payload: {},
  };
};
