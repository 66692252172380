import { useEffect, useState } from "react";
import { LearnerDetailView } from "./view";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  spaceFinishTrainingClearData,
  spaceOne,
  spaceOneClearData,
  spaceTrainingFinish,
} from "../../store/space/actions";
import LocalStorageService from "../../services/localStorage";
import {
  APP_ID,
  COMPLETED_TRAINING_STATUS,
  STORE_KEY,
} from "../../utils/constants";
import CircularProgress from "@mui/material/CircularProgress";
import { Space, SpaceResponse } from "../../model/space";
import { Session } from "../../model/session";
import {
  LearnerDetailItemProps,
  learnerItemType,
  quizActions,
  quizStatus,
} from "@edukcare/github-packages-edukcare-components/dist";
import { SESSION_STATUS, SESSION_TYPE } from "../../enum/session";
import showToast from "../../services/toast";
import {
  position,
  typeAlert,
} from "@edukcare/github-packages-edukcare-components/dist";
import { useTranslation } from "react-i18next";
import { SessionProgressRequest } from "../../model/session";
import { sessionFinish, sessionProgress } from "../../store/session/actions";
import { Question } from "../../model/question";
import { Answer } from "../../model/answer";
import { TrainingAttendRequest } from "../../model/training";
import {
  trainingAttend,
  trainingAttendClearData,
} from "../../store/training/actions";
import { delay } from "../../utils/delay";
import { TRAINING_QUIZ_ACTION } from "../../enum/training";
import { getSessionType } from "../../utils/sessions";

const LearnerDetailPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation("global");
  const { id } = params;
  const localStoreService = new LocalStorageService(STORE_KEY);
  const navigate = useNavigate();

  const {
    responseSessionProgress,
    errorSessionProgress,
    loadingSessionProgress,
  } = useSelector((state: any) => ({
    responseSessionProgress: state.Session.responseSessionProgress,
    errorSessionProgress: state.Session.errorSessionProgress,
    loadingSessionProgress: state.Session.loadingSessionProgress,
  }));

  const { responseSpaceOne, errorSpaceOne, loadingSpaceOne } = useSelector(
    (state: any) => ({
      responseSpaceOne: state.Space.responseSpaceOne,
      errorSpaceOne: state.Space.errorSpaceOne,
      loadingSpaceOne: state.Space.loadingSpaceOne,
    })
  );

  const { responseSpaceTrainingFinish } = useSelector((state: any) => ({
    responseSpaceTrainingFinish: state.Space.responseSpaceTrainingFinish,
  }));

  const { responseTrainingAttend, loadingTrainingAttend, errorTrainingAttend } =
    useSelector((state: any) => ({
      responseTrainingAttend: state.Training.responseTrainingAttend,
      loadingTrainingAttend: state.Training.loadingTrainingAttend,
      errorTrainingAttend: state.Training.errorTrainingAttend,
    }));

  const [dataForm, setDataForm] = useState({});
  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const [actualSession, setActualSession] = useState({});
  const [actualSessionInternal, setActualSessionInternal] = useState<any>({});
  const [listSession, setListSession] = useState<any[]>([]);
  const [stateForm, setStateForm] = useState<any>({
    status: null,
    score: 0,
    showFailedButton: false,
  });
  const [listOptions, setListOptions] = useState<Question[]>([]);
  const [indexQuestion, setIndexQuestion] = useState<number>(0);
  const [listAnswers, setListAnswers] = useState<Answer[]>([]);
  const [quizAction, setQuizAction] = useState<null | TRAINING_QUIZ_ACTION>(
    null
  );

  const labels = {
    successTitle: t("learnerDetail.quiz.successTitle"),
    successDescription: t("learnerDetail.quiz.successDescription"),
    successButton: t("learnerDetail.quiz.successButton"),
    failedTitle: t("learnerDetail.quiz.failedTitle"),
    failedDescription: t("learnerDetail.quiz.failedDescription"),
    failedButton: t("learnerDetail.quiz.failedButton"),
    limitTitle: t("learnerDetail.quiz.limitTitle"),
    limitDescription: t("learnerDetail.quiz.limitDescription"),
    limitButton: t("learnerDetail.quiz.limitButton"),
    label: t("learnerDetail.quiz.label"),
    accept: t("learnerDetail.quiz.accept"),
    cancel: t("learnerDetail.quiz.cancel"),
  };

  const modalLabels = {
    title: t("learnerDetail.modal.title"),
    description: t("learnerDetail.modal.description"),
  };

  const handleOnProgress = (item: LearnerDetailItemProps, progress: number) => {
    if (actualSessionInternal?.completed) return;
    const attending = progress >= 99 ? 100 : progress;
    const data = {
      id: item.id,
      body: {
        app_id: APP_ID,
        data: {
          attending,
          answering: 0,
          info: {
            videoSecs: `${(progress * +(item.duration ?? 0)) / 100}`,
            totalSecs: item.duration,
          },
        },
      } as SessionProgressRequest,
    };
    dispatch(sessionProgress({ ...data }));
    setActualSession(item);
    handleUpdateProgress(item, attending);
    setSeeToast({ ...seeToast, show: false });
  };

  const handleUpdateProgress = (
    item: LearnerDetailItemProps,
    progress: number
  ) => {
    const newList = listSession.map((session: LearnerDetailItemProps) => {
      if (item.id === session.id) {
        session.progress = progress;
        session.completed = progress >= 99;
      }
      return session;
    });
    setListSession(newList);
    handleAllFinishedSessions(newList, dataForm);
  };

  const handleFinishSession = () => {
    const data = {
      id: actualSessionInternal?.id,
    };
    setSeeToast({ ...seeToast, show: false });
    dispatch(sessionFinish({ ...data }));
    handleAllFinishedSessions(listSession, dataForm);
  };

  const handleOnClickSession = (item: LearnerDetailItemProps) => {
    setActualSessionInternal(item);
  };

  const onNextQuestion = (question: Question, listQuestions: Question[]) => {
    handleEndQuestion(listQuestions);
  };

  const onEndQuiz = (listQuestions: Question[]) => {
    setQuizAction(TRAINING_QUIZ_ACTION.FINISH);
    handleEndQuestion(listQuestions);
  };

  const handleEndQuestion = (listQuestions: Question[]) => {
    const data = responseSpaceOne?.data as Space;
    const retries = data.training?.quiz_response?.retries ?? 0;
    const questionWithAnswer = listQuestions
      .filter((x) => x.value)
      .map((x) => {
        const answer: Answer = { questionId: x.id };
        if (x.multipleChoice) answer.choiceId = x.value;
        else answer.singleResponse = x.value;
        return answer;
      });
    const newListAnswering = [...listAnswers, ...questionWithAnswer];
    const attending =
      listOptions.length === 0
        ? 0
        : (newListAnswering.length * 100) / listOptions.length;

    const dataRequest: TrainingAttendRequest = {
      app_id: APP_ID,
      data: {
        attending: attending,
        answering: attending,
        retries: retries,
        answers: newListAnswering,
      },
    };
    setSeeToast({ ...seeToast, show: false });
    dispatch(trainingAttend({ id: data?.id, body: dataRequest }));
  };

  const handleAllFinishedSessions = async (
    listSession: LearnerDetailItemProps[],
    dataForm: any
  ) => {
    const getPendings = listSession.filter(
      (item: LearnerDetailItemProps) => !item.completed
    );
    if (getPendings.length <= 0) {
      await handleQuizState();
      return;
    }
  };

  const restarForm = () => {
    setStateForm({ status: null, score: 0, showFailedButton: false });
    setActualSession({});
    setActualSessionInternal({});
    setDataForm({});
    setSeeToast({ ...seeToast, show: false });
    dispatch(spaceOneClearData());
    setListOptions([]);
    setIndexQuestion(0);
    setListAnswers([]);
    setQuizAction(null);
  };

  const handleOnActionQuiz = (action: quizActions, state: quizStatus) => {
    const data = responseSpaceOne?.data as Space;
    setSeeToast({ ...seeToast, show: false });
    if (
      action === quizActions.EXIT &&
      (state === quizStatus.SUCCESS_QUIZ || state === quizStatus.LIMIT_QUIZ)
    ) {
      if (
        !COMPLETED_TRAINING_STATUS.toLocaleLowerCase()
          .split(",")
          .includes(data?.training?.status?.toLocaleLowerCase() ?? "")
      ) {
        dispatch(spaceTrainingFinish({ id: data?.id }));
      } else {
        restarForm();
        navigate("/learner");
      }
    } else if (
      action === quizActions.RETRY &&
      state === quizStatus.FAILED_QUIZ
    ) {
      setQuizAction(TRAINING_QUIZ_ACTION.RETRY);
      const data = responseSpaceOne?.data as Space;
      const retries = (data.training?.quiz_response?.retries ?? 0) + 1;
      const dataRequest: TrainingAttendRequest = {
        app_id: APP_ID,
        data: {
          attending: 0,
          answering: 0,
          retries: retries,
          answers: [],
        },
      };
      dispatch(trainingAttend({ id: data?.id, body: dataRequest }));
    }
  };

  const handleQuizState = async () => {
    const data = responseSpaceOne?.data as Space;
    const training = data?.training;
    const quiz_response = training?.quiz_response;
    const quiz = training?.quiz;
    if (quiz_response) {
      if (quiz_response?.answering === 100) {
        const isWinner =
          (training?.min_score ?? 0) <= (training?.score_quiz ?? 0);
        if (
          !isWinner &&
          (quiz_response?.retries ?? 0) >= (training?.quiz?.retries ?? 0)
        ) {
          setStateForm({
            status: quizStatus.LIMIT_QUIZ,
            score: 0,
            showFailedButton: false,
          });
          return;
        }
        setStateForm({
          status: isWinner ? quizStatus.SUCCESS_QUIZ : quizStatus.FAILED_QUIZ,
          score: training?.score_quiz ?? 0,
          showFailedButton: !isWinner,
        });

        return;
      }
      setStateForm({
        status: quizStatus.QUIZ,
        score: 0,
        showFailedButton: false,
      });
      const newIndex = Math.floor(
        ((quiz_response?.answering ?? 0) / 100) * (quiz?.questions?.length ?? 0)
      );
      setIndexQuestion(newIndex);
      setListAnswers(quiz_response?.answers ?? []);
      await delay(0.2);
    }
    if (quiz && training?.require_quiz === "1") {
      setListOptions(
        quiz?.questions?.map((x: Question) => {
          return { ...x, choises: x.choices };
        }) ?? []
      );
      setStateForm({
        status: quizStatus.QUIZ,
        score: 0,
        showFailedButton: false,
      });
      return;
    }
    setStateForm({
      status: quizStatus.SUCCESS_QUIZ,
      score: 100,
    });
  };

  useEffect(() => {
    if (!id) return;
    setSeeToast({ ...seeToast, show: false });
    restarForm();
    const userInfo: any = localStoreService.getUserStored();
    let contactId = null;
    if (userInfo?.students && userInfo?.students?.length > 0)
      contactId = userInfo?.students[0]?.id;
    dispatch(spaceOne({ contactId, id }));
  }, [id]);

  useEffect(() => {
    if (responseSpaceOne?.data) {
      const info: any = responseSpaceOne.data;
      let getActualSession = info.training?.sessions?.find(
        (session: Session) =>
          session.status?.toLocaleLowerCase() ===
          SESSION_STATUS.IN_PROGRESS.toLocaleLowerCase()
      );
      if (
        !getActualSession &&
        info.training?.sessions &&
        info.training.sessions.length > 0
      )
        getActualSession = info.training.sessions[0];
      if (getActualSession) {
        let src = getActualSession.resources.href ?? "";
        const newSession = {
          id: getActualSession.id,
          type: getSessionType(getActualSession.type),
          name: getActualSession.title,
          duration: getActualSession.duration,
          src,
          completed: false,
          inProgress: true,
          progress: getActualSession.progressInfo?.attending ?? 0,
        };
        setActualSession(newSession);
        setActualSessionInternal(newSession);
      }
      const newList: LearnerDetailItemProps[] =
        info.training?.sessions?.map((session: any) => {
          return {
            id: session.id,
            type: getSessionType(session.type),
            name: session.title,
            duration: session.duration,
            src: session?.resources?.href,
            completed: session.progressInfo?.attending === 100,
            inProgress: false,
            progress: session.progressInfo?.attending ?? 0,
          } as LearnerDetailItemProps;
        }) ?? [];
      setListSession(newList);
      setDataForm({
        ...dataForm,
        title: info.title,
        description: info.description,
      });
      handleAllFinishedSessions(newList, info);
    }
  }, [responseSpaceOne]);

  useEffect(() => {
    if (errorSpaceOne) {
      setSeeToast({
        show: true,
        message: t("learnerDetail.getAllError"),
        type: typeAlert.error,
      });
      dispatch(spaceOneClearData());
    }
  }, [errorSpaceOne]);

  useEffect(() => {
    if (responseSessionProgress?.success) {
      if (actualSessionInternal?.progress >= 99) handleFinishSession();
    }
  }, [responseSessionProgress]);

  useEffect(() => {
    if (responseTrainingAttend?.success) {
      if (
        quizAction === TRAINING_QUIZ_ACTION.FINISH.toString() ||
        quizAction === TRAINING_QUIZ_ACTION.RETRY.toString()
      ) {
        dispatch(trainingAttendClearData());
        window.location.reload();
      }
    }
  }, [responseTrainingAttend]);

  useEffect(() => {
    if (responseSpaceTrainingFinish?.success) {
      restarForm();
      navigate("/learner");
      dispatch(spaceFinishTrainingClearData());
    }
  }, [responseSpaceTrainingFinish]);

  return (
    <>
      {!loadingSpaceOne && (
        <LearnerDetailView
          dataForm={dataForm}
          handleOnProgress={handleOnProgress}
          actualSession={actualSession}
          listSession={listSession}
          handleOnClickSession={handleOnClickSession}
          stateForm={stateForm}
          labels={labels}
          listOptions={listOptions}
          handleOnActionQuiz={handleOnActionQuiz}
          onNextQuestion={onNextQuestion}
          indexQuestion={indexQuestion}
          onEndQuiz={onEndQuiz}
          modalLabels={modalLabels}
        />
      )}
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message)}
      {loadingSpaceOne && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default LearnerDetailPage;
