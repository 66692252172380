import { call, put, takeEvery } from "redux-saga/effects";
import {
  feedContactError,
  feedContactReadError,
  feedContactReadSuccess,
  feedContactSuccess,
} from "./action";
import { FEED_CONTACT, FEED_CONTACT_READ } from "./actionTypes";
import { getFeedsByContact, readFeed } from "../../resources/feed/sagaHelper";

function* feedContact({ payload: { feed } }) {
  try {
    const response = yield call(getFeedsByContact, feed);
    yield put(feedContactSuccess(response));
  } catch (error) {
    yield put(feedContactError(error?.message));
  }
}

function* feedReadContact({ payload: { feed } }) {
  try {
    const response = yield call(readFeed, feed);
    yield put(feedContactReadSuccess(response));
  } catch (error) {
    yield put(feedContactReadError(error?.message));
  }
}

function* feedSaga() {
  yield takeEvery(FEED_CONTACT, feedContact);
  yield takeEvery(FEED_CONTACT_READ, feedReadContact);
}

export default feedSaga;
