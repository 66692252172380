import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    contact,
    contactClearData,
    createContact,
    createContactClearData,
    deleteContact,
    deleteContactClearData,
    disableContact,
    disableContactClearData,
    updateContact,
    updateContactClearData
} from "../../store/contact/actions";
import {ContactView} from "./view";
import {ContactData, ContactRequest, ContactUpdateRequest} from "../../model/contact";
import {useTranslation} from "react-i18next";
import {tag} from "../../store/tag/actions";
import {autocomplete} from "@edukcare/github-packages-edukcare-components/dist/src/dto/autocomplete";
import {
    D3IChip,
    D3IPersonalCard,
    formActions,
    Loading_,
    position,
    sizesTextField,
    typeAlert,
    typeLoaders,
    variantsAlert
} from "@edukcare/github-packages-edukcare-components/dist";
import showToast from "../../services/toast";
import {themeCore} from "../../assets/themes/theme";
import {formatDate} from "../../utils/formatDate";
import {ADDRESS_TYPE, APP_ID, PHONE_TYPE} from "../../utils/constants";
import Grid from "@mui/material/Grid";

const ContactTeach = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation("global");

    const [pagination, setPagination] = useState({
        page: 1,
        size: 10,
        total: 20,
    });
    const [rows, setRows] = useState([]);
    const [listTags, setListTags] = useState<autocomplete[]>([]);
    const [seeToast, setSeeToast] = React.useState<{
        show: boolean,
        type: typeAlert,
        message: string
    }>({
        show: false,
        message: "",
        type: typeAlert.success
    });
    const [showForm, setShowForm] = useState(false)
    const [filters, setFilters] = useState({
        search: '',
        tags: []
    })

    const DISABLE_CONTACT_REASONS = [
        {
            id: "ABUSE",
            value: "ABUSE",
            name: t("contact.disable.abuse")
        },
        {
            id: "UNPAID",
            value: "UNPAID",
            name: t("contact.disable.unpaid")
        },
        {
            id: "SIMPLE_LEAVE",
            value: "SIMPLE_LEAVE",
            name: t("contact.disable.leave")
        }
    ]

    const labels: any = {
        editLabel: t("contact.dialogLabel.editLabel"),
        disableLabel: t("contact.dialogLabel.disableLabel"),
        deleteLabel: t("contact.dialogLabel.deleteLabel"),
        resetLabel: t("contact.dialogLabel.resetLabel"),
        confirmDialog: t("contact.dialogLabel.confirmDialog"),
        cancelDialog: t("contact.dialogLabel.cancelDialog"),
        deleteTitle: t("contact.dialogLabel.deleteTitle"),
        deleteDescription: t("contact.dialogLabel.deleteDescription"),
        disableTitle: t("contact.dialogLabel.disableTitle"),
        resetTitle: t("contact.dialogLabel.resetTitle"),
        disableDescription: t("contact.dialogLabel.disableDescription"),
        disableError: t("contact.dialogLabel.disableError"),
        manualLabel: t("contact.dialogLabel.manualLabel"),
        generateLabel: t("contact.dialogLabel.generateLabel"),
    };

    const pageLabels: any = {
        title: t("contact.title"),
        pageDescription: t("contact.pageDescription"),
        pageTitle: t("contact.pageTitle"),
        searchLabel: t("contact.searchLabel"),
        labelActionButton: t("contact.labelActionButton"),
    };
    const form: any = {
        titleCreate: t("contact.form.titleCreate"),
        titleEdit: t("contact.form.titleEdit"),
        confirmLabel: t("contact.form.confirmLabel"),
        cancelLabel: t("contact.form.cancelLabel"),
        optionsDisable: DISABLE_CONTACT_REASONS,
        optionsTags: listTags,
        formLabels: {
            Name: t("contact.form.formLabels.Name"),
            NameHelper: t("contact.form.formLabels.NameHelper"),
            LastName: t("contact.form.formLabels.LastName"),
            LastNameHelper: t("contact.form.formLabels.LastNameHelper"),
            Email: t("contact.form.formLabels.Email"),
            EmailHelper: t("contact.form.formLabels.EmailHelper"),
            Type: t("contact.form.formLabels.Type"),
            TypeHelper: t("contact.form.formLabels.TypeHelper"),
            Password: t("contact.form.formLabels.Password"),
            PasswordHelper: t("contact.form.formLabels.PasswordHelper"),
            PasswordConfirmation: t("contact.form.formLabels.PasswordConfirmation"),
            PasswordConfirmHelper: t("contact.form.formLabels.PasswordConfirmHelper"),
            GenderHelp: t("contact.form.formLabels.GenderHelp"),
            Gender: t("contact.form.formLabels.Gender"),
            Birthday: t("contact.form.formLabels.Birthday"),
            BirthdayHelp: t("contact.form.formLabels.BirthdayHelp"),
            Phone: t("contact.form.formLabels.Phone"),
            PhoneHelper: t("contact.form.formLabels.PhoneHelper"),
            Address: t("contact.form.formLabels.Address"),
            AddressHelper: t("contact.form.formLabels.AddressHelper"),
            Tag: t("contact.form.formLabels.Tag"),
            TagHelperText: t("contact.form.formLabels.TagHelperText"),
        },
        formErrorLabels: {
            NameRequired: t("contact.form.formErrorLabels.NameRequired"),
            LastNameRequired: t("contact.form.formErrorLabels.LastNameRequired"),
            TypeRequired: t("contact.form.formErrorLabels.TypeRequired"),
            EmailRequired: t("contact.form.formErrorLabels.EmailRequired"),
            EmailInvalid: t("contact.form.formErrorLabels.EmailInvalid"),
            PasswordRequired: t("contact.form.formErrorLabels.PasswordRequired"),
            PasswordConfirmInvalid: t(
                "contact.form.formErrorLabels.PasswordConfirmInvalid"
            ),
            PasswordConfirmRequired: t(
                "contact.form.formErrorLabels.PasswordConfirmRequired"
            ),
        },
        contactOptions: [
            {
                id: "STUDENT",
                name: t("contact.student"),
            },
            {
                id: "STAFF",
                name: t("contact.staff"),
            },
        ],
        genderList: [
            {
                id: "FEMALE",
                name: t("contact.female"),
            },
            {
                id: "MALE",
                name: t("contact.male"),
            },
        ],
    };

    const columns = [
        {
            field: "AvatarImage",
            headerName: t("contact.table.user"),
            renderCell: (params: any) => (
                <D3IPersonalCard
                    avatarImage={params.row.AvatarImage}
                    id={params.row.Id}
                    subtitle={params.row.Email}
                    title={`${params.row.Name} ${params.row.LastName}`}
                    avatarText={`${params.row.Name} ${params.row.LastName}`}
                />
            ),
            width: 250,
        },
        {
            field: "Type",
            headerName: t("contact.table.type"),
            width: 250,
            renderCell: (params: any) => (
                <span>{form.contactOptions.find((contact: any) => contact.id === params.row.Type)?.name ?? ""}</span>
            )
        },
        {
            field: "Estado",
            headerName: t("contact.table.status"),
            width: 250,
            renderCell: (params: any) => (
                <span>{params.row.disabled === true ? t("contact.table.disable") : t("contact.table.active")}</span>
            )
        },
        {
            field: "Birthday",
            headerName: t("contact.table.birthday"),
            width: 250,
        },
        {
            field: "tags",
            headerName: t("contact.table.tags"),
            width: 350,
            renderCell: (params: any) => {
                return (
                    <Grid xs={12} item container style={{maxHeight: "100%", overflowY: "auto"}}>
                        {params.row.tags?.map((tag: any) => (
                                <Grid item style={{margin: 5}}>
                                    <D3IChip
                                        id={tag.id}
                                        label={tag.name}
                                        variant={variantsAlert.filled}
                                        size={sizesTextField.medium}
                                    />
                                </Grid>
                            )
                        )}
                    </Grid>
                )
            }
        },

    ];


    const {responseUpdateContact, errorUpdateContact, loadingUpdateContact} = useSelector(
        (state: any) => ({
            responseUpdateContact: state.Contact.responseUpdateContact,
            errorUpdateContact: state.Contact.errorUpdateContact,
            loadingUpdateContact: state.Contact.loadingUpdateContact,
        })
    );

    const {responseContact, errorContact, loadingContact} = useSelector(
        (state: any) => ({
            responseContact: state.Contact.responseContact,
            errorContact: state.Contact.errorContact,
            loadingContact: state.Contact.loadingContact,
        })
    );

    const {responseCreateContact, errorCreateContact, loadingCreateContact} = useSelector(
        (state: any) => ({
            responseCreateContact: state.Contact.responseCreateContact,
            errorCreateContact: state.Contact.errorCreateContact,
            loadingCreateContact: state.Contact.loadingCreateContact,
        })
    );

    const {responseDisableContact, errorDisableContact, loadingDisableContact} = useSelector(
        (state: any) => ({
            responseDisableContact: state.Contact.responseDisableContact,
            errorDisableContact: state.Contact.errorDisableContact,
            loadingDisableContact: state.Contact.loadingDisableContact,
        })
    );

    const {responseDeleteContact, errorDeleteContact, loadingDeleteContact} = useSelector(
        (state: any) => ({
            responseDeleteContact: state.Contact.responseDeleteContact,
            errorDeleteContact: state.Contact.errorDeleteContact,
            loadingDeleteContact: state.Contact.loadingDeleteContact,
        })
    );

    const {responseTag, errorTag, loadingTag} = useSelector((state: any) => ({
        responseTag: state.Tag.responseTag,
        errorTag: state.Tag.errorTag,
        loadingTag: state.Tag.loadingTag,
    }));

    const table: any = {
        isLoading: loadingContact,
        pageSizeOptions: [10, 20, 100]
    }
    const handleSubmit = (action: any, info: any) => {
        const {data, selectedTags, tags} = info
        setShowForm(true)
        setSeeToast({show: false, message: "Error al crear contacto", type: typeAlert.error})
        if (action === "edit") {
            const newTags = tags.filter((x: any) => x.newValue).map((x: any) => x.name)
            let existingTags = tags.filter((x: any) => !x.newValue).map((x: any) => x.id)
            existingTags = [...existingTags, ...selectedTags.map((x: any) => x.id)]
            let submitData: ContactUpdateRequest = {
                app_id: APP_ID,
                data: {
                    names: data.Name,
                    last_names: data.LastName,
                    type: data.Type,
                    gender: data.Gender ?? undefined,
                    email: data.Email,
                    birth_date: data.Birthday ? formatDate(data.Birthday) : undefined,
                    new_tags: newTags,
                    existing_tags: existingTags,
                    addresses: [{type: ADDRESS_TYPE, description: data.Address}],
                    phones: [{type: PHONE_TYPE, number: data.Phone}]
                }
            }
            dispatch(updateContact({id: data.Id, body: submitData}))
            return;
        }
        const submitData: ContactRequest = {
            app_id: "app",
            data: {
                email: data.Email,
                last_names: data.LastName,
                names: data.Name,
                password: data.Password,
                password_confirm: data.PasswordConfirmation,
                type: data.Type,

            },
        };
        dispatch(createContact(submitData));
    };

    const handleClickConfirmation = (action: string, data: any, reason?: any) => {
        setSeeToast({show: false, message: "Error al crear contacto", type: typeAlert.error})
        if (action === formActions.Disable.toString()) {
            const submit = {
                app_id: APP_ID,
                data: {
                    reason_type: reason.Reason
                }
            }
            dispatch(disableContact({id: data.Id, body: submit}))
        } else if (action === formActions.Reset.toString()) {
            const submitData: ContactUpdateRequest = {
                app_id: APP_ID,
                data: {
                    names: data.Name,
                    last_names: data.LastName,
                    type: data.Type,
                    gender: data.Gender ?? undefined,
                    email: data.Email,
                    birth_date: data.Birthday ? formatDate(data.Birthday) : undefined,
                    password: reason?.Password,
                    password_confirm: reason?.PasswordConfirmation,
                    addresses: [{type: ADDRESS_TYPE, description: data.Address}],
                    phones: [{type: PHONE_TYPE, number: data.Phone}]
                }
            }
            dispatch(updateContact({id: data.Id, body: submitData}))
        } else if (action === formActions.Delete.toString()) {
            dispatch(deleteContact({id: data.Id}))
        }
    }

    const handleGetContacts = (pagination: any, filter?: any) => {
        let params = {
            size: pagination.size,
            page: pagination.page,
            tags: []
        }
        if (filter) {
            params = {
                ...params,
                tags: filter?.tags,
            }
        }
        dispatch(contact(params));
    }

    const handlePaginationChange = (info: any) => {
        const newPagination = {
            ...pagination,
            page: info.page + 1,
            size: info.pageSize
        }
        setPagination(newPagination)
        handleGetContacts(newPagination)
    }

    const handleApplyFilter = (data: any) => {
        setFilters({...filters, tags: data.tag?.map((x: any) => x.id)})
    }

    const handleOnTerm = (data: any) => {
    }

    useEffect(() => {
        handleGetContacts(pagination)
        dispatch(tag({}));
    }, []);

    useEffect(() => {
        if (responseContact?.data) {
            if (responseContact.paging) {
                setPagination({
                    ...pagination,
                    total: responseContact?.paging?.total,
                    page: responseContact?.paging?.page,
                    size: responseContact?.paging?.size,
                })
            }
            const data = responseContact.data.map((contact: ContactData) => {
                return {
                    AvatarImage: contact.avatar.href,
                    Gender: contact.gender,
                    Birthday: contact.birth_date,
                    Id: contact.id,
                    Name: contact.names,
                    LastName: contact.last_names,
                    Type: contact.contact_type,
                    Email: contact.email,
                    tags: contact.tags,
                    disabled: contact.disabled,
                    Phone: contact.phones.length > 0 ? contact.phones[0].number : "",
                    Address: contact.addresses.length > 0 ? contact.addresses[0].description : ""
                };
            });
            setRows(data);
        }
    }, [responseContact]);

    useEffect(() => {
        if (responseTag?.data) {
            setListTags(responseTag.data);
        }
    }, [responseTag]);

    useEffect(() => {
        if (errorContact) {
            setSeeToast({show: true, message: t("contact.alerts.errorContact"), type: typeAlert.error})
            dispatch(contactClearData())
        }
    }, [errorContact]);

    useEffect(() => {
        if (errorCreateContact) {
            setSeeToast({show: true, message: t("contact.alerts.errorCreateContact"), type: typeAlert.error})
            dispatch(createContactClearData())
        }
    }, [errorCreateContact]);

    useEffect(() => {
        if (errorUpdateContact) {
            setSeeToast({show: true, message: t("contact.alerts.errorUpdateContact"), type: typeAlert.error})
            dispatch(createContactClearData())
        }
    }, [errorUpdateContact]);

    useEffect(() => {
        if (errorDisableContact) {
            setSeeToast({show: true, message: t("contact.alerts.errorDisabledContact"), type: typeAlert.error})
            dispatch(disableContactClearData())
        }
    }, [errorDisableContact]);

    useEffect(() => {
        if (errorDeleteContact) {
            setSeeToast({show: true, message: t("contact.alerts.errorDeleteContact"), type: typeAlert.error})
            dispatch(deleteContactClearData())
        }
    }, [errorDeleteContact]);

    useEffect(() => {
        if (responseCreateContact?.data) {
            setSeeToast({show: true, message: t("contact.alerts.successCreateContact"), type: typeAlert.success})
            dispatch(createContactClearData())
            setShowForm(false)
            handleGetContacts(pagination)
        }
    }, [responseCreateContact]);

    useEffect(() => {
        if (responseUpdateContact?.success) {
            setSeeToast({show: true, message: t("contact.alerts.successUpdateContact"), type: typeAlert.success})
            dispatch(updateContactClearData())
            setShowForm(false)
            handleGetContacts(pagination)
        }
    }, [responseUpdateContact]);

    useEffect(() => {
        if (responseDisableContact?.success) {
            setSeeToast({show: true, message: t("contact.alerts.successDisabledContact"), type: typeAlert.success})
            dispatch(disableContactClearData())
        }
    }, [responseDisableContact]);

    useEffect(() => {
        if (responseDeleteContact?.success) {
            setSeeToast({show: true, message: t("contact.alerts.successDeleteContact"), type: typeAlert.success})
            dispatch(deleteContactClearData())
            handleGetContacts(pagination)
        }
    }, [responseDeleteContact]);

    useEffect(() => {
        if (filters) {
            handleGetContacts(pagination, filters)
        }
    }, [filters]);

    return (
        <>
            <ContactView
                showForm={showForm}
                columns={columns}
                rows={rows}
                labels={labels}
                pageLabels={pageLabels}
                form={form}
                tags={listTags}
                handleSubmit={handleSubmit}
                handleClickConfirmation={handleClickConfirmation}
                table={table}
                onPaginationChange={handlePaginationChange}
                pagination={pagination}
                onApplyFilter={handleApplyFilter}
            />
            {
                seeToast.show && (showToast(position.topRight, seeToast.type, seeToast.message))
            }
            <Loading_
                loading={loadingCreateContact || loadingUpdateContact || loadingDisableContact || loadingDeleteContact}
                type={typeLoaders.BarLoader}
                color={themeCore.colors.third}
                textColor={themeCore.colors.third}
                text=""
                bgColor={themeCore.colors.backgroundPrimaryTransparent}
            />
        </>
    );
};

export default ContactTeach;
