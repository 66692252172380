import {
  position,
  typeAlert,
} from "@edukcare/github-packages-edukcare-components/dist";
import showToast from "../services/toast";
import LocalStorageService from "../services/localStorage";
import { STORE_KEY } from "../utils/constants";

const localStoreService = new LocalStorageService(STORE_KEY);

const fetchRequest = async (url: string, requestOptions: any) => {
  const response = await fetch(url, requestOptions);
  if (!response.ok && response.status === 403) {
    localStoreService.logOut();
    window.location.reload();
    return;
  } else if (!response.ok) {
    showToast(
      position.topRight,
      typeAlert.error,
      `HTTP error! Status: ${response.status}`
    );
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  return response.json();
};

export { fetchRequest };
