import {
  FEED_CONTACT,
  FEED_CONTACT_CLEAR_DATA,
  FEED_CONTACT_ERROR,
  FEED_CONTACT_SUCCESS,
  FEED_CONTACT_READ,
  FEED_CONTACT_READ_CLEAR_DATA,
  FEED_CONTACT_READ_ERROR,
  FEED_CONTACT_READ_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorFeedContact: null,
  responseFeedContact: null,
  loadingFeedContact: false,
  errorFeedContactRead: null,
  responseFeedContactRead: null,
  loadingFeedContactRead: false,
};

const Feed = (state = initialState, action) => {
  switch (action.type) {
    case FEED_CONTACT:
      state = {
        ...state,
        loadingFeedContact: true,
        errorFeedContact: null,
        responseFeedContact: true,
      };
      break;
    case FEED_CONTACT_READ:
      state = {
        ...state,
        loadingFeedContactRead: true,
        errorFeedContactRead: null,
        responseFeedContactRead: true,
      };
      break;
    case FEED_CONTACT_SUCCESS:
      state = {
        ...state,
        loadingFeedContact: false,
        errorFeedContact: null,
        responseFeedContact: action.payload,
      };
      break;
    case FEED_CONTACT_READ_SUCCESS:
      state = {
        ...state,
        loadingFeedContactRead: false,
        errorFeedContactRead: null,
        responseFeedContactRead: action.payload,
      };
      break;
    case FEED_CONTACT_ERROR:
      state = {
        ...state,
        loadingFeedContact: false,
        errorFeedContact: action.payload,
        responseFeedContact: null,
      };
      break;
    case FEED_CONTACT_READ_ERROR:
      state = {
        ...state,
        loadingFeedContactRead: false,
        errorFeedContactRead: action.payload,
        responseFeedContactRead: null,
      };
      break;
    case FEED_CONTACT_CLEAR_DATA:
      state = {
        ...state,
        loadingFeedContact: false,
        errorFeedContact: null,
        responseFeedContact: null,
      };
      break;
    case FEED_CONTACT_READ_CLEAR_DATA:
      state = {
        ...state,
        loadingFeedContactRead: false,
        errorFeedContactRead: null,
        responseFeedContactRead: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Feed;
