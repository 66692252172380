import {fetchRequest} from '../fetchRequest';
import {LoginRequest, LoginResponse} from "../../model/login";
import {URLs} from "../url";
import GetUrl from "../../services/config";

const postLogin = async (body: LoginRequest) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Accept': 'application/json, text/plain, */*',},
        body: JSON.stringify(body),
    };

    try {
        return (await fetchRequest(GetUrl(URLs.login), requestOptions)) as LoginResponse;
    } catch (error: any) {
        throw error;
    }
};

export {postLogin};
