import { LearnerList } from "@edukcare/github-packages-edukcare-components/dist";
import { WrapperContent } from "../../components/layout/style";

export const LearnerView = (props: any) => {
  return (
    <WrapperContent>
      <LearnerList
        title={props.labels.title}
        pageTitle={props.labels.title}
        labels={props.labels.labels}
        data={props.data}
        onClickCard={props.handleClickCard}
        isLoading={props.isLoading}
        onChangeOption={props.handleOnChangeOption}
        onRefresh={props.handleOnRefresh}
      />
    </WrapperContent>
  );
};
