import { call, put, takeEvery } from "redux-saga/effects";
import {
  SESSION_PROGRESS,
  SESSION_FINISH,
  SESSION_CREATE,
  SESSION_RESOURCE_ADD,
  SESSION_ONE,
  SESSION_UPDATE,
} from "./actionTypes";
import {
  sessionProgressSuccess,
  sessionProgressError,
  sessionFinishSuccess,
  sessionFinishError,
  sessionCreateSuccess,
  sessionCreateError,
  sessionResourceAddSuccess,
  sessionResourceAddError,
  sessionOneSuccess,
  sessionOneError,
  sessionUpdateSuccess,
  sessionUpdateError,
} from "./actions";

import {
  updateSessionProgress,
  updateSessionFinish,
  getOne,
} from "../../resources/session/sagaHelper";

import {
  createNewSession,
  updateActualSession,
  uploadResourceSession,
} from "../../resources/space/sagaHelper";

function* sessionProgress({ payload: { session } }) {
  try {
    const response = yield call(updateSessionProgress, session);
    yield put(sessionProgressSuccess(response));
  } catch (error) {
    yield put(sessionProgressError(error?.message));
  }
}

function* sessionFinish({ payload: { session } }) {
  try {
    const response = yield call(updateSessionFinish, session);
    yield put(sessionFinishSuccess(response));
  } catch (error) {
    yield put(sessionFinishError(error?.message));
  }
}

function* sessionCreate({ payload: { session } }) {
  try {
    const response = yield call(createNewSession, session);
    yield put(sessionCreateSuccess(response));
  } catch (error) {
    yield put(sessionCreateError(error?.message));
  }
}

function* sessionResourceAdd({ payload: { session } }) {
  try {
    const response = yield call(uploadResourceSession, session);
    yield put(sessionResourceAddSuccess(response));
  } catch (error) {
    yield put(sessionResourceAddError(error?.message));
  }
}

function* sessionOne({ payload: { session } }) {
  try {
    const response = yield call(getOne, session);
    yield put(sessionOneSuccess(response));
  } catch (error) {
    yield put(sessionOneError(error?.message));
  }
}

function* sessionUpdate({ payload: { session } }) {
  try {
    const response = yield call(updateActualSession, session);
    yield put(sessionUpdateSuccess(response));
  } catch (error) {
    yield put(sessionUpdateError(error?.message));
  }
}

function* sessionSaga() {
  yield takeEvery(SESSION_PROGRESS, sessionProgress);
  yield takeEvery(SESSION_FINISH, sessionFinish);
  yield takeEvery(SESSION_CREATE, sessionCreate);
  yield takeEvery(SESSION_RESOURCE_ADD, sessionResourceAdd);
  yield takeEvery(SESSION_ONE, sessionOne);
  yield takeEvery(SESSION_UPDATE, sessionUpdate);
}

export default sessionSaga;
