import {
  SESSION_PROGRESS,
  SESSION_PROGRESS_CLEAR_DATA,
  SESSION_PROGRESS_ERROR,
  SESSION_PROGRESS_SUCCESS,
  SESSION_FINISH,
  SESSION_FINISH_CLEAR_DATA,
  SESSION_FINISH_ERROR,
  SESSION_FINISH_SUCCESS,
  SESSION_CREATE,
  SESSION_CREATE_CLEAR_DATA,
  SESSION_CREATE_ERROR,
  SESSION_CREATE_SUCCESS,
  SESSION_RESOURCE_ADD,
  SESSION_RESOURCE_ADD_CLEAR_DATA,
  SESSION_RESOURCE_ADD_ERROR,
  SESSION_RESOURCE_ADD_SUCCESS,
  SESSION_ONE,
  SESSION_ONE_CLEAR_DATA,
  SESSION_ONE_ERROR,
  SESSION_ONE_SUCCESS,
  SESSION_UPDATE,
  SESSION_UPDATE_CLEAR_DATA,
  SESSION_UPDATE_ERROR,
  SESSION_UPDATE_SUCCESS,
} from "./actionTypes";

export const sessionProgress = (session, history) => {
  return {
    type: SESSION_PROGRESS,
    payload: { session, history },
  };
};

export const sessionCreate = (session, history) => {
  return {
    type: SESSION_CREATE,
    payload: { session, history },
  };
};

export const sessionResourceAdd = (session, history) => {
  return {
    type: SESSION_RESOURCE_ADD,
    payload: { session, history },
  };
};

export const sessionFinish = (session, history) => {
  return {
    type: SESSION_FINISH,
    payload: { session, history },
  };
};

export const sessionOne = (session, history) => {
  return {
    type: SESSION_ONE,
    payload: { session, history },
  };
};

export const sessionUpdate = (session, history) => {
  return {
    type: SESSION_UPDATE,
    payload: { session, history },
  };
};

export const sessionProgressSuccess = (success) => {
  return {
    type: SESSION_PROGRESS_SUCCESS,
    payload: success,
  };
};

export const sessionResourceAddSuccess = (success) => {
  return {
    type: SESSION_RESOURCE_ADD_SUCCESS,
    payload: success,
  };
};

export const sessionCreateSuccess = (success) => {
  return {
    type: SESSION_CREATE_SUCCESS,
    payload: success,
  };
};

export const sessionFinishSuccess = (success) => {
  return {
    type: SESSION_FINISH_SUCCESS,
    payload: success,
  };
};

export const sessionOneSuccess = (success) => {
  return {
    type: SESSION_ONE_SUCCESS,
    payload: success,
  };
};

export const sessionUpdateSuccess = (success) => {
  return {
    type: SESSION_UPDATE_SUCCESS,
    payload: success,
  };
};

export const sessionProgressError = (error) => {
  return {
    type: SESSION_PROGRESS_ERROR,
    payload: error,
  };
};

export const sessionResourceAddError = (error) => {
  return {
    type: SESSION_RESOURCE_ADD_ERROR,
    payload: error,
  };
};

export const sessionCreateError = (error) => {
  return {
    type: SESSION_CREATE_ERROR,
    payload: error,
  };
};

export const sessionFinishError = (error) => {
  return {
    type: SESSION_FINISH_ERROR,
    payload: error,
  };
};

export const sessionOneError = (error) => {
  return {
    type: SESSION_ONE_ERROR,
    payload: error,
  };
};

export const sessionUpdateError = (error) => {
  return {
    type: SESSION_UPDATE_ERROR,
    payload: error,
  };
};

export const sessionProgressClearData = () => {
  return {
    type: SESSION_PROGRESS_CLEAR_DATA,
    payload: {},
  };
};

export const sessionFinishClearData = () => {
  return {
    type: SESSION_FINISH_CLEAR_DATA,
    payload: {},
  };
};

export const sessionCreateClearData = () => {
  return {
    type: SESSION_CREATE_CLEAR_DATA,
    payload: {},
  };
};

export const sessionResourceAddClearData = () => {
  return {
    type: SESSION_RESOURCE_ADD_CLEAR_DATA,
    payload: {},
  };
};

export const sessionOneClearData = () => {
  return {
    type: SESSION_ONE_CLEAR_DATA,
    payload: {},
  };
};

export const sessionUpdateClearData = () => {
  return {
    type: SESSION_UPDATE_CLEAR_DATA,
    payload: {},
  };
};
