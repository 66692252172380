import { LoginData, LoginResponse } from "../model/login";
import {
  MenuItem,
  MenuOptionsList,
} from "@edukcare/github-packages-edukcare-components/dist";

class LocalStorageService {
  private storageKey: string;
  private _ls: LoginData;
  private _mh: MenuItem[];
  private _mhSide: MenuOptionsList[];

  constructor(storageKey: string) {
    this.storageKey = storageKey;
    this._ls = { token: "" };
    this._mh = [];
    this._mhSide = [];
  }

  public getUserStored(): LoginData {
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData) {
      this._ls = JSON.parse(storedData);
      return this._ls;
    }
    return this._ls;
  }

  public setUserStored(user: LoginResponse): void {
    localStorage.setItem(this.storageKey, JSON.stringify(user));
  }

  public logOut(): void{
    localStorage.clear();
  }

  public isAuthenticated(): boolean {
    return !!this.getUserStored().token;
  }

  /*Set Menu Options*/
  public setMenuHorizontalStored(menuHorizontal: MenuItem[]): void {
    localStorage.setItem(this.storageKey, JSON.stringify(menuHorizontal));
  }

  public getMenuHorizontalStored(): MenuItem[] {
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData) {
      this._mh = JSON.parse(storedData);
      return this._mh;
    }
    return this._mh;
  }
  /*End*/

  /*Set Menu SideBar*/
  public setMenuSideBarStored(menuSideBar: MenuOptionsList[]): void {
    localStorage.setItem(this.storageKey, JSON.stringify(menuSideBar));
  }

  public getMenuSideBarStored(): MenuOptionsList[] {
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData) {
      this._mhSide = JSON.parse(storedData);
      return this._mhSide;
    }
    return this._mhSide;
  }
  /*End*/
}

export default LocalStorageService;
