import {
  SESSION_PROGRESS,
  SESSION_PROGRESS_CLEAR_DATA,
  SESSION_PROGRESS_ERROR,
  SESSION_PROGRESS_SUCCESS,
  SESSION_FINISH,
  SESSION_FINISH_CLEAR_DATA,
  SESSION_FINISH_ERROR,
  SESSION_FINISH_SUCCESS,
  SESSION_CREATE,
  SESSION_CREATE_CLEAR_DATA,
  SESSION_CREATE_ERROR,
  SESSION_CREATE_SUCCESS,
  SESSION_RESOURCE_ADD,
  SESSION_RESOURCE_ADD_CLEAR_DATA,
  SESSION_RESOURCE_ADD_ERROR,
  SESSION_RESOURCE_ADD_SUCCESS,
  SESSION_ONE,
  SESSION_ONE_CLEAR_DATA,
  SESSION_ONE_ERROR,
  SESSION_ONE_SUCCESS,
  SESSION_UPDATE,
  SESSION_UPDATE_CLEAR_DATA,
  SESSION_UPDATE_ERROR,
  SESSION_UPDATE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorSession: null,
  responseSession: null,
  loadingSession: false,
  errorSessionProgress: null,
  responseSessionProgress: null,
  loadingSessionProgress: null,
  errorSessionFinish: null,
  responseSessionFinish: null,
  loadingSessionFinish: null,
  errorSessionCreate: null,
  responseSessionCreate: null,
  loadingSessionCreate: false,
  errorSessionResourceAdd: null,
  responseSessionResourceAdd: null,
  loadingSessionResourceAdd: false,
  errorSessionOne: null,
  responseSessionOne: null,
  loadingSessionOne: false,
  errorSessionUpdate: null,
  responseSessionUpdate: null,
  loadingSessionUpdate: false,
};

const Session = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_PROGRESS:
      state = {
        ...state,
        loadingSessionProgress: true,
        errorSessionProgress: null,
        responseSessionProgress: true,
      };
      break;
    case SESSION_CREATE:
      state = {
        ...state,
        loadingSessionCreate: true,
        errorSessionCreate: null,
        responseSessionCreate: true,
      };
      break;
    case SESSION_FINISH:
      state = {
        ...state,
        loadingSessionFinish: true,
        errorSessionFinish: null,
        responseSessionFinish: true,
      };
      break;
    case SESSION_RESOURCE_ADD:
      state = {
        ...state,
        loadingSessionResourceAdd: true,
        errorSessionResourceAdd: null,
        responseSessionResourceAdd: true,
      };
      break;
    case SESSION_ONE:
      state = {
        ...state,
        loadingSessionOne: true,
        errorSessionOne: null,
        responseSessionOne: true,
      };
      break;
    case SESSION_UPDATE:
      state = {
        ...state,
        loadingSessionUpdate: true,
        errorSessionUpdate: null,
        responseSessionUpdate: true,
      };
      break;
    case SESSION_PROGRESS_SUCCESS:
      state = {
        ...state,
        errorSessionProgress: null,
        loadingSessionProgress: false,
        responseSessionProgress: action.payload,
      };
      break;
    case SESSION_CREATE_SUCCESS:
      state = {
        ...state,
        errorSessionCreate: null,
        loadingSessionCreate: false,
        responseSessionCreate: action.payload,
      };
      break;
    case SESSION_FINISH_SUCCESS:
      state = {
        ...state,
        errorSessionFinish: null,
        loadingSessionFinish: false,
        responseSessionFinish: action.payload,
      };
      break;
    case SESSION_RESOURCE_ADD_SUCCESS:
      state = {
        ...state,
        errorSessionResourceAdd: null,
        loadingSessionResourceAdd: false,
        responseSessionResourceAdd: action.payload,
      };
      break;
    case SESSION_ONE_SUCCESS:
      state = {
        ...state,
        errorSessionOne: null,
        loadingSessionOne: false,
        responseSessionOne: action.payload,
      };
      break;
    case SESSION_UPDATE_SUCCESS:
      state = {
        ...state,
        errorSessionUpdate: null,
        loadingSessionUpdate: false,
        responseSessionUpdate: action.payload,
      };
      break;
    case SESSION_PROGRESS_ERROR:
      state = {
        ...state,
        errorSessionProgress: action.payload,
        loadingSessionProgress: false,
        responseSessionProgress: null,
      };
      break;
    case SESSION_CREATE_ERROR:
      state = {
        ...state,
        errorSessionCreate: action.payload,
        loadingSessionCreate: false,
        responseSessionCreate: null,
      };
      break;
    case SESSION_FINISH_ERROR:
      state = {
        ...state,
        errorSessionFinish: action.payload,
        loadingSessionFinish: false,
        responseSessionFinish: null,
      };
      break;
    case SESSION_RESOURCE_ADD_ERROR:
      state = {
        ...state,
        errorSessionResourceAdd: action.payload,
        loadingSessionResourceAdd: false,
        responseSessionResourceAdd: null,
      };
      break;
    case SESSION_ONE_ERROR:
      state = {
        ...state,
        errorSessionOne: action.payload,
        loadingSessionOne: false,
        responseSessionOne: null,
      };
      break;
    case SESSION_UPDATE_ERROR:
      state = {
        ...state,
        errorSessionUpdate: action.payload,
        loadingSessionUpdate: false,
        responseSessionUpdate: null,
      };
      break;
    case SESSION_PROGRESS_CLEAR_DATA:
      state = {
        ...state,
        errorSessionProgress: null,
        loadingSessionProgress: false,
        responseSessionProgress: null,
      };
      break;
    case SESSION_FINISH_CLEAR_DATA:
      state = {
        ...state,
        errorSessionFinish: null,
        loadingSessionFinish: false,
        responseSessionFinish: null,
      };
      break;
    case SESSION_CREATE_CLEAR_DATA:
      state = {
        ...state,
        errorSessionCreate: null,
        loadingSessionCreate: false,
        responseSessionCreate: null,
      };
      break;
    case SESSION_RESOURCE_ADD_CLEAR_DATA:
      state = {
        ...state,
        errorSessionResourceAdd: null,
        loadingSessionResourceAdd: false,
        responseSessionResourceAdd: null,
      };
      break;
    case SESSION_ONE_CLEAR_DATA:
      state = {
        ...state,
        errorSessionOne: null,
        loadingSessionOne: false,
        responseSessionOne: null,
      };
      break;
    case SESSION_UPDATE_CLEAR_DATA:
      state = {
        ...state,
        errorSessionUpdate: null,
        loadingSessionUpdate: false,
        responseSessionUpdate: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Session;
