import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    paddingLeft: "15px",
  },
  bodyContainer: {
    height: "88vh",
  },
}));
