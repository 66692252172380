import { call, put, takeEvery } from "redux-saga/effects";
import { TAG, TAG_SPACE } from "./actionTypes";
import {
  tagSuccess,
  tagError,
  tagSpaceSuccess,
  tagSpaceError,
} from "./actions";

import { getAll, getAllSpace } from "../../resources/tag/sagaHelper";

function* tag({ payload: { tag } }) {
  try {
    const response = yield call(getAll, tag);
    yield put(tagSuccess(response));
  } catch (error) {
    yield put(tagError(error?.message));
  }
}

function* tagSpace({ payload: { tag } }) {
  try {
    const response = yield call(getAllSpace, tag);
    yield put(tagSpaceSuccess(response));
  } catch (error) {
    yield put(tagSpaceError(error?.message));
  }
}

function* tagSaga() {
  yield takeEvery(TAG, tag);
  yield takeEvery(TAG_SPACE, tagSpace);
}

export default tagSaga;
