import LocalStorageService from "../../services/localStorage";
import { STORE_KEY } from "../../utils/constants";
import GetUrl from "../../services/config";
import { URLs } from "../url";
import { buildQueryString } from "../../utils/queryParams";
import { fetchRequest } from "../fetchRequest";
import { TagResponse } from "../../model/tag";

const localStorageService = new LocalStorageService(STORE_KEY);
const getAll = async (params: any) => {
  const url = `${GetUrl(URLs.tags)}/Contacts?${buildQueryString(params)}`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
    },
  };
  try {
    return (await fetchRequest(url, requestOptions)) as TagResponse;
  } catch (errors: any) {
    throw errors;
  }
};

const getAllSpace = async (params: any) => {
  const url = `${GetUrl(URLs.tags)}?${buildQueryString(params)}`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
    },
  };
  try {
    return (await fetchRequest(url, requestOptions)) as TagResponse;
  } catch (errors: any) {
    throw errors;
  }
};

export { getAll, getAllSpace };
