import { call, put, takeEvery } from "redux-saga/effects";
import {
  BRANCH,
  CREATE_SPACE,
  SPACE,
  SPACE_ONE,
  SPACE_TRAINING_FINISH,
  SPACE_UPDATE,
  UPDATE_AVATAR_SPACE,
} from "./actionTypes";
import {
  spaceSuccess,
  spaceError,
  spaceOneSuccess,
  spaceOneError,
  spaceTrainingFinishSuccess,
  spaceTrainingFinishError,
  createSpaceSuccess,
  createSpaceError,
  updateAvatarSpaceSuccess,
  updateAvatarSpaceError,
  branchSuccess,
  branchError,
  spaceUpdateSuccess,
  spaceUpdateError,
} from "./actions";

import {
  createNewSpace,
  getAll,
  getOne,
  updateTrainingFinish,
  uploadAvatarSpace,
  updateSpace,
} from "../../resources/space/sagaHelper";

function* space({ payload: { space } }) {
  try {
    const response = yield call(getAll, space);
    yield put(spaceSuccess(response));
  } catch (error) {
    yield put(spaceError(error?.message));
  }
}

function* spaceUpdate({ payload: { space } }) {
  try {
    const response = yield call(updateSpace, space);
    yield put(spaceUpdateSuccess(response));
  } catch (error) {
    yield put(spaceUpdateError(error?.message));
  }
}

function* branch({ payload: { space } }) {
  try {
    const response = yield call(getAll, space);
    yield put(branchSuccess(response));
  } catch (error) {
    yield put(branchError(error?.message));
  }
}

function* createSpace({ payload: { space } }) {
  try {
    const response = yield call(createNewSpace, space);
    yield put(createSpaceSuccess(response));
  } catch (error) {
    yield put(createSpaceError(error?.message));
  }
}

function* updateAvatarSpace({ payload: { space } }) {
  try {
    const response = yield call(uploadAvatarSpace, space);
    yield put(updateAvatarSpaceSuccess(response));
  } catch (error) {
    yield put(updateAvatarSpaceError(error?.message));
  }
}

function* spaceOne({ payload: { space } }) {
  try {
    const response = yield call(getOne, space);
    yield put(spaceOneSuccess(response));
  } catch (error) {
    yield put(spaceOneError(error?.message));
  }
}

function* spaceFinishTrainigin({ payload: { space } }) {
  try {
    const response = yield call(updateTrainingFinish, space);
    yield put(spaceTrainingFinishSuccess(response));
  } catch (error) {
    yield put(spaceTrainingFinishError(error?.message));
  }
}

function* spaceSaga() {
  yield takeEvery(SPACE, space);
  yield takeEvery(SPACE_ONE, spaceOne);
  yield takeEvery(SPACE_TRAINING_FINISH, spaceFinishTrainigin);
  yield takeEvery(CREATE_SPACE, createSpace);
  yield takeEvery(UPDATE_AVATAR_SPACE, updateAvatarSpace);
  yield takeEvery(BRANCH, branch);
  yield takeEvery(SPACE_UPDATE, spaceUpdate);
}

export default spaceSaga;
