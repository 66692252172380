export const CONTACT = "CONTACT";
export const CONTACT_SPACE_STUDENT = "CONTACT_SPACE_STUDENT";
export const CONTACT_SPACE_STAFF = "CONTACT_SPACE_STAFF";
export const CONTACT_STUDENT = "CONTACT_STUDENT";
export const CONTACT_STAFF = "CONTACT_STAFF";
export const CREATE_CONTACT = "CREATE_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const DISABLE_CONTACT = "DISABLE_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_STAFF_SUCCESS = "CONTACT_STAFF_SUCCESS";
export const CONTACT_SPACE_STUDENT_SUCCESS = "CONTACT_SPACE_STUDENT_SUCCESS";
export const CONTACT_SPACE_STAFF_SUCCESS = "CONTACT_SPACE_STAFF_SUCCESS";
export const CONTACT_STUDENT_SUCCESS = "CONTACT_STUDENT_SUCCESS";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DISABLE_CONTACT_SUCCESS = "DISABLE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const CONTACT_ERROR = "CONTACT_ERROR";
export const CONTACT_STAFF_ERROR = "CONTACT_STAFF_ERROR";
export const CONTACT_SPACE_STUDENT_ERROR = "CONTACT_SPACE_STUDENT_ERROR";
export const CONTACT_SPACE_STAFF_ERROR = "CONTACT_SPACE_STAFF_ERROR";
export const CONTACT_STUDENT_ERROR = "CONTACT_STUDENT_ERROR";
export const CREATE_CONTACT_ERROR = "CREATE_CONTACT_ERROR";
export const UPDATE_CONTACT_ERROR = "UPDATE_CONTACT_ERROR";
export const DISABLE_CONTACT_ERROR = "DISABLE_CONTACT_ERROR";
export const DELETE_CONTACT_ERROR = "DELETE_CONTACT_ERROR";
export const CONTACT_CLEAR_DATA = "CONTACT_CLEAR_DATA";
export const CONTACT_STAFF_CLEAR_DATA = "CONTACT_STAFF_CLEAR_DATA";
export const CONTACT_SPACE_STUDENT_CLEAR_DATA = "CONTACT_SPACE_STUDENT_CLEAR_DATA";
export const CONTACT_SPACE_STAFF_CLEAR_DATA = "CONTACT_SPACE_STAFF_CLEAR_DATA";
export const CONTACT_STUDENT_CLEAR_DATA = "CONTACT_STUDENT_CLEAR_DATA";
export const CREATE_CONTACT_CLEAR_DATA = "CREATE_CONTACT_CLEAR_DATA";
export const UPDATE_CONTACT_CLEAR_DATA = "UPDATE_CONTACT_CLEAR_DATA";
export const DISABLE_CONTACT_CLEAR_DATA = "DISABLE_CONTACT_CLEAR_DATA";
export const DELETE_CONTACT_CLEAR_DATA = "DELETE_CONTACT_CLEAR_DATA";
