import { fetchRequest } from "../fetchRequest";
import { URLs } from "../url";
import GetUrl from "../../services/config";
import LocalStorageService from "../../services/localStorage";
import { STORE_KEY } from "../../utils/constants";
import { SessionProgressRequest, SessionResponse } from "../../model/session";

const localStorageService = new LocalStorageService(STORE_KEY);

const updateSessionProgress = async (data: any) => {
  const url = `${GetUrl(URLs.session)}/${data?.id}/Attend`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      Authorization: userInfo?.token,
      studentId: undefined,
    },
    body: JSON.stringify(data.body as SessionProgressRequest),
  };
  if (userInfo?.students && userInfo?.students?.length > 0)
    requestOptions.headers.studentId = userInfo?.students[0]?.id;
  try {
    return await fetchRequest(url, requestOptions);
  } catch (error: any) {
    throw error;
  }
};

const updateSessionFinish = async (data: any) => {
  const url = `${GetUrl(URLs.session)}/${data?.id}/Finish`;
  const userInfo: any = localStorageService.getUserStored();
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      Authorization: userInfo?.token,
      studentId: undefined,
    },
  };
  if (userInfo?.students && userInfo?.students?.length > 0)
    requestOptions.headers.studentId = userInfo?.students[0]?.id;
  try {
    return await fetchRequest(url, requestOptions);
  } catch (error: any) {
    throw error;
  }
};

const getOne = async (params: any) => {
  const url = `${GetUrl(URLs.session)}/${params.id}`;
  const userInfo: any = localStorageService.getUserStored();
  let requestOptions: any = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      schoolId: userInfo?.school?.id,
      Authorization: userInfo?.token,
    },
  };

  try {
    return (await fetchRequest(url, requestOptions)) as SessionResponse;
  } catch (errors: any) {
    throw errors;
  }
};

export { updateSessionProgress, updateSessionFinish, getOne };
