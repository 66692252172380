import {call, put, takeEvery} from "redux-saga/effects"
import {LOGIN_USER,} from "./actionTypes"
import {loginUserError, loginUserSuccess} from "./actions"

import {postLogin} from "../../resources/login/sagaHelper";


function* loginUser({payload: {user}}) {
    try {
        const response = yield call(postLogin, user)
        yield put(loginUserSuccess(response))
    } catch (error) {
        yield put(loginUserError(error?.message))
    }
}


function* authSaga() {
    yield takeEvery(LOGIN_USER, loginUser);
}

export default authSaga
