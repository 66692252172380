import {
  D3ICourseNew,
  sizes,
  wrapperType,
} from "@edukcare/github-packages-edukcare-components/dist";

export const View = (props: any) => {
  return (
    <D3ICourseNew
      addService={() => {}}
      breadCrumbs={[]}
      confirmLabel={props.labels?.confirmLabel ?? ""}
      cancelLabel={props.labels?.cancelLabel ?? ""}
      dataFormLabel={{
        descriptionErrorText:
          props.labels?.dataForm?.descriptionErrorText ?? "",
        descriptionLabel: props.labels?.dataForm?.descriptionLabel ?? "",
        nameErrorText: props.labels?.dataForm?.nameErrorText ?? "",
        nameLabel: props.labels?.dataForm?.nameLabel ?? "",
        initialDateLabel: props?.labels?.dataForm?.initialDateLabel ?? "",
        endDateLabel: props?.labels?.dataForm?.endDateLabel ?? "",
        descriptionTextHelper:
          props?.labels?.dataForm?.descriptionTextHelper ?? "",
        fileDescription: props?.labels?.dataForm?.fileDescription ?? "",
        tagTitle: props?.labels?.dataForm?.tagTitle ?? "",
        tagDescription: props?.labels?.dataForm?.tagDescription ?? "",
      }}
      description={props.labels?.description ?? ""}
      elevation={1}
      errorLabels={{
        DescriptionRequired:
          props.labels?.errorLabels?.DescriptionRequired ?? "",
        NameRequired: props.labels?.errorLabels?.NameRequired ?? "",
        InitialDate: props.labels?.errorLabels?.NameRequired ?? "",
        EndDate: props.labels?.errorLabels?.NameRequired ?? "",
      }}
      formType={props.mode}
      onClick={function noRefCheck() {}}
      onClickAvatar={props.handleAvatar}
      onClickBreadCrumbs={function noRefCheck() {}}
      onClickCancel={props.handleCancel}
      onClickHelp={function noRefCheck() {}}
      onClickHelpButton={function noRefCheck() {}}
      onSubmit={props.handleSubmit}
      options={props.tags ?? []}
      preSelectedAssignment={[]}
      showActions
      showHelp
      title={props.labels.title}
      showHeader={false}
      type={wrapperType.form}
      fullWidth={false}
      buttonSize={sizes.small}
      open={false}
      buttonOptions={[]}
      dataForm={{}}
    />
  );
};
