export const SESSION_PROGRESS = "SESSION_PROGRESS";
export const SESSION_ONE = "SESSION_ONE";
export const SESSION_UPDATE = "SESSION_UPDATE";
export const SESSION_CREATE = "SESSION_CREATE";
export const SESSION_FINISH = "SESSION_FINISH";
export const SESSION_RESOURCE_ADD = "SESSION_RESOURCE_ADD";
export const SESSION_PROGRESS_SUCCESS = "SESSION_PROGRESS_SUCCESS";
export const SESSION_RESOURCE_ADD_SUCCESS = "SESSION_RESOURCE_ADD_SUCCESS";
export const SESSION_CREATE_SUCCESS = "SESSION_CREATE_SUCCESS";
export const SESSION_ONE_SUCCESS = "SESSION_ONE_SUCCESS";
export const SESSION_UPDATE_SUCCESS = "SESSION_UPDATE_SUCCESS";
export const SESSION_FINISH_SUCCESS = "SESSION_FINISH_SUCCESS";
export const SESSION_PROGRESS_ERROR = "SESSION_PROGRESS_ERROR";
export const SESSION_RESOURCE_ADD_ERROR = "SESSION_RESOURCE_ADD_ERROR";
export const SESSION_CREATE_ERROR = "SESSION_CREATE_ERROR";
export const SESSION_ONE_ERROR = "SESSION_ONE_ERROR";
export const SESSION_UPDATE_ERROR = "SESSION_UPDATE_ERROR";
export const SESSION_FINISH_ERROR = "SESSION_FINISH_ERROR";
export const SESSION_PROGRESS_CLEAR_DATA = "SESSION_PROGRESS_CLEAR_DATA";
export const SESSION_ONE_CLEAR_DATA = "SESSION_ONE_CLEAR_DATA";
export const SESSION_UPDATE_CLEAR_DATA = "SESSION_UPDATE_CLEAR_DATA";
export const SESSION_RESOURCE_ADD_CLEAR_DATA = "SESSION_RESOURCE_ADD_CLEAR_DATA";
export const SESSION_FINISH_CLEAR_DATA = "SESSION_FINISH_CLEAR_DATA";
export const SESSION_CREATE_CLEAR_DATA = "SESSION_CREATE_CLEAR_DATA";

