import React from "react";
import {Login_} from "@edukcare/github-packages-edukcare-components/dist";
import {useTranslation} from "react-i18next";

export const LoginView = (props: any) => {
    const { t, i18n } = useTranslation("global");
    return (
        <>
            <Login_
                onSignIn={props.onSingIn}
                title= {t("login.title")}
                emailError={t("login.emailError")}
                emailRequired={t("login.emailRequired")}
                emailText={t("login.emailText")}
                emailHelperText={t("login.emailHelperText")}
                passwordHelperText={t("login.passwordHelperText")}
                passwordText={t("login.passwordText")}
                passwordError={t("login.passwordError")}
                passwordRequired={t("login.passwordError")}
            />
        </>

    );
};
