import {
  SPACE,
  CREATE_SPACE,
  CREATE_SPACE_CLEAR_DATA,
  CREATE_SPACE_ERROR,
  CREATE_SPACE_SUCCESS,
  SPACE_CLEAR_DATA,
  SPACE_ERROR,
  SPACE_SUCCESS,
  SPACE_ONE,
  SPACE_ONE_CLEAR_DATA,
  SPACE_ONE_ERROR,
  SPACE_ONE_SUCCESS,
  SPACE_TRAINING_FINISH,
  SPACE_TRAINING_FINISH_CLEAR_DATA,
  SPACE_TRAINING_FINISH_SUCCESS,
  SPACE_TRAINING_FINISH_ERROR,
  UPDATE_AVATAR_SPACE,
  UPDATE_AVATAR_SPACE_CLEAR_DATA,
  UPDATE_AVATAR_SPACE_ERROR,
  UPDATE_AVATAR_SPACE_SUCCESS,
  BRANCH,
  BRANCH_CLEAR_DATA,
  BRANCH_ERROR,
  BRANCH_SUCCESS,
  SPACE_UPDATE,
  SPACE_UPDATE_CLEAR_DATA,
  SPACE_UPDATE_ERROR,
  SPACE_UPDATE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorBranch: null,
  responseBranch: null,
  loadingBranch: null,
  errorSpace: null,
  responseSpace: null,
  loadingSpace: false,
  errorSpaceOne: null,
  responseSpaceOne: null,
  loadingSpaceOne: null,
  errorSpaceTrainingFinish: null,
  responseSpaceTrainingFinish: null,
  loadingSpaceTrainingFinish: null,
  createErrorSpace: null,
  createResponseSpace: null,
  createLoadingSpace: false,
  updateAvatarErrorSpace: null,
  updateAvatarResponseSpace: null,
  updateAvatarLoadingSpace: false,
  errorSpaceUpdate: null,
  responseSpaceUpdate: null,
  loadingSpaceUpdate: false,
};

const Space = (state = initialState, action) => {
  switch (action.type) {
    case SPACE:
      state = {
        ...state,
        loadingSpace: true,
        errorSpace: null,
        responseSpace: true,
      };
      break;
    case SPACE_UPDATE:
      state = {
        ...state,
        loadingSpaceUpdate: true,
        errorSpaceUpdate: null,
        responseSpaceUpdate: true,
      };
      break;
    case BRANCH:
      state = {
        ...state,
        loadingBranch: true,
        errorBranch: null,
        responseBranch: true,
      };
      break;
    case UPDATE_AVATAR_SPACE:
      state = {
        ...state,
        updateAvatarLoadingSpace: true,
        updateAvatarErrorSpace: null,
        updateAvatarResponseSpace: true,
      };
      break;
    case CREATE_SPACE:
      state = {
        ...state,
        createLoadingSpace: true,
        createErrorSpace: null,
        createResponseSpace: true,
      };
      break;
    case SPACE_ONE:
      state = {
        ...state,
        loadingSpaceOne: true,
        errorSpaceOne: null,
        responseSpaceOne: true,
      };
      break;
    case SPACE_TRAINING_FINISH:
      state = {
        ...state,
        loadingSpaceTrainingFinish: true,
        errorSpaceTrainingFinish: null,
        responseSpaceTrainingFinish: true,
      };
      break;
    case SPACE_SUCCESS:
      state = {
        ...state,
        errorSpace: null,
        loadingSpace: false,
        responseSpace: action.payload,
      };
      break;
    case SPACE_UPDATE_SUCCESS:
      state = {
        ...state,
        errorSpaceUpdate: null,
        loadingSpaceUpdate: false,
        responseSpaceUpdate: action.payload,
      };
      break;
    case BRANCH_SUCCESS:
      state = {
        ...state,
        errorBranch: null,
        loadingBranch: false,
        responseBranch: action.payload,
      };
      break;
    case UPDATE_AVATAR_SPACE_SUCCESS:
      state = {
        ...state,
        updateAvatarErrorSpace: null,
        updateAvatarLoadingSpace: false,
        updateAvatarResponseSpace: action.payload,
      };
      break;
    case CREATE_SPACE_SUCCESS:
      state = {
        ...state,
        createErrorSpace: null,
        createLoadingSpace: false,
        createResponseSpace: action.payload,
      };
      break;
    case SPACE_TRAINING_FINISH_SUCCESS:
      state = {
        ...state,
        errorSpaceTrainingFinish: null,
        loadingSpaceTrainingFinish: false,
        responseSpaceTrainingFinish: action.payload,
      };
      break;
    case SPACE_ONE_SUCCESS:
      state = {
        ...state,
        errorSpaceOne: null,
        loadingSpaceOne: false,
        responseSpaceOne: action.payload,
      };
      break;
    case SPACE_ERROR:
      state = {
        ...state,
        errorSpace: action.payload,
        loadingSpace: false,
        responseSpace: null,
      };
      break;
    case SPACE_UPDATE_ERROR:
      state = {
        ...state,
        errorSpaceUpdate: action.payload,
        loadingSpaceUpdate: false,
        responseSpaceUpdate: null,
      };
      break;
    case BRANCH_ERROR:
      state = {
        ...state,
        errorBranch: action.payload,
        loadingBranch: false,
        responseBranch: null,
      };
      break;
    case UPDATE_AVATAR_SPACE_ERROR:
      state = {
        ...state,
        updateAvatarErrorSpace: action.payload,
        updateAvatarLoadingSpace: false,
        updateAvatarResponseSpace: null,
      };
      break;
    case CREATE_SPACE_ERROR:
      state = {
        ...state,
        createErrorSpace: action.payload,
        createLoadingSpace: false,
        createResponseSpace: null,
      };
      break;
    case SPACE_TRAINING_FINISH_ERROR:
      state = {
        ...state,
        errorSpaceTrainingFinish: action.payload,
        loadingSpaceTrainingFinish: false,
        responseSpaceTrainingFinish: null,
      };
      break;
    case SPACE_ONE_ERROR:
      state = {
        ...state,
        errorSpaceOne: action.payload,
        loadingSpaceOne: false,
        responseSpaceOne: null,
      };
      break;
    case SPACE_CLEAR_DATA:
      state = {
        ...state,
        errorSpace: null,
        loadingSpace: false,
        responseSpace: null,
      };
      break;
    case SPACE_UPDATE_CLEAR_DATA:
      state = {
        ...state,
        errorSpaceUpdate: null,
        loadingSpaceUpdate: false,
        responseSpaceUpdate: null,
      };
      break;
    case BRANCH_CLEAR_DATA:
      state = {
        ...state,
        errorBranch: null,
        loadingBranch: false,
        responseBranch: null,
      };
      break;
    case UPDATE_AVATAR_SPACE_CLEAR_DATA:
      state = {
        ...state,
        updateAvatarErrorSpace: null,
        updateAvatarLoadingSpace: false,
        updateAvatarResponseSpace: null,
      };
      break;
    case CREATE_SPACE_CLEAR_DATA:
      state = {
        ...state,
        createErrorSpace: null,
        createLoadingSpace: false,
        createResponseSpace: null,
      };
      break;
    case SPACE_TRAINING_FINISH_CLEAR_DATA:
      state = {
        ...state,
        errorSpaceTrainingFinish: null,
        loadingSpaceTrainingFinish: false,
        responseSpaceTrainingFinish: null,
      };
      break;
    case SPACE_ONE_CLEAR_DATA:
      state = {
        ...state,
        errorSpaceOne: null,
        loadingSpaceOne: false,
        responseSpaceOne: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Space;
