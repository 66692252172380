import {WrapperContent} from "../../components/layout/style";
import {Contact, icons} from "@edukcare/github-packages-edukcare-components/dist";

export const ContactView = (props: any) => {

    return (
        <WrapperContent>
            <Contact
                showForm={props.showForm}
                form={props.form}
                title={props.pageLabels.title}
                gridRows={props.rows}
                gridColumns={props.columns}
                labels={props.labels}
                pageDescription={props.pageLabels.pageDescription}
                pageTitle={props.pageLabels.pageTitle}
                labelActionButton={props.pageLabels.labelActionButton}
                tags={props.tags}
                onSubmit={props.handleSubmit}
                onClickConfirmation={props.handleClickConfirmation}
                table={props.table}
                onPaginationChange={props.onPaginationChange}
                gridTotalRowCount={props.pagination.total}
                gridPageSize={props.pagination.size}
                gridHeight={"500px"}
                onApplyFilter={props.onApplyFilter}
                menuSideBar={[
                    {
                        icon: {
                            icon: icons.Storage,
                        },
                        active: true,
                        disabled: false,
                        id: "1",
                        name: "Cursos",
                    },
                    {
                        icon: {
                            icon: icons.Person,
                        },
                        active: false,
                        disabled: false,
                        id: "2",
                        name: "Usuarios",
                    },
                    {
                        icon: {
                            icon: icons.Event,
                        },
                        active: false,
                        disabled: false,
                        id: "3",
                        name: "Calendario",
                    },
                    {
                        icon: {
                            icon: icons.SpeakerNotes,
                        },
                        active: false,
                        disabled: false,
                        id: "4",
                        name: "Mensajes",
                    },
                    {
                        icon: {
                            icon: icons.Tv,
                        },
                        active: false,
                        disabled: false,
                        id: "5",
                        name: "Dashboard",
                    },
                ]}
            />

        </WrapperContent>
    )
}