export const URLs = {
  login: "Users/Login",
  spaces: "Spaces",
  contacts: "Contacts",
  tags: "Tags",
  space: "Space",
  session: "Session",
  trainings: "Trainings",
  feeds: "Feeds"
};

export const Methods = {
  post: "post",
  get: "get",
  put: "put",
  delete: "delete",
  patch: "patch",
} as const;
