import {
  CONTACT,
  CONTACT_SUCCESS,
  CONTACT_ERROR,
  CONTACT_CLEAR_DATA,
  CREATE_CONTACT,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_ERROR,
  CREATE_CONTACT_CLEAR_DATA,
  UPDATE_CONTACT,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_ERROR,
  UPDATE_CONTACT_CLEAR_DATA,
  DISABLE_CONTACT,
  DISABLE_CONTACT_CLEAR_DATA,
  DISABLE_CONTACT_ERROR,
  DISABLE_CONTACT_SUCCESS,
  DELETE_CONTACT,
  DELETE_CONTACT_ERROR,
  DELETE_CONTACT_CLEAR_DATA,
  DELETE_CONTACT_SUCCESS,
  CONTACT_STUDENT,
  CONTACT_STUDENT_CLEAR_DATA,
  CONTACT_STUDENT_ERROR,
  CONTACT_STUDENT_SUCCESS,
  CONTACT_STAFF,
  CONTACT_STAFF_CLEAR_DATA,
  CONTACT_STAFF_ERROR,
  CONTACT_STAFF_SUCCESS,
  CONTACT_SPACE_STUDENT,
  CONTACT_SPACE_STUDENT_CLEAR_DATA,
  CONTACT_SPACE_STUDENT_ERROR,
  CONTACT_SPACE_STUDENT_SUCCESS,
  CONTACT_SPACE_STAFF,
  CONTACT_SPACE_STAFF_CLEAR_DATA,
  CONTACT_SPACE_STAFF_ERROR,
  CONTACT_SPACE_STAFF_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorContact: null,
  responseContact: null,
  loadingContact: false,
  errorCreateContact: null,
  responseCreateContact: null,
  loadingCreateContact: false,
  errorUpdateContact: null,
  responseUpdateContact: null,
  loadingUpdateContact: false,
  errorDisableContact: null,
  responseDisableContact: null,
  loadingDisableContact: null,
  errorDeleteContact: null,
  responseDeleteContact: null,
  loadingDeleteContact: null,
  errorContactStudent: null,
  loadingContactStudent: false,
  responseContactStudent: null,
  errorContactStaff: null,
  loadingContactStaff: false,
  responseContactStaff: null,
  responseContactSpaceStudent: null,
  errorContactSpaceStudent: false,
  loadingContactSpaceStudent: null,
  responseContactSpaceStaff: null,
  errorContactSpaceStaff: false,
  loadingContactSpaceStaff: null,
};

const Contact = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT:
      state = {
        ...state,
        loadingContact: true,
        errorContact: null,
        responseContact: true,
      };
      break;
    case CONTACT_SPACE_STUDENT:
      state = {
        ...state,
        loadingContactSpaceStudent: true,
        errorContactSpaceStudent: null,
        responseContactSpaceStudent: true,
      };
      break;
    case CONTACT_SPACE_STAFF:
      state = {
        ...state,
        loadingContactSpaceStaff: true,
        errorContactSpaceStaff: null,
        responseContactSpaceStaff: true,
      };
      break;
    case CONTACT_STUDENT:
      state = {
        ...state,
        loadingContactStudent: true,
        errorContactStudent: null,
        responseContactStudent: true,
      };
      break;
    case CONTACT_STAFF:
      state = {
        ...state,
        loadingContactStaff: true,
        errorContactStaff: null,
        responseContactStaff: true,
      };
      break;
    case CREATE_CONTACT:
      state = {
        ...state,
        loadingCreateContact: true,
        errorCreateContact: null,
        responseCreateContact: true,
      };
      break;
    case UPDATE_CONTACT:
      state = {
        ...state,
        loadingUpdateContact: true,
        errorUpdateContact: null,
        responseUpdateContact: true,
      };
      break;
    case DISABLE_CONTACT:
      state = {
        ...state,
        loadingDisableContact: true,
        errorDisableContact: null,
        responseDisableContact: true,
      };
      break;
    case DELETE_CONTACT:
      state = {
        ...state,
        loadingDeleteContact: true,
        errorDeleteContact: null,
        responseDeleteContact: true,
      };
      break;
    case CONTACT_SUCCESS:
      state = {
        ...state,
        errorContact: null,
        loadingContact: false,
        responseContact: action.payload,
      };
      break;
    case CONTACT_SPACE_STUDENT_SUCCESS:
      state = {
        ...state,
        errorContactSpaceStudent: null,
        loadingContactSpaceStudent: false,
        responseContactSpaceStudent: action.payload,
      };
      break;
    case CONTACT_SPACE_STAFF_SUCCESS:
      state = {
        ...state,
        errorContactSpaceStaff: null,
        loadingContactSpaceStaff: false,
        responseContactSpaceStaff: action.payload,
      };
      break;
    case CREATE_CONTACT_SUCCESS:
      state = {
        ...state,
        errorCreateContact: null,
        loadingCreateContact: false,
        responseCreateContact: action.payload,
      };
      break;
    case CONTACT_STUDENT_SUCCESS:
      state = {
        ...state,
        errorContactStudent: null,
        loadingContactStudent: false,
        responseContactStudent: action.payload,
      };
      break;
    case CONTACT_STAFF_SUCCESS:
      state = {
        ...state,
        errorContactStaff: null,
        loadingContactStaff: false,
        responseContactStaff: action.payload,
      };
      break;
    case UPDATE_CONTACT_SUCCESS:
      state = {
        ...state,
        errorUpdateContact: null,
        loadingUpdateContact: false,
        responseUpdateContact: action.payload,
      };
      break;
    case DISABLE_CONTACT_SUCCESS:
      state = {
        ...state,
        errorDisableContact: null,
        loadingDisableContact: false,
        responseDisableContact: action.payload,
      };
      break;
    case DELETE_CONTACT_SUCCESS:
      state = {
        ...state,
        errorDeleteContact: null,
        loadingDeleteContact: false,
        responseDeleteContact: action.payload,
      };
      break;
    case CONTACT_ERROR:
      state = {
        ...state,
        errorContact: action.payload,
        loadingContact: false,
        responseContact: null,
      };
      break;
    case CONTACT_SPACE_STUDENT_ERROR:
      state = {
        ...state,
        errorContactSpaceStudent: action.payload,
        loadingContactSpaceStudent: false,
        responseContactSpaceStudent: null,
      };
      break;
    case CONTACT_SPACE_STAFF_ERROR:
      state = {
        ...state,
        errorContactSpaceStaff: action.payload,
        loadingContactSpaceStaff: false,
        responseContactSpaceStaff: null,
      };
      break;
    case CONTACT_STUDENT_ERROR:
      state = {
        ...state,
        errorContactStudent: action.payload,
        loadingContactStudent: false,
        responseContactStudent: null,
      };
      break;
    case CONTACT_STAFF_ERROR:
      state = {
        ...state,
        errorContactStaff: action.payload,
        loadingContactStaff: false,
        responseContactStaff: null,
      };
      break;
    case CREATE_CONTACT_ERROR:
      state = {
        ...state,
        errorCreateContact: action.payload,
        loadingCreateContact: false,
        responseCreateContact: null,
      };
      break;
    case UPDATE_CONTACT_ERROR:
      state = {
        ...state,
        errorUpdateContact: action.payload,
        loadingUpdateContact: false,
        responseUpdateContact: null,
      };
      break;
    case DISABLE_CONTACT_ERROR:
      state = {
        ...state,
        errorDisableContact: action.payload,
        loadingDisableContact: false,
        responseDisableContact: null,
      };
      break;
    case DELETE_CONTACT_ERROR:
      state = {
        ...state,
        errorDeleteContact: action.payload,
        loadingDeleteContact: false,
        responseDeleteContact: null,
      };
      break;
    case CONTACT_CLEAR_DATA:
      state = {
        ...state,
        errorContact: null,
        loadingContact: null,
        responseContact: false,
      };
      break;
    case CONTACT_SPACE_STUDENT_CLEAR_DATA:
      state = {
        ...state,
        errorContactSpaceStudent: null,
        loadingContactSpaceStudent: null,
        responseContactSpaceStudent: false,
      };
      break;
    case CONTACT_SPACE_STAFF_CLEAR_DATA:
      state = {
        ...state,
        errorContactSpaceStaff: null,
        loadingContactSpaceStaff: null,
        responseContactSpaceStaff: false,
      };
      break;
    case CONTACT_STUDENT_CLEAR_DATA:
      state = {
        ...state,
        errorContactStudent: null,
        loadingContactStudent: null,
        responseContactStudent: false,
      };
      break;
    case CONTACT_STAFF_CLEAR_DATA:
      state = {
        ...state,
        errorContactStaff: null,
        loadingContactStaff: null,
        responseContactStaff: false,
      };
      break;
    case CREATE_CONTACT_CLEAR_DATA:
      state = {
        ...state,
        errorCreateContact: null,
        loadingCreateContact: null,
        responseCreateContact: false,
      };
      break;
    case UPDATE_CONTACT_CLEAR_DATA:
      state = {
        ...state,
        errorUpdateContact: null,
        loadingUpdateContact: null,
        responseUpdateContact: false,
      };
      break;
    case DISABLE_CONTACT_CLEAR_DATA:
      state = {
        ...state,
        errorDisableContact: null,
        loadingDisableContact: null,
        responseDisableContact: false,
      };
      break;
    case DELETE_CONTACT_CLEAR_DATA:
      state = {
        ...state,
        errorDeleteContact: null,
        loadingDeleteContact: null,
        responseDeleteContact: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Contact;
