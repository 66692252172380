import { Navigate, Route, Routes } from "react-router-dom";
import { PrivateRoutes } from "./privateRoutes";

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<PrivateRoutes />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};
