export enum SESSION_TYPE {
  AUDIO = "AUDIO",
  READ_DOCUMENT = "READ_DOCUMENT",
  WATCH_VIDEO = "WATCH_VIDEO",
}

export enum ASSET_TYPE {
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
  DOCUMENT = "DOCUMENT"
}

export enum SESSION_STATUS {
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED_OK = "FINISHED_OK"
}
