import {
  LearnerDetail,
  Quiz,
} from "@edukcare/github-packages-edukcare-components/dist";
import { WrapperContent } from "../../components/layout/style";

export const LearnerDetailView = (props: any) => {
  return (
    <WrapperContent>
      {props.stateForm?.status === null && (
        <LearnerDetail
          list={props.listSession ?? []}
          title={props.dataForm?.title}
          description={props.dataForm?.description}
          onProgress={props.handleOnProgress}
          progressInterval={700}
          actualMedia={props.actualSession ?? null}
          onClickSession={props.handleOnClickSession}
          renderMode={"canvas"}
        />
      )}
      {props.stateForm?.status !== null && (
        <div>
          <Quiz
            id="quizid"
            questions={props.listOptions}
            quizInfoProp={{
              state: props.stateForm?.status,
              score: props.stateForm?.score ?? 0,
            }}
            labels={props.labels}
            onAction={props.handleOnActionQuiz}
            showNext={true}
            showPrevious={true}
            enablePrevious={false}
            onNext={props.onNextQuestion}
            actualPosition={props.indexQuestion}
            onEnd={props.onEndQuiz}
            modal={props.modalLabels}
            showFailedButton={props.stateForm?.showFailedButton}
          />
        </div>
      )}
    </WrapperContent>
  );
};
