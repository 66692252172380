export const TRAINING_ATTEND = "TRAINING_ATTEND";
export const TRAINING_ASSIGNMENT = "TRAINING_ASSIGNMENT";
export const TRAINING_UNASSIGNMENT = "TRAINING_UNASSIGNMENT";
export const TRAINING_ATTEND_SUCCESS = "TRAINING_ATTEND_SUCCESS";
export const TRAINING_ASSIGNMENT_SUCCESS = "TRAINING_ASSIGNMENT_SUCCESS";
export const TRAINING_UNASSIGNMENT_SUCCESS = "TRAINING_UNASSIGNMENT_SUCCESS";
export const TRAINING_ATTEND_ERROR = "TRAINING_ATTEND_ERROR";
export const TRAINING_ASSIGNMENT_ERROR = "TRAINING_ASSIGNMENT_ERROR";
export const TRAINING_UNASSIGNMENT_ERROR = "TRAINING_UNASSIGNMENT_ERROR";
export const TRAINING_ATTEND_CLEAR_DATA = "TRAINING_ATTEND_CLEAR_DATA";
export const TRAINING_ASSIGNMENT_CLEAR_DATA = "TRAINING_ASSIGNMENT_CLEAR_DATA";
export const TRAINING_UNASSIGNMENT_CLEAR_DATA = "TRAINING_UNASSIGNMENT_CLEAR_DATA";
