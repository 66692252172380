export const themeCore = {
    colors: {
        success: "#0a6519",
        error: "#8d0606",
        warning: "#f8b400",

        primary: "rgb(31 61 178)",
        secondary: "#666666",
        third: "#ffffff",
        fourth: "#F5F5F5",
        text: "#EBEEF6",

        primaryText: "rgb(31 61 178)",
        secondaryText: "#666666",
        thirdText: "#ffffff",
        fourthText: "#A1A1A1",

        backgroundPrimary: "rgb(31 61 178)",
        backgroundSecondary: "#FCFCFC",
        backgroundThird: "#ECF6FC",
        backgroundFourth: "#fafafa",
        background: "#fff",

        backgroundPrimaryTransparent: "rgba(58,86,161,0.69)",
    },
    spacing: {
        spacingTiny: "4px",
        spacingXs: "8px",
        spacingSmall: "12px",
        spacingMedium: "16px",
        spacingLarge: "24px",
        spacingXl: "32px",
        spacingHuge: "40px",
    },
    typography: {
        fontFamily: "'Source Sans Pro', sans-serif'",
        fontSizeTiny: "10px",
        fontSizeXs: "12px",
        fontSizeSm: "14px",
        fontSizeMedium: "16px",
        fontSizeLarge: "20px",
        fontSizeXl: "26px",
        fontSizeHuge: "32px",
        fontWeightNormal: "10px",
        fontWeightSemiBold: "10px",
        lineHeight: "1.5",
    },
};
