export const STORE_KEY = "edukcare";

export const APP_ID = "app"

export const ADDRESS_TYPE = "HOME"

export const PHONE_TYPE = "HOME"

export const LOGOUT = "LOGOUT";

export const ASSIGNED_TRAINING_STATUS = "STARTED, PENDING";

export const COMPLETED_TRAINING_STATUS = "FINISHED_OK";

export const ORIGIN = "WEBSITE";