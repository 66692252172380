import GetUrl from "../../services/config";
import {URLs} from "../url";
import {buildQueryString} from "../../utils/queryParams";
import {fetchRequest} from "../fetchRequest";
import LocalStorageService from "../../services/localStorage";
import {STORE_KEY} from "../../utils/constants";
import {ContactRequest, ContactResponse} from "../../model/contact";

const localStorageService = new LocalStorageService(STORE_KEY);
const getAll = async (params: any) => {
    const url = `${GetUrl(URLs.contacts)}?${buildQueryString(params)}`;
    const userInfo: any = localStorageService.getUserStored();
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
            schoolId: userInfo?.school?.id,
            Authorization: userInfo?.token,
        },
    };
    try {
        return (await fetchRequest(url, requestOptions)) as ContactResponse;
    } catch (errors: any) {
        throw errors;
    }
};

const createContact = async (data: ContactRequest) => {
    const url = `${GetUrl(URLs.contacts)}`;
    const userInfo: any = localStorageService.getUserStored();
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
            schoolId: userInfo?.school?.id,
            Authorization: userInfo?.token,
        },
        body: JSON.stringify(data),
    };
    try {
        return (await fetchRequest(url, requestOptions)) as ContactResponse;
    } catch (errors: any) {
        throw errors;
    }
};

const updateContact = async (data: any) => {
    const url = `${GetUrl(URLs.contacts)}/${data.id}`;
    const userInfo: any = localStorageService.getUserStored();
    const requestOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
            schoolId: userInfo?.school?.id,
            Authorization: userInfo?.token,
        },
        body: JSON.stringify(data.body),
    };
    try {
        return (await fetchRequest(url, requestOptions)) as ContactResponse;
    } catch (errors: any) {
        throw errors;
    }
}

const disableContact = async (data: any) => {
    const url = `${GetUrl(URLs.contacts)}/${data.id}/Disable`;
    const userInfo: any = localStorageService.getUserStored();
    const requestOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
            schoolId: userInfo?.school?.id,
            Authorization: userInfo?.token,
        },
        body: JSON.stringify(data.body),
    };
    try {
        return (await fetchRequest(url, requestOptions)) as ContactResponse;
    } catch (errors: any) {
        throw errors;
    }
}

const deleteContact = async (data: any) => {
    const url = `${GetUrl(URLs.contacts)}/${data.id}`;
    const userInfo: any = localStorageService.getUserStored();
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
            schoolId: userInfo?.school?.id,
            Authorization: userInfo?.token,
        },
    };
    try {
        return (await fetchRequest(url, requestOptions)) as ContactResponse;
    } catch (errors: any) {
        throw errors;
    }
}

export {getAll, createContact, updateContact, disableContact, deleteContact};
