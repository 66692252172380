import { learnerItemType } from "@edukcare/github-packages-edukcare-components/dist";
import { SESSION_TYPE } from "../enum/session";

export const getSessionType = (type: string | undefined): learnerItemType => {
  if (!type) return learnerItemType.VIDEO;
  if (type === SESSION_TYPE.AUDIO.toString()) return learnerItemType.AUDIO;
  if (type === SESSION_TYPE.READ_DOCUMENT.toString())
    return learnerItemType.DOCUMENT;
  return learnerItemType.VIDEO;
};
