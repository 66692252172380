import {LOGIN_USER, LOGIN_USER_CLEAR_DATA, LOGIN_USER_ERROR, LOGIN_USER_SUCCESS} from "./actionTypes";

const initialState = {
    errorLogin: null,
    responseLogin: null,
    loadingLogin: false,
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            state = {
                ...state,
                loadingLogin: true,
                errorLogin: null,
                responseLogin: true,
            }
            break
        case LOGIN_USER_SUCCESS:
            state = {
                ...state,
                errorLogin: null,
                loadingLogin: false,
                responseLogin: action.payload,
            }
            break
        case LOGIN_USER_ERROR:
            state = {
                ...state,
                errorLogin: action.payload,
                loadingLogin: false,
                responseLogin: null,
            }
            break
        case LOGIN_USER_CLEAR_DATA:
            state = {
                ...state,
                errorLogin: null,
                loadingLogin: null,
                responseLogin: false,
            }
            break
        default:
            state = {...state}
            break
    }
    return state
}

export default login
