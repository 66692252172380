import styled from "styled-components";
import {themeCore} from "../../assets/themes/theme";

export const Container = styled.section`
  display: flex;
  background-color: ${themeCore.colors.background};
  height: calc(100vh - 56px);
  position: relative;
  margin: 18px auto;
  padding: 0;
  width: 90%;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  color: ${themeCore.colors.primaryText};
  margin: 20px 0 20px 0;
  position: relative;
`;

export const WrapperFooter = styled.section`
  position: fixed;
  bottom: 0;
  z-index: 9999999;
  width: 100%;
`;

export const WrapperContent = styled.article`
  margin: 0 0 0 0;
  height: 100%;
  position: relative;
  width: 100%;
`;

export const Content = styled.article`
  flex-grow: 1;
  margin: 16px 23px 0 25px;
  padding: 0;
`;
