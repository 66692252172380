import {
  icons,
  MenuItem,
  MenuOptionsList,
} from "@edukcare/github-packages-edukcare-components/dist";
import { TFunction } from "i18next";

export const GetMenuSideBarHome = (t: TFunction) => {
  const m: MenuOptionsList[] = [
    {
      id: t("layout.menuSideBar.homeTraining.idCourse"),
      title: t("layout.menuSideBar.homeTraining.titleCourse"),
      icon: icons.Ballot,
      active: true,
      route: "/course",
      divider: false,
    },
    {
      id: t("layout.menuSideBar.homeTraining.idUser"),
      title: t("layout.menuSideBar.homeTraining.titleUser"),
      icon: icons.People,
      active: false,
      route: "/user",
      divider: false,
    },
    {
      id: t("layout.menuSideBar.homeTraining.idCalendar"),
      title: t("layout.menuSideBar.homeTraining.titleCalendar"),
      icon: icons.Today,
      active: false,
      route: "/calendar",
      divider: false,
    },
    {
      id: t("layout.menuSideBar.homeTraining.idMessages"),
      title: t("layout.menuSideBar.homeTraining.titleMessages"),
      icon: icons.Message,
      active: false,
      route: "/msj",
      divider: false,
    },
    {
      id: t("layout.menuSideBar.homeTraining.idDashboard"),
      title: t("layout.menuSideBar.homeTraining.titleDashboard"),
      icon: icons.Tv,
      active: false,
      route: "/dashboard",
      divider: false,
    },
  ];
  return m;
};

export const GetMenuSideBarHomeStudent = (t: TFunction) => {
  const m: MenuOptionsList[] = [
    {
      id: t("layout.menuSideBar.homeTraining.idTraining"),
      title: t("layout.menuSideBar.homeTraining.titleTraining"),
      icon: icons.Ballot,
      active: true,
      route: "/learner",
      divider: false,
    },
  ];
  return m;
};

export const GetMenuSideBarDetailCourse = (t: TFunction) => {
  const m: MenuOptionsList[] = [
    {
      id: t("layout.menuSideBar.course.idSession"),
      title: t("layout.menuSideBar.course.titleSession"),
      icon: icons.VisibilityOff,
      active: true,
      route: "/session",
      divider: false,
    },
    {
      id: t("layout.menuSideBar.course.idStudent"),
      title: t("layout.menuSideBar.course.titleStudent"),
      icon: icons.Report,
      active: false,
      route: "/student",
      divider: false,
    },
    {
      id: t("layout.menuSideBar.course.idEdit"),
      title: t("layout.menuSideBar.course.titleEdit"),
      icon: icons.Help,
      active: false,
      route: "/editCourse",
      divider: false,
    },
  ];

  return m;
};

export const GetMenuSideBarAdmin = (t: TFunction) => {
  const m: MenuOptionsList[] = [
    {
      id: t("layout.menuSideBar.admin.idContact"),
      title: t("layout.menuSideBar.admin.titleContact"),
      icon: icons.People,
      active: true,
      route: "/contact",
      divider: false,
    },
    {
      id: t("layout.menuSideBar.admin.idRol"),
      title: t("layout.menuSideBar.admin.titleRol"),
      icon: icons.Shield,
      active: false,
      route: "/rols",
      divider: false,
    },
    {
      id: t("layout.menuSideBar.admin.idSetting"),
      title: t("layout.menuSideBar.admin.titleSetting"),
      icon: icons.Settings,
      active: false,
      route: "/setting",
      divider: false,
    },
  ];

  return m;
};

export const GetMenuSideBarLearner = (t: TFunction) => {
  const m: MenuOptionsList[] = [
    {
      id: t("layout.menuSideBar.homeTraining.idMessages"),
      title: t("layout.menuSideBar.homeTraining.titleMessages"),
      icon: icons.Message,
      active: true,
      route: "/msg",
      divider: false,
    },
    {
      id: t("layout.menuSideBar.homeTraining.idCalendar"),
      title: t("layout.menuSideBar.homeTraining.titleCalendar"),
      icon: icons.Today,
      active: false,
      route: "/calendar",
      divider: false,
    },
    {
      id: t("layout.menuSideBar.homeTraining.idStats"),
      title: t("layout.menuSideBar.homeTraining.titleStats"),
      icon: icons.Message,
      active: false,
      route: "/stats",
      divider: false,
    },
  ]
  return m;
}

export const GetMenuHorizontalAdmin = (t: TFunction) => {
  const m: MenuItem[] = [
    {
      id: t("layout.menuHorizontal.idHome"),
      name: t("layout.menuHorizontal.nameHome"),
      active: true,
    },
    {
      id: t("layout.menuHorizontal.idAdmin"),
      name: t("layout.menuHorizontal.nameAdmin"),
      active: false,
    },
  ]
  return m
}

export const GetMenuHorizontalStudent = (t: TFunction) => {
  const m: MenuItem[] = [
    {
      id: t("layout.menuHorizontal.idHomeStudent"),
      name: t("layout.menuHorizontal.nameHomeStudent"),
      active: true,
    },
  ]
  return m
}
